// PanValidationStyles.js

export const getLabelStyle = (isLargeScreen) => ({
  fontSize: isLargeScreen ? "14px" : "16px",
});

export const getButtonPadding = (isMediumScreen, isVerySmallScreen) => {
  if (isMediumScreen || isVerySmallScreen) {
    return "5px 10px";
  }
  return "10px 20px";
};

export const getPlaceholderFontSize = (isMediumScreen, isVerySmallScreen) => {
  if (isMediumScreen || isVerySmallScreen) {
    return "12px";
  }
  return "15px";
};

export const getContainerClass = (isBetween768And883) =>
  `d-flex justify-content-center align-items-center gap-2 ${
    isBetween768And883 ? "gap-3" : "gap-md-4"
  } gap-lg-5 w-100 flex-column ${
    isBetween768And883 ? "flex-column" : "flex-md-row"
  }`;

export const getTextFieldStyle = (isData, placeholderFontSize) => ({
  "& .Mui-disabled": {
    cursor: isData ? "not-allowed" : "default",
  },
  "& .MuiInputBase-input::placeholder": {
    fontSize: placeholderFontSize,
  },
});
