import React, { useState } from "react";
import Navigator from "../Navigator";
import { Box, Button, Container, Divider, styled } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/PestControl";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import SwapVertOutlinedIcon from "@mui/icons-material/SwapVertOutlined";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "rgba(208, 223, 255, 1)",
  borderColor: "rgba(0, 70, 139, 1)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "rgba(208, 223, 255, 1)",
    borderColor: "rgba(208, 223, 255, 1)",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "rgba(208, 223, 255, 1)",
    borderColor: "rgba(208, 223, 255, 1)",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});
const SortButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "rgba(255, 221, 184, 1)",
  borderColor: "rgba(255, 221, 184, 1)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "rgba(255, 221, 184, 1)",
    borderColor: "rgba(255, 221, 184, 1)",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "rgba(255, 221, 184, 1)",
    borderColor: "rgba(255, 221, 184, 1)",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(255, 221, 184, 1)",
  },
});
const CertifeiedButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "rgba(9, 30, 66, 0.06)",
  borderColor: "rgba(9, 30, 66, 0.06)",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "rgba(9, 30, 66, 0.06)",
    borderColor: "rgba(9, 30, 66, 0.06)",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "rgba(9, 30, 66, 0.06)",
    borderColor: "rgba(9, 30, 66, 0.06)",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(9, 30, 66, 0.06)",
  },
});
function generate(element) {
  return [0, 1, 2, 3, 4, 5].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const ServiceProviderCatalogue = () => {
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  return (
    <div style={{ marginTop: "72px" }}>
      <Navigator />

      <Container maxWidth="lg" sx={{ display: "flex", gap: "16px" }}>
        <Box
          sx={{
            // bgcolor: "#cfe8fc",
            height: "100vh",
            // border: "1px solid red",
            width: "30vw",
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontFamily: "Gilroy-Medium",
                fontSize: "20px",
                mb: "24px",
              }}
              variant="h6"
              component="div"
            >
              Sub-categories
            </Typography>
            <List
              dense={dense}
              sx={{ gap: "32px", display: "flex", flexDirection: "column" }}
            >
              {generate(
                <ListItem
                  style={{
                    border: "1px solid rgba(194, 199, 207, 1)",
                    borderRadius: "4px",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "transparent" }}>
                      <FolderIcon sx={{ color: "rgba(33, 39, 42, 1)" }} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="General Pest control"
                    secondary={secondary ? "Secondary text" : null}
                  />
                </ListItem>
              )}
            </List>
          </Grid>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box
          sx={{
            height: "100vh",
            width: "60vw",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Gilroy-Medium",
              fontSize: "20px",
              mb: "24px",
              color: "rgba(52, 53, 56, 1)",
              flexDirection: "row",
              display: "flex",
              gap: "5px",
            }}
            variant="h6"
            component="div"
          >
            Showing All
            <Typography
              sx={{
                fontFamily: "Gilroy-SemiBold",
                fontSize: "20px",
                mb: "24px",
                color: "rgba(52, 53, 56, 1)",
              }}
            >
              (9953)
            </Typography>
            <Typography
              sx={{
                fontFamily: "Gilroy-Medium",
                fontSize: "20px",
                mb: "24px",
                color: "rgba(52, 53, 56, 1)",
              }}
            >
              Pest control services
            </Typography>
          </Typography>

          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: "0px !important",
              paddingRight: "0px !important",
            }}
          >
            <Box>
              <BootstrapButton
                startIcon={
                  <div
                    style={{
                      borderRadius: "1000px",
                      backgroundColor: "rgba(0, 70, 139, 1)",
                      width: "30px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FilterListOutlinedIcon
                      sx={{
                        fontSize: "20px",
                        color: "rgba(208, 223, 255, 1)",
                      }}
                    />
                  </div>
                }
                endIcon={
                  <KeyboardArrowDownOutlinedIcon
                    sx={{ color: "rgba(0, 70, 139, 1)" }}
                  />
                }
                sx={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "16",
                  color: "rgba(0, 70, 139, 1)",
                }}
                variant="outlined"
                disableRipple
              >
                Filters
              </BootstrapButton>
            </Box>
            <Box>
              <SortButton
                variant="contained"
                startIcon={
                  <SwapVertOutlinedIcon
                    sx={{ colors: "rgba(0, 70, 139, 1)" }}
                  />
                }
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                sx={{ color: "rgba(42, 23, 0, 1)" }}
              >
                Sort
              </SortButton>
            </Box>
          </Container>

          <Grid
            container
            sx={{
              marginTop: {
                lg: "39px",
                md: "39px",
              },
              marginBottom: {
                lg: "53px",
                md: "53px",
              },
              justifyContent: "center",
            }}
          >
            <Grid item xs={12} sm={10} className="mt-8 mt-lg-0 mx-3 mx-lg-0">
              <InputGroup className="mb-3" style={{}}>
                <InputGroup.Text
                  style={{ backgroundColor: "#ffff", borderRight: "none" }}
                  id="basic-addon1"
                >
                  <SearchIcon sx={{ color: "#6A7D9F" }} />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: "none" }}
                  sx={{ color: "#6A7D9F" }}
                  className="gilroy-regular"
                  placeholder="Search for a service"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <List
              dense={dense}
              sx={{ gap: "44px", display: "flex", flexDirection: "column" }}
            >
              {generate(
                <ListItem
                  style={{
                    border: "1px solid rgba(194, 199, 207, 1)",
                    borderRadius: "4px",
                    padding: "24px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <img
                        src="images/logo/SubCategory.png"
                        alt=""
                        style={{ width: "100%", height: "116px" }}
                      />
                    </Grid>

                    <Grid item xs={9} sx={{}}>
                      <Typography
                        style={{
                          fontFamily: "Gilroy-SemiBold",
                          fontSize: "24px",
                          color: "rgba(0, 0, 0, 1)",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Shri Gaurav Pest Control Services
                        <CertifeiedButton
                          variant="contained"
                          startIcon={<CheckCircleOutlineIcon />}
                          sx={{
                            backgroundColor: "rgba(9, 30, 66, 0.06)",
                            color: "rgba(0, 0, 0, 1)",
                            fontFamily: "Gilroy-Medium",
                            fontSize: "16px",
                            color: "rgba(23, 43, 77, 1)",
                          }}
                        >
                          Certified
                        </CertifeiedButton>
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "Gilroy-Medium",
                          fontSize: "20px",
                          color: "rgba(106, 125, 159, 1)",
                          alignItems: "center",
                          lineHeight: "23px",
                        }}
                      >
                        <LocationOnIcon
                          sx={{ color: "rgba(106, 125, 159, 1)", fontSize: 30 }}
                        />
                        Ibrahimpura, Bhopal, 462001
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      sx={{
                        justifyContent: "flex-end",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Gilroy-SemiBold",
                          fontSize: "28px",
                          color: "rgba(0, 0, 0, 1)",
                        }}
                      >
                        <StarOutlinedIcon
                          sx={{ color: "rgba(250, 155, 0, 1)" }}
                        />
                        4.0/5
                      </Typography>
                      <Typography
                        style={{
                          color: "rgba(106, 125, 159, 1)",
                          fontSize: "16px",
                          fontFamily: "Gilroy-Medium",
                        }}
                      >
                        100 reviews
                      </Typography>
                    </Grid>

                    <Grid xs={12} sx={{ marginTop: "16px" }}>
                      <Typography style={{ display: "flex", gap: "7px" }}>
                        <div
                          style={{
                            borderRadius: "4px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            backgroundColor: "rgba(214, 236, 255, 1)",
                          }}
                        >
                          Bed Bug termination
                        </div>
                        <div
                          style={{
                            borderRadius: "4px",
                            paddingLeft: "4px",
                            paddingRight: "4px",
                            backgroundColor: "rgba(214, 236, 255, 1)",
                          }}
                        >
                          Pest control
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              )}
            </List>
          </Grid>
        </Box>
      </Container>

      <Box sx={{ flexGrow: 1, maxWidth: 752 }}></Box>
    </div>
  );
};

export default ServiceProviderCatalogue;
