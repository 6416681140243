import React, { useState } from "react";
import {
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  useMediaQuery,
} from "@mui/material";

const Invoicing = ({ onSave }) => {
  const [turnover, setTurnover] = useState("");
  const [specificCategory, setSpecificCategory] = useState("");

  const handleTrunover = (event) => {
    setTurnover(event.target.value);
  };

  const handleSpecificCategory = (event) => {
    setSpecificCategory(event.target.value);
  };

  const handleSave = () => {
    const fromData = {
      turnover,
      specificCategory,
    };
    onSave(fromData);
    // setTurnover("");
    // setSpecificCategory("");
  };

  // Screen Size for responsiveness ------------
  const isVerySmallScreen = useMediaQuery("(max-width: 435px) ");
  const isLargeScreen = useMediaQuery("(max-width: 992px) ");

  const labelStyle = {
    fontSize: isLargeScreen ? "14px" : "16px",
    textTransform: "none",
  };
  const labelClassStyle = "fw-bold my-auto ms-2 mt-2 gilroy text-dark";

  return (
    <>
      <div className="e_invoicing__container">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none !important",
          }}
          className="text-sm-center gilroy-regular"
        >
          e-Invoicing declaration facilitate faster turnaround time of your
          transactions
        </p>

        <p
          style={{
            fontSize: "13px",
            fontWeight: 500,
            backgroundColor: "#E4F5FF",
          }}
          className="p-3 rounded gilroy"
        >
          The e-Invoice System is meant for GST registered entity to upload all
          the B2B invoices to Invoice Registration Protal (IRP). The IRP
          generates and returns a unique invoice REference Number (IRN),
          digitally signed e-invoice and QR code to the user. <br />
          <br />
          E-Invoicing has been made mandatory by the Goods and SErvice Tax
          Council for businesses with an annual turnovr above &#8377;5 crore.
        </p>

        <div className="e-invoicing_content mt-4">
          <div className="turnover">
            <p style={labelStyle} className={labelClassStyle}>
              Turnover (max in 3 years)*
            </p>
            <TextField
              size="small"
              value={turnover}
              onChange={handleTrunover}
            />
          </div>
          <div className="mt-4">
            <p style={labelStyle} className={labelClassStyle}>
              Specific category excluded from compliance to e-invoicing as
              notified*
            </p>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                style={{
                  paddingLeft: "15px",
                  borderRadius: "4px",
                }}
                value={specificCategory}
                onChange={handleSpecificCategory}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="mt-4 text-center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#fa9b00", color: "#ffffff" }}
            onClick={handleSave}
            className="gilroy"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default Invoicing;
