import React from "react";
import "./Certification.css";
import { Button } from "@mui/material";
import Certificates from "./Certificates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function Certification() {
  return (
    <div className="mt-5 px-4 pb-0 pt-5 mb-0">
      <div className="d-flex justify-content-between gilroy">
        <h4 className="fw-bold fs-4 gilroy text-black ">
          Certifications & Accreditations
        </h4>
        <Button
          style={{
            backgroundColor: "rgb(238,238,244)",
            fontSize: "16px",
            padding: "6px 12px 7px",
          }}
          className="rounded-pill text-capitalize align-center"
        >
          <FontAwesomeIcon icon={faPlus} style={{ fontSize: "20px" }} />
          &nbsp;Add
        </Button>
      </div>
      <div className="d-flex gap-3 mt-2 mb-0 overflow-hidden">
        <Certificates />
        <Certificates />
        <Certificates />
      </div>
    </div>
  );
}

export default Certification;
