import React, { useState, useEffect } from "react";

const ProductVerify = () => {
  const [fileData, setFileData] = useState([]);

  // Load the PDF details from localStorage on component mount
  useEffect(() => {
    const savedFiles = localStorage.getItem("financialApproval");
    if (savedFiles) {
      setFileData(JSON.parse(savedFiles)); // Load the files from localStorage
    }
  }, []);

  const openPDF = (base64) => {
    try {
      // If the base64 string includes the data URL prefix, remove it
      if (base64.startsWith("data:application/pdf;base64,")) {
        base64 = base64.split(",")[1]; // Remove the prefix
      }

      // Convert base64 to binary string
      const byteCharacters = atob(base64); // Decodes the base64

      // Convert binary string to byte array
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Open the Blob URL in a new window
      const pdfWindow = window.open(blobUrl, "_blank");

      if (
        !pdfWindow ||
        pdfWindow.closed ||
        typeof pdfWindow.closed == "undefined"
      ) {
        alert("Please allow popups to view the PDF.");
      }
    } catch (error) {
      console.error("Error opening PDF:", error);
      alert(
        "There was an issue opening the PDF. Please ensure the file is valid."
      );
    }
  };

  return (
    <>
      <div>
        {" "}
        <h3
          style={{ fontSize: "20px", fontWeight: 600, color: "#000000" }}
          className="gilroy mb-5"
        >
          Documents
        </h3>
        <div className="d-flex">
          {fileData.length > 0 ? (
            fileData.map((file, index) => (
              <div
                key={index}
                style={{ width: "auto" }}
                className="file-item d-flex flex-column gap-4 p-2 py-4 border rounded align-items-center"
              >
                <img
                  src="files/pdf.png" // Make sure the path is correct
                  alt="PDF Icon"
                  style={{ width: "70px", height: "70px", cursor: "pointer" }}
                  onClick={() => openPDF(file.base64)} // Open PDF on icon click
                />
                <p
                  style={{ cursor: "pointer" }}
                  className="m-0"
                  onClick={() => openPDF(file.base64)}
                >
                  {file.name}
                </p>
              </div>
            ))
          ) : (
            <p>No files uploaded</p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductVerify;
