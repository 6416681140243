import React, { useEffect, useState } from "react";
import "./MyOrdersPaginate.css";
import { useNavigate } from "react-router-dom";
import { Button, IconButton, useMediaQuery } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { MoreVert, LocalOffer } from "@mui/icons-material";
import MyOrdersFilter from "../MyOrdersFilter/MyOrdersFilter";
import MyOrdersSort from "../MyOrderSort/MyOrderSort";
import axios from "axios";
import { baseUrl, custOrderDeleteAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";

const itemsPerPage = 4;

const MyOrdersPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);
  const [orderList, setOrderList] = useState("/customer/order/list");
  const [orderData, setOrderData] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);

  // Screen size for responsiveness--------------------

  const isMediumScreen = useMediaQuery(" (max-width: 846px) ");
  const isSmallScreen = useMediaQuery("(max-width:564px) ");
  const isVerySmallScreen = useMediaQuery(" (max-width:420px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:350px)");

  // Styling -------------------

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  //  Handling Show Bids Button -----------------------------
  const handleOrders = (id) => {
    if (id) {
      const prodId = id;
      localStorage.setItem("productDetailsId", JSON.stringify(prodId));
    }
    navigate("/my-order-page");
  };

  const handleMyOrdersFilter = (data) => {
    console.log(data);
    if (data) {
      const sorting = data;
      setOrderList(sorting);
    } else {
      setOrderData("/customer/order/list");
    }
  };
  const handleMyOrdersSort = (data) => {
    console.log(data);
    if (data) {
      const sorting = data;
      setOrderList(sorting);
    } else {
      setOrderData("/customer/order/list");
    }
  };

  useEffect(() => {
    const fetchCustOrderListAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData) {
        try {
          const response = await axios.get(baseUrl + orderList, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.data) {
            console.log("order-list", response.data.data);
            setOrderData(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustOrderListAPI();
  }, [orderList]);

  const handleMoreOption = (event, id) => {
    event.stopPropagation();
    setOpenDropdownId((prevId) => (prevId === id ? null : id)); // Toggle the dropdown for this specific item
  };

  const handleDeleteOrder = (event, id) => {
    event.stopPropagation();
    console.log(id);
    if (id) {
      const fetchCustOrderDeleteAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.get(custOrderDeleteAPI + id, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedData.token}`,
              },
            });
            if (response && response.status === 200) {
              console.log(response.data.msg);
              toast.success(response.data.msg);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          } catch (error) {}
        }
      };
      fetchCustOrderDeleteAPI();
    }
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = orderData.slice(offset, offset + itemsPerPage);

  return (
    <div className="mt-5">
      <div className="my-5">
        <div
          className={`d-flex ${
            isExtraSmallScreen ? "flex-column" : "flex-sm-row"
          } gap-2 gap-sm-inherit justify-content-between mt-5`}
        >
          <MyOrdersFilter onCheck={handleMyOrdersFilter} />
          <MyOrdersSort onCheck={handleMyOrdersSort} />
        </div>
      </div>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div
              key={item._id}
              onClick={() => handleOrders(item._id)}
              className="border my-4 py-3 px-2 px-md-2 px-lg-4 pe-sm-2 py-sm-2 rounded"
            >
              <div
                className={`d-flex ${
                  isMediumScreen ? "flex-column" : "flex-row "
                }  align-items-center ms-sm-0 justify-content-between`}
              >
                <div className="order_img__container px-sm-4">
                  <img
                    src={item.productDetails.image}
                    alt={item.title}
                    className="order_img"
                  />
                </div>

                <div className="w-100 mt-4 mt-sm-auto">
                  <div className=" d-flex flex-column justify-content-between py-lg-3 py-md-1">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <div>
                        <h4 className="order_title gilroy-regular m-0">
                          {item.productDetails.title}
                        </h4>
                      </div>
                      <div>
                        <IconButton
                          onClick={(event) => handleMoreOption(event, item._id)}
                        >
                          <MoreVert
                            sx={{
                              fontSize: isVerySmallScreen
                                ? "25px"
                                : isSmallScreen
                                ? "30px"
                                : "35px",
                              color: "#000",
                            }}
                          />
                        </IconButton>
                      </div>

                      {/* Only render the dropdown if the ID matches */}
                      {openDropdownId === item._id && (
                        <div className="more-option--box position-absolute border rounded ">
                          <div className="more-option d-flex flex-column gap-2 align-items-start justify-content-center py-2">
                            <div className="button-box w-100 px-4">
                              <p
                                sx={{ color: "#000" }}
                                className="view--details gilroy m-0 py-2"
                                onClick={() => handleOrders(item._id)}
                              >
                                View Details
                              </p>
                            </div>
                            <div className="button-box w-100 px-4">
                              <p
                                sx={{ color: "#000" }}
                                className="delete--order gilroy m-0 py-2"
                                onClick={(event) =>
                                  handleDeleteOrder(event, item._id)
                                }
                              >
                                Delete
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-1">
                      <div className="d-flex align-items-center gap-2">
                        <p className="orders_infos__text details gilroy m-0">
                          Details: {item.productDetails.description}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`d-flex ${
                        isVerySmallScreen
                          ? "flex-column align-items-start gap-3"
                          : "flex-row  align-items-end"
                      } justify-content-between  pt-2`}
                    >
                      <div className="order-details--container">
                        <div className="d-flex flex-column flex-md-row gap-1 gap-md-0 justify-content-between">
                          <div className="d-flex flex-column gap-1 gap-md-2">
                            <div className="d-flex align-items-center gap-2">
                              <LocalOffer
                                sx={{ width: 15, height: 15, color: "#275EA7" }}
                              />
                              <p className="orders_infos__text details gilroy fs-5 m-0">
                                <span className="text-decoration-line-through fs-6">
                                  &#8377; {item.productDetails.mrp}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ color: "#2a9b3c" }}>
                                  &#8377; {item.totalPrice}
                                </span>
                              </p>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <p className="orders_infos__text details details-text gilroy m-0 ps-md-1">
                                  Order ID: {item._id}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column gap-1 gap-md-2">
                            <div className="d-flex align-items-center gap-2">
                              <p className="orders_infos__text details gilroy m-0">
                                Quantity: {item.qty}
                              </p>
                            </div>

                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <FontAwesomeIcon
                                  icon={faCalendarAlt}
                                  style={{
                                    color: "#275EA7",
                                    fontSize: "14.5px",
                                  }}
                                />
                                <p className="orders_infos__text details gilroy m-0">
                                  {item.oDate.split(" ")[0]} |{" "}
                                  {item.oDate.split(" ").slice(1).join(" ")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-sm-center order_infos__order_status">
                        <p
                          style={{
                            backgroundColor:
                              item.status === "Completed"
                                ? "#E8FBE8"
                                : "#F2EFCF",
                            color:
                              item.status === "Completed"
                                ? "#2A9928"
                                : "#898400",

                            // ------------------------------------
                          }}
                          className="order_status m-0 rounded-pill gilroy"
                        >
                          Status: {item.status}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(orderData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default MyOrdersPaginate;
