import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seeMoreServiceItem: null,
};

const seeMoreServiceSlice = createSlice({
  name: "seeMoreService",
  initialState,
  reducers: {
    setSeeMoreServicesItem: (state, action) => {
      state.seeMoreServiceItem = action.payload;
    },
  },
});

export const { setSeeMoreServicesItem } = seeMoreServiceSlice.actions;
export default seeMoreServiceSlice.reducer;
