import React, { useState, useEffect } from "react";
import "./Automobile.css";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { useDispatch } from "react-redux";
import { setServiceSubCateIdItem } from "../../../redux/serviceSubCateIdSlice";
import "react-multi-carousel/lib/styles.css";

const Automobile = ({ serviceData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isData, setIsData] = useState(false);
  const [serviceName, setServiceName] = useState("");

  useEffect(() => {
    if (serviceData && serviceData[1]) {
      console.log(serviceData[1]);
      console.log(serviceData[1].name);
      setServiceName(serviceData[1].name);
      setIsData(true);
    }
  }, [serviceData]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const handleServiceChildCategId = (id) => {
    const cateId = id;
    dispatch(setServiceSubCateIdItem(cateId));
    navigate("/cust-product-service");
    console.log("service sub Id and Name", cateId, serviceName);
  };

  const design = (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
        className="bsiness_service"
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span>{serviceName}</span>
          </Box>
        </Box>
        <div className="automobile_carousel">
          <Carousel
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            responsive={responsive}
            pauseOnHover
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {isData &&
              serviceData[1].children.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    className="d-flex justify-content-center flex-column align-items-center mx-1"
                    onClick={() =>
                      handleServiceChildCategId(item._id, item.name)
                    }
                  >
                    <img
                      src={item.path}
                      alt={item.name}
                      className="item_image px-2"
                    />

                    <p
                      className="pb-1 px-0 pt-0 mx-0 mb-0 gilroy-regular"
                      style={{
                        marginTop: "19px",
                        color: "#000",
                        textAlign: "center",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {item.name}
                    </p>

                    <p
                      className="p-0 m-0 gilroy-regular"
                      style={{
                        color: " #42474E",
                        textAlign: "center",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {item.description}
                    </p>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Container>
    </>
  );
  return design;
};

export default Automobile;
