import React, { useEffect } from "react";
import "./Otp.css";
import { Link, useMediaQuery } from "@mui/material";

function Otp({ otp, handleOtpChange }) {
  const isExtraVerySmallScreen = useMediaQuery("(max-width:305x) ");
  const isVerySmallScreen = useMediaQuery("(max-width:568px) ");
  const isBetween664pxand744px = useMediaQuery(
    " (min-width:665px) and (max-width: 744px) "
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="otp-verification d-flex flex-column justify-content-center align-items-center mt-2 mt-lg-4 mt-sm-5 w-100 me-0">
      <div
        style={{
          padding: isVerySmallScreen ? "12px" : "30px 30px 20px",
          width: isExtraVerySmallScreen
            ? "16rem"
            : isBetween664pxand744px
            ? "18rem"
            : "100%",
        }}
        className="mt-2 mt-lg-4 border d-flex flex-column rounded"
      >
        <div className="d-flex">
          <label
            htmlFor="exampleFormControlInput1"
            style={{ fontSize: isVerySmallScreen ? "14px" : "16px" }}
            className="form-label fw-bold my-auto ms-0 gilroy text-dark"
          >
            Enter the 6-digit code sent to your number
          </label>
        </div>
        <div className="otp-inputBox d-flex gap-2 mt-3 ms-0">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
              className="otp-box p-2 fs-6 fs-sm-5 fw-bold border rounded gilroy"
              maxLength={1}
              id={`otp-input-${index}`}
              autoComplete="off"
              style={{
                textAlign: "center",
                width: isVerySmallScreen
                  ? "30px"
                  : isBetween664pxand744px
                  ? "30px"
                  : "40px",
                height: isVerySmallScreen
                  ? "30px"
                  : isBetween664pxand744px
                  ? "30px"
                  : "40px",
              }}
            />
          ))}
        </div>
        <p
          className="mt-3"
          style={{ fontSize: isVerySmallScreen ? "14px" : "16px" }}
        >
          Didn&apos;t receive code?&nbsp;
          <Link sx={{ textDecoration: "none" }}>Resend</Link>
        </p>
      </div>
    </div>
  );
}

export default Otp;
