import React, { useState, useEffect } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Popular.css";
import { useDispatch } from "react-redux";
import { setPopularServiceCateId } from "../../../../redux/popularCateIdSlice";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Popular = ({ data }) => {
  const [isData, setIsData] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && data.popular) {
      setIsData(true);
      // console.log(data.popular);
    }
  }, [data]);

  const isDesktopScreen = useMediaQuery("(max-width:1240px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 464px) ");

  // const PrevButton2 = ({ onClick }) => (
  //   <button onClick={onClick}>
  //     <ArrowBackIcon color="black" />
  //   </button>
  // );

  // const NextButton2 = ({ onClick }) => (
  //   <button onClick={onClick}>
  //     <ArrowForwardIcon color="black" />
  //   </button>
  // );

  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handlePopularCateId = (id) => {
    // console.log("popular id", id);
    dispatch(setPopularServiceCateId(id));
    window.scrollTo(0, 0);
    navigate("/cust-product-service ");
  };

  const design = (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
      >
        <Box className="d-flex justify-content-center mb-4 mb-lg-5">
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "#1365AF" }}>Popular </span> services near you
          </Box>
        </Box>
        <div className="popular_carousel">
          <Carousel
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            responsive={responsive}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {isData &&
              data.popular.map((item) => {
                return (
                  <div
                    className="d-flex justify-content-center flex-column align-items-center"
                    key={item._id}
                    onClick={() => handlePopularCateId(item._id)}
                  >
                    <img
                      src={item.path}
                      width={
                        isVerySmallScreen
                          ? "170px"
                          : isDesktopScreen
                          ? "155px"
                          : "162px"
                      }
                      height="162px"
                      className="rounded-circle"
                      alt={item.name}
                    />
                    <p
                      className="gilroy-regular"
                      style={{
                        marginTop: "31px",
                        color: "#42474E",
                        textAlign: "center",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Container>
    </>
  );
  return design;
};

export default Popular;
