import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import {
  Button,
  Modal,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { custLoginInAPI } from "../../../../Config/apiConfig";
import { custLoginOtpVerifyAPI } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = ({
  loginOpen,
  open,
  handleClose,
  handleLoginClose,
  style,
}) => {
  const [emailId, setEmailId] = useState("");
  const [otp, setOtp] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loginId, setLoginId] = useState("");

  const handleEmailId = (event) => {
    setEmailId(event.target.value);
  };

  const handleOtp = (event) => {
    setOtp(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleGenerateOtp = async () => {
    try {
      if (emailId) {
        const response = await axios.post(custLoginInAPI, {
          email: emailId,
        });
        // console.log(response.data);
        setLoginId(response.data.data);
        toast.success(response.data.msg);
      }
    } catch (error) {
      if (error && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  const hadleVerifyOtp = async () => {
    try {
      if (otp) {
        const response = await axios.post(custLoginOtpVerifyAPI, {
          id: loginId,
          otp: otp,
        });
        localStorage.setItem(
          "custLoginCredentials",
          JSON.stringify(response.data)
        );
        // console.log(response.data);
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setEmailId("");
        setOtp("");
        handleLoginClose();
      }
    } catch (error) {}
  };

  return (
    <>
      <Modal open={loginOpen} onClose={handleLoginClose}>
        <Box sx={style} mx className="no-scrollbar">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-row align-items-center gap-3">
              <CloseIcon
                onClick={handleLoginClose}
                fontSize="small"
                color="rgba(26, 27, 30, 1)"
              />
              <p
                style={{
                  fontFamily: "Gilroy-SemiBold",
                  fontSize: "28px",
                  color: "rgba(19, 101, 175, 1)",
                }}
                className="register_to_continue m-0"
              >
                Welcome Back!&nbsp;
                <span style={{ color: "rgba(0, 0, 0, 1)" }}>Please Login</span>
              </p>
            </div>
            <div className="registration_page_form mt-3">
              <div
                style={{
                  gap: "29px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    color: "rgba(26, 27, 30, 1)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    gap: "4px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  Email ID
                  <Form.Control
                    sx={{ color: "#6A7D9F" }}
                    className="gilroy-regular"
                    placeholder="Enter Email Id"
                    value={emailId}
                    onChange={handleEmailId}
                  />
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      marginTop: "10px",
                    }}
                    style={{ alignSelf: "flex-start" }}
                    className="nav-icon-btn2 gilroy"
                    onClick={handleGenerateOtp}
                  >
                    Get OTP
                  </Button>
                </div>

                <div
                  style={{
                    color: "rgba(26, 27, 30, 1)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  OTP
                  <Form.Control
                    sx={{ color: "#6A7D9F" }}
                    className="gilroy-regular"
                    value={otp}
                    onChange={handleOtp}
                  />
                  {/* <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      marginTop: "10px",
                    }}
                    style={{ alignSelf: "flex-start" }}
                    variant="contained"
                    className="nav-icon-btn2 gilroy"
                  >
                    Verify
                  </Button> */}
                  <div
                    style={{
                      fontFamily: "Gilroy-Medium",
                      fontSize: "15px",
                      color: "rgba(128, 128, 127, 1)",
                      display: "flex",
                      alignSelf: "flex-end",
                    }}
                  >
                    Didn’t receive code?&nbsp;
                    <span style={{ color: "rgba(19, 101, 175, 1)" }}>
                      Resend
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <FormGroup>
                  <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="small"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                    }
                    label="Remember me"
                    style={{ fontFamily: "Gilroy-Regular" }}
                  />
                  {/* <FormControlLabel
                    required
                    control={
                      <Checkbox
                        size="small"
                        checked={termsAccepted}
                        onChange={handleTermsAcceptedChange}
                      />
                    }
                    label="I agree to all the Terms and Privacy policy"
                    style={{ fontFamily: "Gilroy-Regular" }}
                  /> */}
                </FormGroup>
              </div>
              <div
                className="w-100 d-flex flex-column justify-content-center align-items-center gap-3"
                // style={{
                //   // border: "1px solid red",
                //   display: "flex",
                //   justifyContent: "center",
                //   gap: "10px",
                // }}
              >
                <Button
                  sx={{
                    px: {
                      lg: "12px",
                      md: "1px",
                    },
                    backgroundColor: "rgba(19, 101, 175, 1)",
                    "&:hover": {
                      backgroundColor: "rgba(19, 101, 175, 1)",
                    },
                    fontSize: "16px",
                    color: "#ffffff",
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    padding: "10px 20px",
                  }}
                  className="gilroy"
                  onClick={hadleVerifyOtp}
                >
                  Login
                </Button>

                {/* 
                <Button
                  sx={{
                    px: {
                      lg: "12px",
                      md: "1px",
                    },
                  }}
                  startIcon={
                    <img
                      src="images/logo/google.png"
                      style={{ width: "14px", height: "14px" }}
                      alt=""
                    />
                  }
                  style={{
                    border: " 2px solid rgba(19, 101, 175, 1)",
                    fontSize: "16px",
                    color: "rgba(19, 101, 175, 1)",
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontFamily: "Gilroy-SemiBold",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    width: "252px",
                  }}
                >
                  Sign-in with google
                </Button> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default LoginPage;
