import { TextField } from "@mui/material";
import React from "react";

const BusinessAbout = () => {
  const text =
    "Lorem ipsum dolor sit amet consectetur. Enim erat a egestas faucibus donec quis. Leo nibh tincidunt sit id sed sit elementum. Adipiscing donec lacus faucibus pretium sed neque viverra ac. Ultrices turpis lacinia diam commodo faucibus. Neque tristique amet quis feugiat enim id libero quam. Mus phasellus nunc habitant interdum malesuada morbi ligula a dictumst. Malesuada risus tellus habitant lacinia imperdiet mus lectus magna. Vitae augue tortor adipiscing sagittis sem. Auctor faucibus pharetra facilisis mauris justo congue turpis. Dictum duis nibh ut sed maecenas tempor";
  return (
    <>
      <section className="mt-5">
        <h2
          style={{ fontSize: "24px", fontWeight: 600, lineHeight: "28.63px" }}
          className="gilroy"
        >
          About
        </h2>
        <TextField
          style={{
            fontSize: "20px",
            fontWeight: 500,
            color: "#42474E",
            lineHeight: "23.54px",
          }}
          slotProps={{
            input: {
              readOnly: true,
            },
          }}
          rows={5}
          multiline
          value={text}
          fullWidth
          className="gilroy"
        />
      </section>
    </>
  );
};

export default BusinessAbout;
