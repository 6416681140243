import { useMediaQuery } from "@mui/material";

const useResponsiveStyles = () => {
  // Screen Size for responsivness
  const isSmallScreen = useMediaQuery("(max-width:575px)");
  const isBetween768And883 = useMediaQuery(
    "(min-width: 768px) and (max-width: 883px)"
  );
  const isLargeScreen = useMediaQuery("(max-width: 992px)");

  // Styling logic
  const labelStyle = {
    fontSize: isLargeScreen ? "14px" : "16px",
    textTransform: "none",
  };

  const labelClassStyle = "fw-bold my-auto ms-2 mt-2 gilroy text-dark";
  const sectionStyle = `d-flex flex-column flex-sm-row ${
    isBetween768And883 ? "flex-md-column" : "flex-md-row"
  } gap-3 gap-lg-5 justify-content-between align-items-center`;

  return {
    labelStyle,
    labelClassStyle,
    sectionStyle,
    isSmallScreen,
    isBetween768And883,
  };
};

export default useResponsiveStyles;
