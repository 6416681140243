import React from "react";
import "./BarChartSection.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { dataset } from "./dataset/datasetcontent";

const valueFormatter = (value) => `${value}mm`;

const chartSetting = {
  series: [{ dataKey: "seoul", valueFormatter }],
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

const BarChartSection = () => {
  return (
    <>
      <div className="earnings__bar_chart" style={{ width: "100%" }}>
        <BarChart
          dataset={dataset}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "month",
              // categoryGapRatio: 0.5,
              // tickLabelPlacement: "middle",
            },
          ]}
          {...chartSetting}
        />
      </div>
    </>
  );
};

export default BarChartSection;
