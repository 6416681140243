import React, { useState } from "react";
import "./QuotesSentOrder.css";
import { Avatar, Box, Button, IconButton, useMediaQuery } from "@mui/material";
import { MoreVertRounded, SearchRounded } from "@mui/icons-material";
import { orderData } from "../../Dashboard/RecentOrder/OrderCarousel/orderdata.js";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faChevronLeft,
  faChevronRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import FilterQuotesSent from "./QuotesSentFilter/FilterQuotesSent.jsx";
import SortQuotesSent from "./QuotesSentSort/SortQuotesSent.jsx";

const itemsPerPage = 4;

function QuotesSentOrder() {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery(" (max-width:992px) ");
  const isMoreThnMediumScreen = useMediaQuery(" (max-width:668px) ");
  const isSmallScreen = useMediaQuery(" (max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery(" (max-width: 420px) ");

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = orderData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="quotes_sent__container">
        <div className="mb-5 mt-5">
          <div
            className={`d-flex ${
              isExtraSmallScreen ? "flex-column" : "flex-sm-row"
            } gap-2 gap-sm-inherit justify-content-between w-100`}
          >
            <FilterQuotesSent />
            <SortQuotesSent />
          </div>
        </div>

        <div
          className={` my-5 ${isExtraSmallScreen ? "px-0" : "px-5"}  w-100 `}
        >
          <div className="px-sm-5">
            <div className="search-bar border px-2 d-flex align-items-center justify-content-center w-100">
              <SearchRounded style={{ color: "rgba(106, 125, 159, 1)" }} />
              <Box sx={{ m: 0, width: "100%" }}>
                <input
                  className="w-100 fs-5 fw-medium"
                  type="text"
                  id="searchForService"
                  placeholder="Search For Service"
                  value={searchText}
                  onChange={handleInputChange}
                />
              </Box>
            </div>
          </div>
        </div>

        {currentItems.map((item) => (
          <div
            key={item.id}
            className="carousel border p-4 rounded gilroy mb-4 mt-1 "
          >
            <div className="carousel-name-order-id d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column flex-sm-row gap-2 align-items-center ">
                <div className="d-flex align-items-center">
                  <Avatar
                    src={item.image}
                    sx={{
                      width: isSmallScreen ? 28 : 35,
                      height: isSmallScreen ? 28 : 35,
                    }}
                  />
                  <p className="customer-name m-0">&nbsp;{item.name}</p>
                </div>
                <div className="order-set-time text-lowercase gilroy d-flex  align-items-center gap-1 gap-md-2">
                  <FontAwesomeIcon
                    icon={faCircle}
                    style={{ color: "#6a7d9f", fontSize: "5px" }}
                  />
                  <p className="order_time text-lowercase m-0">
                    {" "}
                    &nbsp;{item.orderSetTime}
                  </p>
                </div>
              </div>
              <IconButton>
                <MoreVertRounded sx={{ color: "#000000", fontSize: "30px" }} />
              </IconButton>
            </div>
            <div
              className={`d-flex ${
                isExtraSmallScreen ? "flex-column" : "flex-row"
              } gap-3 mt-2 `}
            >
              <p
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                className="gilroy px-2 rounded m-0"
              >
                General Pest Control
              </p>
              <p
                style={{
                  color: "rgba(0, 30, 47, 1)",
                  backgroundColor: "rgba(214, 236, 255, 1)",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                className="gilroy px-2 rounded m-0"
              >
                Pest Control
              </p>
            </div>
            <div
              className={`d-flex ${
                isMoreThnMediumScreen
                  ? "flex-column mt-1 gap-2"
                  : "flex-row gap-3"
              } gap-md-5 mt-3 mb-2 mb-md-3 justify-content-start`}
            >
              <div className="carousel-order-information d-flex align-items-center gap-2 ">
                {/* <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ color: "#6a7d9f", fontSize: "18px" }}
                /> */}
                <img src="icons/about.png" alt="" className="quotes_icons" />
                <p className="quotes_sent__details text-color m-0 p-0">
                  {item.information}
                </p>
              </div>
              <div className="quotes-order-price d-flex align-items-center ms-md-1 ps-md-1 gap-2">
                <CurrencyRupeeIcon
                  sx={{ fontSize: isLargeScreen ? 18 : 22, color: "#6a7d9f" }}
                />
                <p className="quotes_sent__details text-color m-0 p-0">
                  {item.price}
                </p>
              </div>
            </div>
            <div
              className={`carousel-order-location-date d-flex ${
                isMoreThnMediumScreen
                  ? "flex-column mt-1 gap-2"
                  : "flex-row gap-3"
              } gap-md-5`}
            >
              <div className="carousel-order-location d-flex align-items-center gap-2 ps-0">
                {/* <Location color="#6a7d9f" height="23px" width="23px" /> */}
                {/* <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  style={{ color: "#6a7d9f", fontSize: "19px" }}
                /> */}
                <img src="icons/location.png" alt="" className="quotes_icons" />
                <p className="quotes_sent__details text-color m-0 pe-2 py-0">
                  {item.location}
                </p>
              </div>
              <div className="carousel-order-date d-flex align-items-center gap-2 ">
                {/* <FontAwesomeIcon
                  icon={faCalendarAlt}
                  style={{ color: "#6a7d9f", fontSize: "19px" }}
                /> */}
                <img src="icons/calendar.png" alt="" className="quotes_icons" />
                <p className="quotes_sent__details text-color m-0 p-0">
                  {item.date}
                </p>
              </div>
            </div>

            <div
              className={`carousel-order-status-button d-flex  ${
                isExtraSmallScreen
                  ? "flex-column justify-content-start align-items-start"
                  : "flex-row justify-content-end align-items-center"
              }   gap-2 mt-3 mt-lg-1`}
            >
              <p className="carousel-order-status rounded-pill m-0 ">
                Status: {item.status}
              </p>
              <Button
                variant="contained"
                size="small"
                fullWidth={isExtraSmallScreen}
                style={{ fontSize: "18px", fontWeight: 500 }}
                className="carousel-order-update-button gilroy p-1 px-4 text-capitalize"
              >
                {/* <Chatbubbles color="#ffffff" height="23px" width="23px" /> */}
                <FontAwesomeIcon
                  icon={faComment}
                  style={{ color: "#ffffff", fontSize: "19px" }}
                />
                &nbsp;Chat
              </Button>
            </div>
          </div>
        ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#6a7d9f", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#6a7d9f", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(orderData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default QuotesSentOrder;
