export const reviewsContent = [
  {
    id: 1,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 2,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 3,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 4,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 5,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 6,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 7,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 9,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 8,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 9,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 10,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 11,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 12,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 13,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 14,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 15,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 16,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 17,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 18,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 19,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 20,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 21,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 22,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
  {
    id: 23,
    name: "Siddhi Gupta",
    avatar: "/static/images/avatar/1.jpg",
    feedback:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt explicabo illum obcaecati labore reiciendis harum iusto ipsa et doloremque nihil vero, odio iure voluptate accusamus omnis expedita fuga autem, natus delectus sapiente dolore esse quidem. Cum dolores odit laborum possimus accusamus consequatur sunt illum, exercitationem, a inventore eius odio saepe.",
    date: "Nov 09, 2022",
    location: "Mumbai",
    rating: 3.4,
  },
];
