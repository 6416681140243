import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popularProductCateId: "",
};

const custProductCateIdSlice = createSlice({
  name: "productCateId",
  initialState,
  reducers: {
    setPopularProductCateId: (state, action) => {
      state.popularProductCateId = action.payload;
    },
  },
});

export const { setPopularProductCateId } = custProductCateIdSlice.actions;
export default custProductCateIdSlice.reducer;
