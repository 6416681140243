import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  custAuctionCateId: "",
};

const custAuctionIdSlice = createSlice({
  name: "custAuctionId",
  initialState,
  reducers: {
    setCustAuctionCateId: (state, action) => {
      state.custAuctionCateId = action.payload;
    },
  },
});

export const { setCustAuctionCateId } = custAuctionIdSlice.actions;
export default custAuctionIdSlice.reducer;
