import React from "react";
import "./BusinessDetails.css";
import { Button, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { RocketLaunch, StarRateRounded, Share } from "@mui/icons-material";

const BusinessDetails = () => {
  return (
    <>
      <div className="bidder_profile__business_details__container mt-5 pt-4 ">
        <section className="d-flex flex-column gap-4 ">
          <Grid container spacing={0} className=" ">
            <Grid sm={12} md={9} className="">
              <div>
                <h1 className="business_heading gilroy m-0">
                  Shri Gaurav Pest Control Services
                </h1>

                <div className="d-flex align-items-center gap-2">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{ fontSize: "19px", color: "#4A7B9D" }}
                    className=" px-1"
                  />
                  <p className="business_address gilroy m-0 ">
                    Arera Colony, Bhopal, 462001
                  </p>
                </div>

                <div className="d-flex gap-5 mt-3">
                  <div className="d-flex align-items-center gap-2">
                    <FontAwesomeIcon
                      icon={faUserGroup}
                      style={{
                        fontSize: "17px",
                        color: "#4A7B9D",
                        transform: "scaleX(-1)",
                      }}
                    />
                    <p className="business_address gilroy m-0">30 Employees </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <RocketLaunch sx={{ fontSize: "21px", color: "#4A7B9D" }} />
                    <p className="business_address gilroy m-0">
                      10+ years Experience
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid sm={12} md={3}>
              <div className="d-flex flex-column align-items-center align-items-sm-end gap-3">
                <div className="d-flex gap-3">
                  <div
                    style={{
                      height: "32px",
                      width: "32px",
                      padding: "3.2px",
                      backgroundColor: "rgba(250, 155, 0, 1)",
                    }}
                    className=" rounded-circle text-center"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      style={{ color: "ffffff" }}
                    />
                  </div>
                  <div
                    style={{
                      height: "32px",
                      width: "32px",
                      padding: "3.2px",
                      backgroundColor: "rgba(250, 155, 0, 1)",
                    }}
                    className=" rounded-circle text-center"
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      style={{ color: "ffffff" }}
                    />
                  </div>
                  <div
                    style={{
                      height: "32px",
                      width: "32px",
                      padding: "3.2px",
                      backgroundColor: "rgba(250, 155, 0, 1)",
                    }}
                    className=" rounded-circle text-center"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: "ffffff" }}
                    />
                  </div>
                </div>
                <div className="business_details_ratings__reviews d-flex flex-column align-items-center">
                  <div className="d-flex flex-column flex-md-row">
                    <StarRateRounded
                      style={{ fontSize: "22.5", color: "#FA9B00" }}
                    />
                    <p className=" business_details__ratings__text gilroy m-0">
                      4.0/5
                    </p>
                  </div>
                  <p className="business_details__reviews__text gilroy m-0 pb-1">
                    100 reviews
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid sm={12} md={6}>
              <div className="d-flex gap-3">
                <p
                  style={{
                    fontSize: "16px",
                    color: "#42474E",
                    fontWeight: 600,
                  }}
                  className="border rounded p-2 gilroy"
                >
                  <span style={{ fontWeight: 500 }}>Response:</span>&nbsp;
                  Within an hour
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#42474E",
                    fontWeight: 600,
                  }}
                  className="border rounded p-2 gilroy"
                >
                  <span style={{ fontWeight: 500 }}>Open:</span>&nbsp;9 AM to 11
                  Pm
                </p>
              </div>
            </Grid>
            <Grid sm={12} md={6}>
              <div className="d-flex gap-3 justify-content-end">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#6A7D9F",
                    textTransform: "none",
                    border: "1px solid #C2C7CF",
                  }}
                  className="gilroy"
                >
                  <Share sx={{ fontSize: "20px" }} />
                  &nbsp; Share
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    fontSize: "20px",
                    fontWeight: 500,
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  className="gilroy"
                >
                  Request Quote
                </Button>
              </div>
            </Grid>
          </Grid>
        </section>
      </div>
    </>
  );
};

export default BusinessDetails;
