import React from "react";
import "./AccountInfo.css";
import {
  Favorite,
  ArrowForwardIosRounded,
  EditCalendarRounded,
} from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faMapMarkerAlt,
  faWallet,
  faGear,
} from "@fortawesome/free-solid-svg-icons";

const AccountInfo = () => {
  const navigate = useNavigate();
  const handleLogoutBtn = () => {
    localStorage.removeItem("custLoginCredentials");
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1500);
  };
  return (
    <div>
      <div className="mt-4">
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <Favorite sx={{ width: "25px", height: "25px" }} />
            <p className="account_contents_items m-0">My Favorites</p>
          </div>
          <div>
            <IconButton>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3 mt-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <EditCalendarRounded sx={{ width: "25px", height: "25px" }} />
            <p className="account_contents_items m-0">My Reviews</p>
          </div>
          <div>
            <IconButton>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3 mt-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <FontAwesomeIcon
              icon={faWallet}
              style={{ fontSize: "20px" }}
              className="ps-1"
            />
            <p className="account_contents_items m-0">My Payment</p>
          </div>
          <div>
            <IconButton>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3 mt-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ fontSize: "22px" }}
              className="ps-1"
            />
            <p className="account_contents_items m-0">My Address</p>
          </div>
          <div>
            <IconButton>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>
        <Box
          style={{ backgroundColor: "#F4F3F7" }}
          className="d-flex justify-content-between align-items-center p-3 mt-3"
        >
          <div className="d-flex gap-2 align-items-center ps-2">
            <FontAwesomeIcon icon={faGear} style={{ fontSize: "21px" }} />
            <p className="account_contents_items m-0">Settings</p>
          </div>
          <div>
            <IconButton>
              <ArrowForwardIosRounded
                sx={{ width: "20px", height: "20px", color: "#6A7D9F" }}
              />
            </IconButton>
          </div>
        </Box>

        <div className="mt-5">
          <Button
            variant="outlined"
            className="gilroy"
            sx={{
              fontSize: "18px",
              backgroundColor: "#ffcccc",
              border: "2px solid #ff5c5c",
              color: "#000",
              boxShadow: "1px 4px 7px -4px",
              "&:hover": {
                backgroundColor: "#ffaaaa",
                border: "2px solid #ff5c5c",
              },
            }}
            onClick={handleLogoutBtn}
          >
            Log Out
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
