import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#fff",
    "&:hover": {
      backgroundColor: "rgba(31, 132, 90, 0.08)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1F845A",
    opacity: 1,
  },
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&::before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&::after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 18,
    height: 18,
    margin: 1,
  },
}));

const ServiceCatelogue = () => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <>
      <div
        style={{
          width: "10rem",
          border: "1px solid rgba(114, 119, 127, 1)",
        }}
        className="action-1 rounded p-3"
      >
        <h6
          style={{
            fontSize: "20px",
            color: "rgba(0,0,0,1)",
            textAlign: "center",
          }}
          className="fw-normal mt-2"
        >
          Service Catelogue
        </h6>
        <div
          style={{ margin: "0 auto" }}
          className="straight-line border border-black"
        ></div>
        <div style={{}} className="mt-3 py-3 d-flex justify-content-center">
          <FormGroup className=" ps-4 pe-0">
            <FormControlLabel control={<Android12Switch />} />
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default ServiceCatelogue;
