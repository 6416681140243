import React from "react";
import "./ContactsBox.css";
import { Avatar, Badge, styled } from "@mui/material";
import { PushPin, DoneAllRounded } from "@mui/icons-material";

const ContactsBox = () => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  return (
    <>
      <div className="chats_contacts__box__container d-flex flex-column gap-2  ">
        <div className="contact_box__active p-2 d-flex align-items-center ">
          <div className="chats_contacts__avatar ">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt="hello"
                src="images/myaccount/profile_img.jpg"
                sx={{ width: "36px", height: "36px" }}
              />
            </StyledBadge>
          </div>
          <div className="chats_contact__name__isactive d-flex flex-column gap-2 w-100 h-100">
            <div className="d-flex justify-content-between align-items-center">
              <p className="chat_contact__name gilroy m-0">
                <span className="chats_contacts__pin pe-1">
                  <PushPin
                    sx={{
                      width: 13,
                      height: 13,
                      color: "#8C8C8C",
                      rotate: "40deg",
                    }}
                  />
                </span>
                Pooja Verma
              </p>
              <span className="chats_last_seen__time">16.45</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="chats_last_seen__chats border gilroy-regular m-0">
                Can we round it to 5k?
              </p>
              <span>
                <DoneAllRounded
                  sx={{ width: 13, height: 13, color: "#2A9928" }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="contact_box p-2 d-flex align-items-center ">
          <div className="chats_contacts__avatar ">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt="hello"
                src="images/myaccount/profile_img.jpg"
                sx={{ width: "36px", height: "36px" }}
              />
            </StyledBadge>
          </div>
          <div className="chats_contact__name__isactive d-flex flex-column gap-2 w-100 h-100">
            <div className="d-flex justify-content-between align-items-center">
              <p className="chat_contact__name gilroy m-0">
                <span className="chats_contacts__pin pe-1">
                  <PushPin
                    sx={{
                      width: 13,
                      height: 13,
                      color: "#8C8C8C",
                      rotate: "40deg",
                    }}
                  />
                </span>
                Pooja Verma
              </p>
              <span className="chats_last_seen__time">16.45</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="chats_last_seen__chats border gilroy-regular m-0">
                Can we round it to 5k?
              </p>
              <span>
                <DoneAllRounded
                  sx={{ width: 13, height: 13, color: "#2A9928" }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="contact_box p-2 d-flex align-items-center ">
          <div className="chats_contacts__avatar ">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar
                alt="hello"
                src="images/myaccount/profile_img.jpg"
                sx={{ width: "36px", height: "36px" }}
              />
            </StyledBadge>
          </div>
          <div className="chats_contact__name__isactive d-flex flex-column gap-2 w-100 h-100">
            <div className="d-flex justify-content-between align-items-center">
              <p className="chat_contact__name gilroy m-0">
                <span className="chats_contacts__pin pe-1">
                  <PushPin
                    sx={{
                      width: 13,
                      height: 13,
                      color: "#8C8C8C",
                      rotate: "40deg",
                    }}
                  />
                </span>
                Pooja Verma
              </p>
              <span className="chats_last_seen__time">16.45</span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="chats_last_seen__chats border gilroy-regular m-0">
                Can we round it to 5k?
              </p>
              <span>
                <DoneAllRounded
                  sx={{ width: 13, height: 13, color: "#2A9928" }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsBox;
