import React, { useState, useEffect } from "react";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyOutlinedIcon from "@mui/icons-material/KeyOutlined";
import { Box, InputLabel, TextField, useMediaQuery } from "@mui/material";
// import { Person } from "react-ionicons";

function UserVerification({ onDataChange, onValidate }) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstnameError, setFirstnameError] = useState("");
  const [lastnameError, setLastnameError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const isSmallScreen = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateFirstname = (newFirstname) => {
    if (newFirstname.trim() === "") {
      setFirstnameError("Firstname is required");
      return false;
    } else {
      setFirstnameError("");
      return true;
    }
  };

  const validateLastname = (newLastname) => {
    if (newLastname.trim() === "") {
      setLastnameError("Lastname is required");
      return false;
    } else {
      setLastnameError("");
      return true;
    }
  };

  const validateUsername = (newUsername) => {
    if (newUsername.trim() === "") {
      setUsernameError("Username is required");
      return false;
    } else {
      setUsernameError("");
      return true;
    }
  };

  const validatePassword = (newPassword) => {
    if (newPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      return false;
    } else if (!/[A-Z]/.test(newPassword)) {
      setPasswordError("Password must contain at least one uppercase letter");
      return false;
    } else {
      setPasswordError("");
      return true;
    }
  };

  const handleFirstNameChange = (event) => {
    const newFirstname = event.target.value;
    setFirstname(newFirstname);
    validateFirstname(newFirstname);
    triggerDataChange(newFirstname, lastname, username, password);
  };

  const handleLastnameChange = (event) => {
    const newLastname = event.target.value;
    setLastname(newLastname);
    validateLastname(newLastname);
    triggerDataChange(firstname, newLastname, username, password);
  };

  const handleUsernameChange = (event) => {
    const newUsername = event.target.value;
    setUsername(newUsername);
    validateUsername(newUsername);
    triggerDataChange(firstname, lastname, newUsername, password);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    triggerDataChange(firstname, lastname, username, newPassword);
  };

  const triggerDataChange = (firstname, lastname, username, password) => {
    const isValid =
      validateFirstname(firstname) &&
      validateLastname(lastname) &&
      validateUsername(username) &&
      validatePassword(password);

    if (isValid) {
      onDataChange({ firstname, lastname, username, password });
    } else {
      onDataChange(null);
    }
    onValidate(isValid);
  };

  return (
    <>
      <div className="d-flex flex-column flex-sm-row justify-content-between py-4 ms-2 gap-2 gap-sm-5 mb-4 ">
        <div className="firstname-container w-100 px-2 ">
          <div className="firstname__label d-flex align-items-center mb-2">
            <PersonRoundedIcon
              sx={{
                fontSize: isSmallScreen ? "22px" : "25px",
                color: "rgba(19, 101, 175, 1)",
              }}
            />
            <InputLabel>
              <p
                style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                className="my-auto gilroy fw-bold ms-2 text-black"
              >
                First Name
              </p>
            </InputLabel>
          </div>
          <Box>
            <TextField
              id="firstname"
              size="small"
              fullWidth={true}
              value={firstname}
              onChange={handleFirstNameChange}
              placeholder="Enter First Name"
              error={!!firstnameError}
              helperText={firstnameError}
            />
          </Box>
        </div>
        <div className="lastname-container w-100 px-2">
          <div className="lastname__label d-flex align-items-center mb-2">
            <PersonRoundedIcon
              sx={{
                fontSize: isSmallScreen ? "22px" : "25px",
                color: "rgba(19, 101, 175, 1)",
              }}
            />
            <InputLabel>
              <p
                style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
                className="my-auto gilroy fw-bold ms-2 text-black"
              >
                Last Name
              </p>
            </InputLabel>
          </div>
          <Box>
            <TextField
              id="lastname"
              size="small"
              fullWidth={true}
              value={lastname}
              onChange={handleLastnameChange}
              placeholder="Enter Lastname"
              error={!!lastnameError}
              helperText={lastnameError}
            />
          </Box>
        </div>
      </div>
      <div className="username-generate-container ms-2 my-2  px-2">
        <div className="username d-flex align-items-center p-0 mb-2">
          <PersonRoundedIcon
            sx={{
              fontSize: isSmallScreen ? "22px" : "25px",
              color: "rgba(19, 101, 175, 1)",
            }}
          />
          <InputLabel>
            <p
              style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
              className="my-auto gilroy fw-bold ms-2 text-black"
            >
              Create a username
            </p>
          </InputLabel>
        </div>

        <Box>
          <TextField
            id="username"
            size="small"
            fullWidth={true}
            value={username}
            onChange={handleUsernameChange}
            placeholder="Enter username"
            error={!!usernameError}
            helperText={usernameError}
          />
        </Box>
      </div>
      <div className="password-generate-container ms-2 mt-3 px-2 pb-4">
        <div className="password__label d-flex align-items-center mt-4">
          <KeyOutlinedIcon
            sx={{
              fontSize: isSmallScreen ? "23px" : "25px",
              color: "rgba(19, 101, 175, 1)",
              transform: "rotate(135deg)",
            }}
          />
          <p
            style={{ fontSize: isSmallScreen ? "14px" : "18px" }}
            className="my-auto gilroy fw-bold ms-2"
          >
            Create a password
          </p>
        </div>
        <Box>
          <TextField
            size="small"
            fullWidth={true}
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            error={!!passwordError}
            helperText={passwordError || ""}
            className=" mt-2 rounded w-100"
          />
        </Box>
      </div>
    </>
  );
}

export default UserVerification;
