import React from "react";
import FilterReviews from "./FilterReviews";
import FilterSort from "./FilterSort";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ReviewsBox from "./ReviewBox/ReviewsBox";

function CustomerReview() {
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <FilterReviews />
        <div className="d-flex align-items-center gap-4">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#626f86", fontSize: "25px" }}
          />
          <FilterSort />
        </div>
      </div>
      <div className="reviews-box-container mt-4 d-flex flex-column justify-content-center align-items-center">
        <ReviewsBox />
      </div>
    </div>
  );
}

export default CustomerReview;
