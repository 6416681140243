import React from "react";
import "./RecentOrder.css";
import OrderCarousel from "./OrderCarousel/OrderCarousel";
import { Button, useMediaQuery } from "@mui/material";

function RecentOrder() {
  // Screen size for responsiveness
  const isMediumScreen = useMediaQuery(" (max-width: 768px) ");
  const isSmallScreen = useMediaQuery(" (max-width: 576px) ");
  return (
    <>
      <div className="recent-order-box mt-5">
        <div className="recent-orders-heading-seeAllButton px-sm-3 d-flex justify-content-between align-items-center">
          <h1 className="recent_orders__heading gilroy m-0">Recent Orders</h1>
          <Button
            sx={{
              backgroundColor: "rgba(208, 223, 255, 1)",
              color: "rgba(0,0,0,1)",
              fontSize: isSmallScreen
                ? "15px"
                : isMediumScreen
                ? "18px"
                : "20px",
              fontWeight: 600,
              padding: isSmallScreen
                ? "5px 10px"
                : isMediumScreen
                ? "8px 13px"
                : "10px 15px",
            }}
            className="text-capitalize gilroy"
          >
            See All
          </Button>
        </div>
        <div className="recent-orders-carousel mt-1">
          <OrderCarousel />
        </div>
      </div>
    </>
  );
}

export default RecentOrder;
