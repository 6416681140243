import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: "rgba(42, 23, 0, 1)",
  })
);

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "rgba(255, 221, 184, 1)",
  borderRadius: "6px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove border
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(42, 23, 0, 1)",
  },
  "& .MuiSelect-select": {
    padding: "4px 8px", // Adjust padding to reduce height
    fontSize: "0.875rem", // Adjust font size to reduce height
  },
}));

export default function CustomerReviewSort() {
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box sx={{ minWidth: 100 }} className="me-2">
      <FormControl fullWidth>
        <CustomSelect
          id="demo-simple-select-placeholder-label"
          multiple
          value={selectedOptions}
          displayEmpty
          size="small"
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          inputProps={{ "aria-label": "Select an age" }}
          renderValue={() => (
            <p
              style={{ color: "rgba(42, 23, 0, 1)", fontSize: "16px" }}
              className="fw-bold gilroy text-center m-0 p-0"
            >
              {/* <SwapVertical color="#2a1700" width="24px" height="24px" /> */}
              <FontAwesomeIcon
                icon={faArrowRightArrowLeft}
                transform={{ rotate: 90 }}
                style={{ color: "#2a1700", fontSize: "20px" }}
              />
              &nbsp; Sort
            </p>
          )}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(42, 23, 0, 1)" }}
              className="fw-bold gilroy"
            >
              Sort
            </span>
          </MenuItem>
          {["Ten", "Twenty", "Thirty"].map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedOptions.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    </Box>
  );
}
