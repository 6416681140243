import React, { useState, useEffect } from "react";
import "./OrderPage.css";
import { ArrowBackRounded } from "@mui/icons-material";
import { custProductDetailsAPI } from "../../../Config/apiConfig";
import axios from "axios";
import {
  Avatar,
  Link,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faMapMarkerAlt,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { InfoRounded } from "@mui/icons-material";
import { custOrderCancelAPI } from "../../../Config/apiConfig";
import { toast } from "react-toastify";

const OrderPage = () => {
  const navigate = useNavigate();

  const [orderId, setOrderId] = useState("");
  const [productDetailsData, setProductDetailsData] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog open state

  // Screen Size for Responsiveness
  const isMediumScreen = useMediaQuery("(max-width:768px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 420px) ");

  useEffect(() => {
    const productId = localStorage.getItem("productDetailsId");
    const productIdParsed = JSON.parse(productId);
    console.log(productIdParsed);
    if (productIdParsed) {
      const fetchCustProductDetailsAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.get(
              custProductDetailsAPI + productIdParsed,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );
            if (response && response.status === 200) {
              console.log("product details", response.data.data);
              setOrderId(response.data.data._id);
              setProductDetailsData(response.data.data);
            }
          } catch (error) {
            console.log(error);
          }
        }
      };
      fetchCustProductDetailsAPI();
    }
  }, []);

  const handleCancelOrder = (id) => {
    console.log("cancel order", id);
    if (id) {
      const cancelOrderId = id;
      const fetchCustOrderCancelAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.get(
              custOrderCancelAPI + cancelOrderId,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );
            if (response && response.status === 200) {
              console.log(response.data);
              toast.success(response.data.msg);
              setIsDialogOpen(false);
              if (response.data.msg) {
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            }
          } catch (error) {
            if (error.response && error.response.status === 400) {
              console.log(error.response.data.error);
              toast.warn(error.response.data.error);
            }
          }
        }
      };
      fetchCustOrderCancelAPI();
    }
  };

  // Open dialog
  const openDialog = () => setIsDialogOpen(true);

  // Close dialog
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <>
      <div className="my_order_page__container">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isSmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 mt-sm-0 fw-bold"
            onClick={() => {
              navigate("/orders");
            }}
          />
          <div className="">
            <h1
              style={{ fontSize: isSmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Orders
            </h1>
            <p
              style={{
                fontSize: isSmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/orders"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                My Orders{" "}
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/my-order-page"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Order Page{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>Order Id: {orderId}</span>
            </p>
          </div>
        </div>
        {productDetailsData && productDetailsData.productDetails && (
          <div>
            <section className="order_page__profile__status pt-3">
              <div className="order__page_content d-flex flex-column flex-sm-row gap-4 gap-lg-5 mt-5">
                <div className="  d-flex justify-content-center justify-content-sm-start">
                  <img
                    src={productDetailsData.productDetails.image}
                    alt={productDetailsData.productDetails.title}
                    className="product__image"
                  />
                </div>
                <div>
                  <div
                    className={`d-flex ${
                      isVerySmallScreen
                        ? "flex-column align-items-start gap-1"
                        : "flex-row gap-4"
                    } justify-content-start  `}
                  >
                    <h1 className="order_title gilroy-regular">
                      {productDetailsData.productDetails.title}
                    </h1>
                    <p className="order_status p-2 gilroy rounded-pill">
                      <span className="fw-bold"> Status:</span>{" "}
                      {productDetailsData.status}
                    </p>
                  </div>
                  <div style={{ gap: "5.5rem" }}>
                    <p className="order_id gilroy-regular">
                      <span className="fw-bold"> Order ID:</span>{" "}
                      {productDetailsData._id}
                    </p>
                    <p className="order_quantity gilroy-regular">
                      <span className="fw-bold"> Quantity:</span>{" "}
                      {productDetailsData.qty}
                    </p>
                    {/* <p className="order_placed gilroy-regular">
                    Order placed at: {productDetailsData.oDate.split(" ")[0]} |{" "}
                    {productDetailsData.oDate.split(" ").slice(1).join(" ")}
                  </p> */}
                  </div>
                </div>
              </div>
              <div
                className={`order_profile__content d-flex  ${
                  isVerySmallScreen
                    ? "flex-column align-items-start"
                    : "flex-row"
                }} gap-3 mt-4`}
              >
                <div className="d-flex gap-2 gap-md-3 align-items-center">
                  <Avatar
                    alt="Remy Sharp"
                    src="/static/images/avatar/1.jpg"
                    sx={{
                      width: isMediumScreen ? 20 : 24,
                      height: isMediumScreen ? 20 : 24,
                      fontSize: isMediumScreen ? 12 : 16,
                    }}
                  />
                  <p className="order_name gilroy-regular m-0 ">
                    Dainik Pest Controls
                  </p>
                </div>
                <Button
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    lineHeight: "18.83px",
                    color: "#4a7b9d",
                    backgroundColor: "#eeeef4",
                    padding: "10px 15px",
                    textTransform: "none",
                  }}
                  className="order_view__profile rounded-pill"
                >
                  View Profile
                </Button>
              </div>
            </section>
            {/* <section className="order_services__price___container mt-5">
              <div className="order_service_title__area mt-5">
                <h4 className="order_service__title gilroy m-0">
                  Pest Control Services
                </h4>
                <p className="order_service_area gilroy">
                  General Pest Control, 1 BHK (1000-2000 sq ft.)
                </p>
              </div>
            </section> */}
            <section className="order_service__price-content mt-4 pt-3">
              <div className="d-flex gap-4 align-items-center">
                <div>
                  <div className="order_service__price__content d-flex gap-2 align-items-center">
                    <img
                      src="icons/money.png"
                      style={{ color: "2f3033", width: "20px", height: "20px" }}
                    />
                    <p className="order_service__price_heading service_text gilroy m-0">
                      Price
                    </p>
                  </div>
                  <div>
                    <p className="order_service__total_price gilroy m-0">
                      &#8377; {productDetailsData.productDetails.finalPrice}
                    </p>
                  </div>
                </div>

                <div>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#FA9B00",
                      fontSize: isMediumScreen ? "16px" : "20px",
                      fontWeight: 500,
                      lineHeight: "23.54px",
                      color: "#ffffff",
                      padding: isMediumScreen ? "5px 15px" : "10px 20px",
                    }}
                    className="gilroy"
                  >
                    Pay Now
                  </Button>
                </div>
              </div>
              <div className="order_service__payment_info d-flex gap-3 align-items-center mt-3 p-3">
                <InfoRounded
                  sx={{ color: "#1365AF", width: "22px", height: "22px" }}
                />
                <p className="order_service_payment_info__text gilroy-regular m-0">
                  You can make the online payment or you can pay cash to the
                  service professional after the service.
                </p>
              </div>
            </section>
            <section className="mt-5 d-flex gap-5">
              <div className="order_services__date_and_time pe-3">
                <div className="order_service__date_time d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    style={{ color: "2f3033", fontSize: "17px" }}
                  />
                  <p className="order_service__date_time_heading service_heading__text gilroy m-0">
                    Date & Time:
                  </p>
                </div>
                <p className="order_service__date_time_text services_text gilroy-regular">
                  {productDetailsData.oDate.split(" ")[0]} |{" "}
                  {productDetailsData.oDate.split(" ").slice(1).join(" ")}
                </p>
              </div>
              {/*<div className="order_services__duration_time">
                <p className="order_service__duration_time_heading service_heading__text gilroy m-0">
                <div className="order_service__duration_time d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ color: "2f3033", fontSize: "17px" }}
                  />
                    Auction Duration:
                  </p>
                </div>
                <p className="order_service__duration_time_text services_text gilroy-regular">
                  48 hours
                </p> 
              </div>*/}
            </section>
            <section className="mt-3 ">
              <div className="order_services__delivery_address">
                <div className="order_service__delivery_address d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{ color: "2f3033", fontSize: "17px" }}
                  />
                  <p className="order_service__delivery_address_heading service_heading__text gilroy m-0">
                    Service Delivery Address:
                  </p>
                </div>
                <p className="order_service__delivery__text services_text gilroy-regular">
                  B 72, Arera colony, Bhopal, 462001
                </p>
              </div>
            </section>

            <section className="mt-4 pt-1">
              <div className="order_services__package_include">
                <div className="order_service__package_include d-flex gap-2 align-items-center">
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    style={{ color: "2f3033", fontSize: "18px" }}
                  />
                  <p className="order_service__package_include_heading service_heading__text gilroy m-0">
                    Product Information:
                  </p>
                </div>
                <section className="mt-2 ms-3 pt-1">
                  <div className="order_services__special_instruction">
                    <div className="order_service__special_instruction d-flex gap-2 align-items-center">
                      <p className="order_service__special_instruction_heading service_heading__text gilroy m-0">
                        Description:
                      </p>
                    </div>
                    <p className="order_service__special_instruction_list__text services_text text-break gilroy-regular m-0">
                      {productDetailsData.productDetails.description}
                    </p>
                  </div>
                </section>
                <section className="mt-2 ms-3 pt-1">
                  <div className="order_services__special_instruction">
                    <div className="order_service__special_instruction d-flex gap-2 align-items-center">
                      <p className="order_service__special_instruction_heading service_heading__text gilroy m-0">
                        Specifications:
                      </p>
                    </div>
                    <p className="order_service__special_instruction_list__text services_text text-break gilroy-regular m-0">
                      {productDetailsData.productDetails.specifications}
                    </p>
                  </div>
                </section>
              </div>
            </section>

            <section>
              <div
                className={`d-flex ${
                  isVerySmallScreen ? "flex-column" : "flex-row"
                } justify-content-center gap-4 mt-4 pt-3`}
              >
                <Button
                  variant="contained"
                  className="gilroy"
                  sx={{
                    backgroundColor: "#4A7B9D",
                    color: "#ffffff",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 600,
                    padding: "10px 35px",
                    "&:hover": {
                      backgroundColor: "#3b6e91",
                    },
                  }}
                >
                  Help
                </Button>
                <Button
                  variant="contained"
                  className="gilroy"
                  sx={{
                    backgroundColor: "#E9E7EB",
                    color: "#000000",
                    fontSize: "20px",
                    lineHeight: "20px",
                    fontWeight: 600,
                    padding: "10px 35px",
                    "&:hover": {
                      backgroundColor: "#c9c9ca",
                    },
                  }}
                  onClick={openDialog}
                >
                  Cancel
                </Button>
              </div>
            </section>
            <Dialog open={isDialogOpen} onClose={closeDialog}>
              <DialogTitle>Confirm Cancel Order</DialogTitle>
              <DialogContent>
                <p>Are you sure you want to cancel this order?</p>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={closeDialog}
                  sx={{ textTransform: "none" }}
                  className="gilroy"
                >
                  Close
                </Button>
                <Button
                  onClick={() => handleCancelOrder(productDetailsData._id)}
                  sx={{ color: "#fc3232", textTransform: "none" }}
                  className="gilroy"
                >
                  Confirm Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderPage;
