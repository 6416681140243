import React from "react";
import "./VirticalStyleLine.css";

const VirticalStyleLine = () => {
  return (
    <div className="vertical_line ms-2 me-1">
      <div className="top-diamond"></div>
      <div className="vertical-line border-end p-0 h-100"></div>
      <div className="bottom-diamond"></div>
    </div>
  );
};

export default VirticalStyleLine;
