import React, { useEffect } from "react";
import "./ServicesProvided.css";
import Headings from "../../Headings/Headings";
import MiniCategoriesButtons from "./MiniCategoriesButtons/MiniCategoriesButtons";
import ResultsOfCategory from "./ResultsOfCategory/ResultsOfCategory";
import VirticalStyleLine from "../../VirticalStyleLine/VirticalStyleLine";

function ServicesProvided() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="home-and-maintenance__container mx-0 ">
        <div className="heading-box">
          <Headings headings="Pest Control services" />
        </div>
        <div className="sub-categories__titles--results d-flex gap-4 mt-4 ">
          <aside className="services--sub--categories__options mt-2">
            <h3 className="sub--categories__options__heading gilroy">
              Sub-categories
            </h3>
            <div className="sub--categories__titles">
              <MiniCategoriesButtons />
            </div>
          </aside>
          <VirticalStyleLine />
          <section className="show--all--services mt-2 ">
            <h3 className="sub--categories__options__results gilroy">
              Showing All (<span className="results fw-bold">9953</span>)
              <span>&nbsp;Pest control services</span>
            </h3>
            <ResultsOfCategory />
          </section>
        </div>
      </div>
    </>
  );
}

export default ServicesProvided;
