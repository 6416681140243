import React from "react";
import "./ServiceOfferedAndCertificates.css";

const ServiceOfferedAndCertificates = () => {
  return (
    <>
      <section className="service_offered__container mt-5">
        <h2
          style={{ fontSize: "24px", fontWeight: 600, lineHeight: "28.63px" }}
          className="gilroy"
        >
          Service Offered
        </h2>
        <div className="service_offered__content d-flex gap-2">
          <p className="service_offered__labels p-1 px-2 m-0">
            Appliances & Electronics
          </p>
          <p className="service_offered__labels p-1 px-2 m-0">
            Packers & Movers
          </p>
          <p className="service_offered__labels p-1 px-2 m-0">
            Gardeners & Horticulture
          </p>
          <p className="service_offered__labels p-1 px-2 m-0">Plumbers</p>
        </div>
      </section>
      <section className="business_certificates my-5 pb-5">
        <h2
          style={{ fontSize: "24px", fontWeight: 600, lineHeight: "28.63px" }}
          className="gilroy"
        >
          Certificates
        </h2>
        <div className="d-flex gap-3">
          <img
            src="images/home/carousel/d.jpg"
            alt=""
            className="business_certificates"
          />
          <img
            src="images/home/carousel/d.jpg"
            alt=""
            className="business_certificates"
          />
          <img
            src="images/home/carousel/d.jpg"
            alt=""
            className="business_certificates"
          />
        </div>
      </section>
    </>
  );
};

export default ServiceOfferedAndCertificates;
