import { Button } from "@mui/material";
import React from "react";
import "./Overview.css";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

function Overview() {
  return (
    <div className="mt-5 px-4 pt-4">
      <div className="d-flex justify-content-between gilroy">
        <h4 className="fw-bold fs-4 gilroy text-black ">Overview</h4>
        <Button
          style={{
            backgroundColor: "rgb(238,238,244)",
            fontSize: "16px",
            padding: "7px 12px",
          }}
          className="rounded-pill text-capitalize "
        >
          <CreateRoundedIcon fontSize="small" />
          Edit
        </Button>
      </div>
      <div className="d-flex gap-5 my-1">
        <div className="employee-count">
          <p className="overview-heading gilroy p-0 my-2">Employee count</p>
          <p className="overview-info gilroy m-0 p-0 fw-bold">70</p>
        </div>
        <div className="experience">
          <p className="overview-heading gilroy p-0 my-2">Experience</p>
          <p className="overview-info gilroy m-0 p-0 fw-bold">Since 1990</p>
        </div>
        <div className="working-hours">
          <p className="overview-heading gilroy p-0 my-2">Working hours</p>
          <p className="overview-info gilroy m-0 p-0 fw-bold">
            9 a.m. - 12 p.m.
          </p>
        </div>
      </div>
      <div className="overview-para rounded p-4 mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum mollitia
        odit, praesentium rerum tempore ut consectetur quam, sunt possimus
        cupiditate perspiciatis assumenda libero aliquam fugit architecto nulla
        iure autem ab. Repellat culpa minima a necessitatibus voluptatibus
        consequatur, pariatur, aliquid exercitationem corrupti beatae itaque
        laborum libero deserunt iusto praesentium illum natus!
      </div>
    </div>
  );
}

export default Overview;
