import React, { useState, useEffect } from "react";
import "./Auctions.css";
import TwoPageButton from "./TwoPageButton/TwoPageButton";
import { Link, useMediaQuery } from "@mui/material";
import OnGoing from "./AuctionsContainer/OnGoingContainer/OnGoing.jsx";
import BiddedOn from "./AuctionsContainer/BiddedOnContainer/BiddedOn.jsx";
import { ArrowBackRounded } from "@mui/icons-material";

function Auctions() {
  const [currentView, setCurrentView] = useState("ongoing");

  // Screen Size responsive-----------
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery(" (max-width: 420px) ");

  return (
    <div className="auctions-box">
      <div className="d-flex gap-1 gap-sm-3 align-items-start ">
        <ArrowBackRounded
          sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
          className="back-arrow mt-2 fw-bold"
        />
        <div className="">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
            className="heading fw-bold gilroy"
          >
            Auctions
          </h1>
          <p
            style={{
              fontSize: isVerySmallScreen ? "14px" : "16px",
              fontWeight: 600,
              color: "#6A7D9F",
            }}
            className="m-0 gilroy"
          >
            <Link
              underline="none"
              href={"/"}
              sx={{ textDecoration: "none", color: "#6A7D9F" }}
            >
              Home{" "}
            </Link>{" "}
            / <span style={{ color: "#1365AF" }}>Auctions</span>
          </p>
        </div>
      </div>
      <div className="auctions-contents-and-details">
        <div className="auctions-two-pages">
          <TwoPageButton
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        </div>

        <div className="auction-container px-0 px-sm-3 ">
          {currentView === "ongoing" && <OnGoing />}
          {currentView === "biddedOn" && <BiddedOn />}
        </div>
      </div>
    </div>
  );
}

export default Auctions;
