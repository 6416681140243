import React, { useState } from "react";
import "./AddServices.css";
import { Button, Link, useMediaQuery } from "@mui/material";
import { ArrowBackRounded, CloudUploadOutlined } from "@mui/icons-material";
import ServiceInfo from "./ServicesInfo/ServiceInfo";
import { updateServicesAPI } from "../../../../Config/apiConfig";
import { loginCredentials } from "../../../ZReusableData/ResuableData";
import axios from "axios";
import { toast } from "react-toastify";

const AddServices = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [titleData, setTitleData] = useState("");
  const [serviceData, setServiceData] = useState("");
  const [detailsData, setDetailsData] = useState("");
  const [price, setPrice] = useState("");

  // Screen size for responsiveness
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");
  const isExtraSmallScreen = useMediaQuery("max-width:320px ");

  // Handling States
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleServiceInfoData = (data) => {
    setTitleData(data.serviceTitle);
    setServiceData(data.serviceData);
    setDetailsData(data.serviceDetails);
    setPrice(data.servicePrice);
  };
  const handleUploadClick = () => {
    if (file) {
      console.log("Uploading file:", file.name);
      setFileName(file.name);
    } else {
      alert("No File Selected");
    }
  };

  const handleCreateService = async () => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("title", titleData);
    formData.append("price", price);
    formData.append("details", detailsData);
    formData.append("serviceId", serviceData);

    try {
      if (loginCredentials) {
        const reponse = sessionStorage.getItem("loginCredentials");
        const parsedData = JSON.parse(reponse);

        console.log(parsedData.token);

        const response = await axios.put(updateServicesAPI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        console.log("response", response);
        toast.success(response.data.msg);
        // toast.success(response.data.msg);
      }
    } catch (error) {}

    // console.log("formdata", formData);
  };

  return (
    <>
      <div className="add_services__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy"
            >
              Add Services
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/dashboard"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Dashboard /{" "}
              </Link>{" "}
              <Link
                underline="none"
                href={"/admin-products-services"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services
              </Link>{" "}
              / Add Services
            </p>
          </div>
        </div>

        <section className="upload_products_img mt-5">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
            className="heading fw-bold gilroy"
          >
            Upload Images
          </h1>
          <div className="d-flex flex-column align-items-start file-upload-container mt-5">
            <div
              className="file-drop-zone d-flex flex-column flex-sm-row px-2 px-md-4 py-3 gap-2 gap-md-5 align-items-center"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              <div className="file-drop-text d-flex align-items-center gap-2 gap-sm-3">
                <CloudUploadOutlined
                  sx={{
                    color: "#999999",
                    fontSize: isVerySmallScreen ? "40px" : "50px",
                  }}
                />
                <div>
                  <p
                    className={`upload_para gilroy-regular ps-1 ${
                      isVerySmallScreen && "m-0"
                    }`}
                  >
                    Select a file or drag and drop here
                  </p>
                  <p
                    style={{ color: "#999999" }}
                    className="upload_para gilroy-regular m-0 p-0"
                  >
                    JPG, PNG or PDF, file size no more than 10MB
                  </p>
                </div>
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input"
              />
              <Button
                variant="contained"
                onClick={handleUploadClick}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "rgba(74, 123, 157, 1)",
                  padding: isVerySmallScreen ? "5px 10px" : "10px 20px",
                  textDecoration: "none",
                }}
                className="gilroy"
              >
                Upload
              </Button>
            </div>
            <span>{fileName}</span>
          </div>
        </section>
        <section className="product_info_sections mt-5">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
            className="heading fw-bold gilroy"
          >
            Other Information
          </h1>
          <div>
            <ServiceInfo onSave={handleServiceInfoData} />
          </div>
        </section>
        <Button
          variant="contained"
          onClick={handleCreateService}
          className="mt-4"
        >
          Add Service
        </Button>
      </div>
    </>
  );
};

export default AddServices;
