import React from "react";
import "./SocialLinks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

function SocialLinks() {
  return (
    <div className="my-5 px-4 w-100">
      <h1 className="fw-bold gilroy text-black fs-4">Social Links</h1>
      <div className="socila-links d-flex flex-column gap-2">
        <div className="website-link d-flex align-items-center justify-content-between">
          <label htmlFor="website" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faGlobe}
              className="border"
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;Website
          </label>
          <input
            type="text"
            id="website-input"
            className=" links form-control rounded"
            placeholder="Username"
          />
        </div>
        <div className="facebook-link d-flex align-items-center justify-content-between">
          <label htmlFor="facebook" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faFacebook}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;Facebook
          </label>
          <input
            type="text"
            id="facebook-input"
            className=" links form-control"
            placeholder="Username"
          />
        </div>
        <div className="instagram-link d-flex align-items-center justify-content-between">
          <label
            htmlFor="instagram"
            className="links-label gilroy align-center"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;Instagram
          </label>
          <input
            type="text"
            id="instagram-input"
            className=" links form-control"
            placeholder="Username"
          />
        </div>
        <div className="twitter-link d-flex justify-content-between align-items-center">
          <label htmlFor="twitter" className="links-label gilroy align-center">
            <FontAwesomeIcon
              icon={faXTwitter}
              style={{ color: "#6a7d9f", fontSize: "19px", marginTop: "1px" }}
            />
            &nbsp;Twitter
          </label>
          <input
            type="text"
            id="twitter-input"
            className=" links form-control"
            placeholder="Username"
          />
        </div>
      </div>
    </div>
  );
}

export default SocialLinks;
