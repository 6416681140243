import React, { useState } from "react";
import "./Orders.css";
import { Box, Link, useMediaQuery } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

import OrderQuotesButton from "./OrderQuotesButton/OrderQuotesButton";

import CustomerOrder from "./CustOrders/CustomerOrder";
import QuotesSentOrder from "./QuotesSent/QuotesSentOrder";
import { useNavigate } from "react-router-dom";
import { ArrowBackRounded } from "@mui/icons-material";

function Orders() {
  const navigate = useNavigate();

  const [view, setView] = useState("orders"); // state to manage the view

  // Screen size for responsiveness

  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <>
      <div className="orders-box gilroy">
        <div className="d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 mt-sm-0 fw-bold"
            onClick={() => {
              navigate("/dashboard");
            }}
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Orders
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/dashboard"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Dashboard{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>Orders</span>
            </p>
          </div>
        </div>
        <div className="order--quotes__button">
          <OrderQuotesButton
            onViewChange={handleViewChange}
            currentView={view}
          />
        </div>
        {view === "orders" && <CustomerOrder />}
        {view === "quotes" && <QuotesSentOrder />}
      </div>
    </>
  );
}

export default Orders;
