import { Button } from "@mui/material";
import React from "react";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function DetailsAndBidsBtn({ isActive, onButtonClick }) {
  return (
    <div className="details-button--bids-button d-flex justify-content-center mt-5">
      <Button
        style={{
          backgroundColor: isActive ? "rgba(214, 236, 255, 1)" : "",
          color: "rgba(0, 30, 47, 1)",
        }}
        className="details-button border border-end-0 border-secondary"
        onClick={() => onButtonClick(true)}
      >
        {isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
        Details
      </Button>
      <Button
        style={{
          backgroundColor: !isActive ? "rgba(214, 236, 255, 1)" : "",
          color: "rgba(0, 30, 47, 1)",
        }}
        className="bids-button border border-secondary"
        onClick={() => onButtonClick(false)}
      >
        {!isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
        Bids
      </Button>
    </div>
  );
}

export default DetailsAndBidsBtn;
