import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";

const Navigator = () => {
  return (
    <Container maxWidth="lg" sx={{ paddingTop: "64px", paddingBottom: "43px" }}>
      <div
        style={{
          gap: "4px",
          display: "flex",
        }}
      >
        <div style={{ lineHeight: "60px" }}>
          <Link to="/" className="nav-link">
            <KeyboardBackspaceIcon sx={{ fontSize: 40 }} />
          </Link>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              fontFamily: "Gilroy-SemiBold",
              fontSize: "40px",
              flexDirection: "row",
              display: "flex",
              gap: 10,
            }}
          >
            <div
              style={{
                color: "rgba(19, 101, 175, 1)",
              }}
            >
              Home
            </div>
            & Maintenance
          </div>
          <div
            style={{
              fontFamily: "Gilroy-Medium",
              fontSize: "16px",
              flexDirection: "row",
              display: "flex",
              gap: 10,
              color: "rgba(19, 101, 175, 1)",
            }}
          >
            <div
              style={{
                color: "rgba(106, 125, 159, 1)",
              }}
            >
              Home /
            </div>
            Home & Maintenance Services
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Navigator;
