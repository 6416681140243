import {
  Box,
  Container,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Stack,
  Grid,
} from "@mui/material";

import "./Explore.css";

const Explore = () => {
  const design = (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "#1365AF" }}>Explore </span> our specials
          </Box>
        </Box>

        <Stack>
          <Grid container>
            <Grid item xs={12} lg={6} className="pe-0 mb-4 mb-lg-0 pe-lg-3">
              <Card sx={{ position: "relative" }}>
                <CardMedia
                  sx={{
                    height: "327px",
                  }}
                  image="/images/home/explore/bg.png"
                />

                <div className="overlay">
                  <CardContent
                    sx={{
                      position: "absolute",
                      bottom: "0px",
                      padding: "24px",
                    }}
                  >
                    <Typography className="title">
                      Take your home décor to the next level
                    </Typography>

                    <Typography className="subtitle">
                      Lorem ipsum dolor sit amet consectetur. Varius donec massa
                      tincidunt a. Convallis turpis suspendisse purus urna arcu
                      mattis suspendisse
                    </Typography>

                    <Button
                      className="gilroy-regular"
                      style={{
                        marginTop: "13px",
                        backgroundColor: "#FA9B00",
                        borderRadius: "4px",
                        display: "inline-flex",
                        height: "36px",
                        padding: "4px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: "0",
                        color: "#FFFFFF",
                      }}
                    >
                      Check it out
                    </Button>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              lg={6}
              className="ps-0 ps-lg-0 pe-md-0 pe-lg-3 pe-xl-0 mt-4 mt-xl-0"
            >
              <Card sx={{ position: "relative" }}>
                <CardMedia
                  sx={{
                    height: "327px",
                  }}
                  image="/images/home/explore/bg.png"
                />
                <div className="overlay">
                  <CardContent
                    sx={{
                      position: "absolute",
                      bottom: "0px",
                      padding: "24px",
                    }}
                  >
                    <Typography className="title">
                      Take your home décor to the next level
                    </Typography>

                    <Typography className="subtitle">
                      Lorem ipsum dolor sit amet consectetur. Varius donec massa
                      tincidunt a. Convallis turpis suspendisse purus urna arcu
                      mattis suspendisse
                    </Typography>

                    <Button
                      className="gilroy-regular"
                      style={{
                        marginTop: "13px",
                        backgroundColor: "#FA9B00",
                        borderRadius: "4px",
                        display: "inline-flex",
                        height: "36px",
                        padding: "4px 16px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: "0",
                        color: "#FFFFFF",
                      }}
                    >
                      Check it out
                    </Button>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
  return design;
};

export default Explore;
