import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: "rgba(0, 70, 139, 1)",
    fontSize: "18px",
  })
);

const CustomSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "rgba(238, 237, 241, 1)",
  borderRadius: "4px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderRadius: "5px",
  },
  "& .MuiSvgIcon-root": {
    color: "rgba(0, 70, 139, 1)",
  },
  "& .MuiSelect-select": {
    padding: "4px 16px",
    fontSize: "0.875rem",
  },
}));

const EarningTimeDuration = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 140 }} className="me-2">
      <FormControl fullWidth>
        <CustomSelect
          id="demo-simple-select-placeholder-label"
          value={age}
          displayEmpty
          size="small"
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          inputProps={{ "aria-label": "Select an age" }}
          inputLabelProps={{ shrink: false }}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold gilroy"
            >
              Time Duration
            </span>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </CustomSelect>
      </FormControl>
    </Box>
  );
};

export default EarningTimeDuration;
