import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "@mui/material";

const Footer = () => {
  const isMobileScreen = useMediaQuery(" (max-width:576px) ");
  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");
  return (
    <>
      <div className="footer_container container-fluid">
        <div className="row">
          <div
            className=" logo__social_links_content col-lg-4 col-md-5 col-sm-12 "
            // style={{ paddingTop: "120px", paddingLeft: "90px" }}
          >
            <img
              className="rounded-circle"
              src="images/logo/header-logo.png"
              width="80px"
              height="80px"
              style={{
                marginBottom: "20px",
              }}
              alt="logo"
            />

            {isMobileScreen ? (
              <p
                className=""
                style={{
                  fontFamily: "Gilroy-Medium",
                  fontSize: "17px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  color: "rgba(255, 255, 255, 1)",
                  paddingBottom: "10px",
                }}
              >
                Funding freemium long tail hypotheses first mover advantage
                assets ownership
              </p>
            ) : (
              <p
                className=""
                style={{
                  display: isMobileScreen ? "none" : "block",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "17px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  color: "rgba(255, 255, 255, 1)",
                  paddingBottom: "10px",
                  width: isLargeScreen ? "15rem" : "auto",
                }}
              >
                Funding freemium long tail
                <br />
                hypotheses first mover advantage
                <br /> assets ownership
              </p>
            )}
            <div>
              <li
                style={{
                  paddingBottom: "20px",
                  listStyle: "none",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "17px",
                  fonteight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  color: " rgba(255, 255, 255, 1)",
                  cursor: "pointer",
                }}
              >
                <p className="d-flex flex-row align-items-end">
                  <span>
                    <FontAwesomeIcon icon={faEnvelope} className="" />
                  </span>
                  <span style={{ paddingBottom: "1px" }}>
                    &nbsp; agensi@mail.com
                  </span>
                </p>
              </li>
              <li
                style={{
                  paddingBottom: "10px",
                  listStyle: "none",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "17px",
                  fonteight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  color: " rgba(255, 255, 255, 1)",
                  cursor: "pointer",
                }}
              >
                <p className="d-flex flex-row ">
                  <span>
                    <FontAwesomeIcon icon={faPhone} className="" />
                  </span>
                  <span style={{ paddingBottom: "1px" }}>
                    &nbsp;+ 12 3456 7890
                  </span>
                </p>
                {/* <i className="fa-solid fa-phone"> </i> &nbsp;+ 12 3456 7890 */}
              </li>
            </div>
            <div
              className="row my-4 mx-auto"
              style={{ gap: isVerySmallScreen ? "25px" : "30px" }}
            >
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  padding: "3.2px",
                  backgroundColor: "rgba(250, 155, 0, 1)",
                }}
                className=" rounded-circle text-center"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  style={{ color: "ffffff" }}
                />
              </div>
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  padding: "3.2px",
                  backgroundColor: "rgba(250, 155, 0, 1)",
                }}
                className=" rounded-circle text-center"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  style={{ color: "ffffff" }}
                />
              </div>
              <div
                style={{
                  height: "32px",
                  width: "32px",
                  padding: "3.2px",
                  backgroundColor: "rgba(250, 155, 0, 1)",
                }}
                className=" rounded-circle text-center "
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  style={{ color: "ffffff" }}
                />
              </div>
            </div>
          </div>

          <div
            className=" list_of_links col-lg-8 col-md-7 col-sm-12 "
            style={{ paddingTop: isVerySmallScreen ? "50px" : "140px" }}
          >
            <div className="row">
              <div className="col-6 col-lg-3">
                <p
                  style={{
                    fontFamily: "Jost",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: " 0.30000001192092896px",
                    color: "rgba(146, 152, 159, 1)",
                  }}
                >
                  Services
                </p>
                <div
                  style={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: isVerySmallScreen ? "14px" : "17px",
                    fontWeight: "500",
                    lineHeight: "37px",
                    letterSpacing: "0em",
                    color: "rgba(255, 255, 255, 1)",
                    listStyle: "none",
                    paddingBottom: "15px",
                  }}
                >
                  <ul
                    style={{ textDecoration: "none", listStyleType: "none" }}
                    className="services d-flex flex-column ps-0 "
                  >
                    <li style={{ cursor: "pointer" }}>Web Hosting</li>
                    <li style={{ cursor: "pointer" }}>Domains</li>
                    <li style={{ cursor: "pointer" }}>Premium Hosting</li>
                    <li style={{ cursor: "pointer" }}>Private Server</li>
                    <li style={{ cursor: "pointer" }}>E-mail Hosting</li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <p
                  style={{
                    fontFamily: "Jost",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: " 0.30000001192092896px",
                    color: "rgba(146, 152, 159, 1)",
                  }}
                >
                  Support
                </p>
                <div
                  style={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: isVerySmallScreen ? "14px" : "17px",
                    fontWeight: "500",
                    lineHeight: "37px",
                    letterSpacing: "0em",
                    color: "rgba(255, 255, 255, 1)",
                    listStyle: "none",
                    paddingBottom: "15px",
                  }}
                >
                  <ul
                    style={{ textDecoration: "none", listStyleType: "none" }}
                    className="support d-flex flex-column text-wrap ps-0"
                  >
                    <li style={{ cursor: "pointer" }}>Pricing Plan</li>
                    <li style={{ cursor: "pointer" }}>Documentation</li>
                    <li style={{ cursor: "pointer" }}>Guide</li>
                    <li style={{ cursor: "pointer" }}>Tutorial</li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <p
                  style={{
                    fontFamily: "Jost",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: " 0.30000001192092896px",
                    color: "rgba(146, 152, 159, 1)",
                  }}
                >
                  Company
                </p>
                <div
                  style={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: isVerySmallScreen ? "14px" : "17px",
                    fontWeight: "500",
                    lineHeight: "37px",
                    letterSpacing: "0em",
                    color: "rgba(255, 255, 255, 1)",
                    listStyle: "none",
                    paddingBottom: "15px",
                  }}
                >
                  <ul
                    style={{ textDecoration: "none", listStyleType: "none" }}
                    className="company d-flex flex-column ps-0"
                  >
                    <li style={{ cursor: "pointer" }}>About</li>
                    <li style={{ cursor: "pointer" }}>Blog</li>
                    <li style={{ cursor: "pointer" }}>Join Us</li>
                    <li style={{ cursor: "pointer" }}>Press</li>
                    <li style={{ cursor: "pointer" }}>Partners</li>
                  </ul>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <p
                  style={{
                    fontFamily: "Jost",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: " 0.30000001192092896px",
                    color: "rgba(146, 152, 159, 1)",
                  }}
                >
                  Legal
                </p>
                <div
                  style={{
                    fontFamily: "Gilroy-Medium",
                    fontSize: isVerySmallScreen ? "14px" : "17px",
                    fontWeight: "500",
                    lineHeight: "37px",
                    letterSpacing: "0em",
                    color: "rgba(255, 255, 255, 1)",
                    listStyle: "none",
                    paddingBottom: "15px",
                  }}
                >
                  <ul
                    style={{ textDecoration: "none", listStyleType: "none" }}
                    className="legal d-flex flex-column ps-0"
                  >
                    <li style={{ cursor: "pointer" }}>Claim</li>
                    <li style={{ cursor: "pointer" }}>Privacy</li>
                    <li style={{ cursor: "pointer" }}>Terms</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr
          style={{
            border: "1px solid rgba(113, 119, 125, 1)",
            marginLeft: "60px",
            marginRight: "80px",
            marginTop: "0px",
          }}
        />
        <div
          className="text-center"
          style={{
            fontFamily: "Gilroy-Medium",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "15px",
            letterSpacing: "0em",
            color: "rgba(146, 152, 159, 1)",
            marginBottom: "-3px",
            paddingBottom: "15px",
          }}
        >
          Design with love © Amazing.web Design 2023. All right reserved
        </div>
      </div>
    </>
  );
};

export default Footer;
