import React from "react";
import "./Profile.css";
import ProfileCarousels from "./ProfileCarousels/ProfileCarousels";
import ShareButton from "./ShareButton";
import BusinessInformation from "./BusinessInformation/BusinessInformation";
import ContactInformation from "./ContactInformation/ContactInformation";
import Overview from "./Overview/Overview";
import ServiceOffered from "./ServiceOffered/ServiceOffered";
import SocialLinks from "./SocialLinks/SocialLinks";
import Certification from "./Certification/Certification";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

function Profile() {
  const navigate = useNavigate();

  const handleLogOutBtn = () => {
    localStorage.removeItem("loginCredentials");
    localStorage.clear();

    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <div className="mb-0">
        <ShareButton />
        <ProfileCarousels />
        <BusinessInformation />
        <ContactInformation />
        <Overview />
        <ServiceOffered />
        <SocialLinks />
        <Certification />

        <div className="my-5 mx-4">
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#ffcccc",
              color: "#000",
              border: "2px solid #ff5c5c",
              fontSize: "18px",
              boxShadow: "1px 4px 7px -4px",
              "&:hover": {
                backgroundColor: "#ffaaaa",
                border: "2px solid #ff5c5c",
              },
            }}
            className="gilroy"
            onClick={handleLogOutBtn}
          >
            Log Out
          </Button>
        </div>
      </div>
    </>
  );
}

export default Profile;
