import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  custBiddedOnServiceId: "",
};

const custBiddedOnServiceIdSlice = createSlice({
  name: "custBiddedOnId",
  initialState,
  reducers: {
    setCustBiddedOnServiceId: (state, action) => {
      state.custBiddedOnServiceId = action.payload;
    },
  },
});

export const { setCustBiddedOnServiceId } = custBiddedOnServiceIdSlice.actions;
export default custBiddedOnServiceIdSlice.reducer;
