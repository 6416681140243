import React, { useState, useEffect } from "react";
import "./ProductCart.css";
import { ArrowBackRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Link,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  TextField, // Added for inline validation feedback
} from "@mui/material";
import OrderSummary from "./OrderSummary/OrderSummary";
import FinancialDetails from "./FinancialDetails/FinancialDetails";
import ProductVerify from "./ProductVerify/ProductVerify";
import { custCartListAPI, custOrderPlacedAPI } from "../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const ProductCart = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [payablePrice, setPayablePrice] = useState("");
  const [typeOfUser, setTypeOfUser] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [address, setAddress] = useState("");
  const [sellerChoose, setSellerChoose] = useState("");
  const [cartData, setCartData] = useState([]);

  // Screen Size for responsiveness
  const isVerySmallScreen = useMediaQuery("(max-width:568px)");

  // Navigation handlers
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scroll(0, 0);
  };

  const steps =
    typeOfUser === "individual"
      ? ["Order Summary"]
      : ["Order Summary", "Financial Details", "Verify"];

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scroll(0, 0);
  };

  const handleOrderSummary = (data) => {
    setName(data.fullName);
    setEmail(data.email);
    setAddress(data.address);
    setSellerChoose(data.sellerChoose);
  };

  // Fetch cart data
  useEffect(() => {
    const custLoginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custLoginResponse);
    if (parsedData && parsedData.token) {
      const fetchCustCartListAPI = async () => {
        try {
          const response = await axios.get(custCartListAPI, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response.data && response.status === 200) {
            setCartData(response.data.data);
            setPayablePrice(response.data.total_amount);
            setTypeOfUser(response.data.typeOfUser);
          }
        } catch (error) {
          console.log("Error fetching cart data:", error);
        }
      };
      fetchCustCartListAPI();
    }
  }, []);

  // Validation function to check if all fields are filled
  const validateFormData = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (!address) newErrors.address = "Address is required";
    if (!sellerChoose) newErrors.sellerChoose = "Seller choice is required";

    setErrors(newErrors);

    // If the errors object has any keys, return false
    return Object.keys(newErrors).length === 0;
  };

  const handleBuyNow = async () => {
    if (!validateFormData()) {
      // If validation fails, prevent the request from being made
      return;
    }

    const formData = {
      name,
      email,
      address,
      sellerChoose,
    };

    const custLoginResponse = localStorage.getItem("custLoginCredentials");
    const parsedData = JSON.parse(custLoginResponse);

    if (parsedData && parsedData.token) {
      try {
        const response = await axios.post(custOrderPlacedAPI, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        if (response && response.status === 200) {
          console.log(response);
          toast.success(response.data.msg);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        if (error && error.response.status === 400) {
          toast.warn(error.response.data.msg);
        }
        console.log("Error placing order:", error);
      }
    }
  };

  return (
    <>
      <div className="product_cart__container">
        <div className="d-flex gap-2 gap-sm-3 align-items-start">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 fw-bold"
            onClick={handleBack}
          />
          <div className="mb-1">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold m-0 gilroy"
            >
              Cart
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home /{" "}
              </Link>
              <Link
                underline="none"
                href={"/cust-product-service"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}> Cart </span>
            </p>
          </div>
        </div>

        <section className="product_cart__steps ">
          <Box
            sx={{ width: "100%", padding: isVerySmallScreen ? "0" : "20px" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ width: isVerySmallScreen ? "100%" : "55%" }}
              >
                {steps.map((label, index) => (
                  <Step key={index}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              <React.Fragment>
                {activeStep === 0 && (
                  <OrderSummary
                    cartData={cartData}
                    price={payablePrice}
                    onSave={handleOrderSummary}
                  />
                )}
                {typeOfUser !== "individual" && activeStep === 1 && (
                  <FinancialDetails price={payablePrice} />
                )}
                {typeOfUser !== "individual" && activeStep === 2 && (
                  <ProductVerify />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    pt: 5,
                  }}
                >
                  {typeOfUser === "individual" ? (
                    <Button
                      variant="contained"
                      onClick={handleBuyNow}
                      sx={{
                        textTransform: "none",
                        fontSize: isVerySmallScreen ? "16px" : "20px",
                        fontWeight: "500",
                        color: "#ffffff",
                      }}
                      className="gilroy"
                      disabled={!name || !email || !address || !sellerChoose} // Disable button if fields are empty
                    >
                      Buy Now
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{
                        textTransform: "none",
                        fontSize: isVerySmallScreen ? "16px" : "20px",
                        fontWeight: "500",
                        color: "#ffffff",
                      }}
                      className="gilroy"
                    >
                      {activeStep === steps.length - 1 ? "Verify" : "Continue"}
                    </Button>
                  )}
                </Box>

                {/* Display validation errors below the form */}
                {errors.name && <p className="error">{errors.name}</p>}
                {errors.email && <p className="error">{errors.email}</p>}
                {errors.address && <p className="error">{errors.address}</p>}
                {errors.sellerChoose && (
                  <p className="error">{errors.sellerChoose}</p>
                )}
              </React.Fragment>
            </Box>
          </Box>
        </section>
      </div>
    </>
  );
};

export default ProductCart;
