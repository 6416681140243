import React, { useEffect, useState } from "react";
import "./AuctionDetails.css";
import DetailsAndBidsBtn from "./DetailsAndBidsBtn";
import Details from "./Details/Details";
import Bids from "./Bids/Bids";
import { useSelector } from "react-redux";
import {
  custAuctionDetailsAPI,
  custAuctionBidAPI,
} from "../../../Config/apiConfig";
import axios from "axios";
import { Link, useMediaQuery } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";

function AuctionDetails() {
  const [isActive, setIsActive] = useState(true);
  const [auctionDetailsBids, setAuctionDetailsBids] =
    useState("Auctions Details");
  const [bidsData, setBidsData] = useState([]);

  // Screen size for resposiveness
  const isVerySmallScreen = useMediaQuery("(max-width: 576px)");

  const handleButtonClick = (isDetailsActive) => {
    setIsActive(isDetailsActive);
    setAuctionDetailsBids(
      isDetailsActive ? "Auctions Details" : "Auction Bids"
    );
  };

  // getting data form redux
  const custAuctionCateId = useSelector(
    (state) => state.custAuctionId.custAuctionCateId
  );

  useEffect(() => {
    const custBiddedOnServiceId = localStorage.getItem("custBiddedOnServiceId");
    const custparsedBiddedOnId = JSON.parse(custBiddedOnServiceId);

    if (custparsedBiddedOnId) {
      console.log("custparsedBiddedId", custparsedBiddedOnId);
      const fetchCustAuctionBidAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          localStorage.removeItem("custAuctionBids");
          try {
            const response = await axios.get(
              custAuctionBidAPI + custparsedBiddedOnId,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );

            if (response && response.status === 200) {
              console.log("Auction bids fetched", response.data);
              const bidsDetails = response.data;
              if (bidsDetails) {
                console.log("bideData dAta", bidsDetails);
                setBidsData(bidsDetails);
                setIsActive(false);
                setAuctionDetailsBids("Auction Bids");

                console.log("custAuctionBids stored successfully", bidsDetails);
              }
            } else {
              console.error(
                "Failed to fetch auction bids, status:",
                response.status
              );
            }
          } catch (error) {
            console.error("Failed to fetch auction bids", error);
          }
        }
      };
      fetchCustAuctionBidAPI();
    }
  }, []);

  useEffect(() => {
    // Fetch auction details whenever custAuctionCateId changes
    const fetchCustAuctionDetailsAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData && custAuctionCateId) {
        try {
          const response = await axios.get(
            custAuctionDetailsAPI + custAuctionCateId,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${parsedData.token}`,
              },
            }
          );
          if (response && response.status === 200) {
            console.log("auction details", response.data.data);
            localStorage.setItem(
              "custAuctionDetails",
              JSON.stringify(response.data.data)
            );
          }
        } catch (error) {
          console.error("Failed to fetch auction details", error);
        }
      }
    };

    if (custAuctionCateId) {
      fetchCustAuctionDetailsAPI();
    }
  }, [custAuctionCateId]);

  return (
    <>
      <div className="auction-details-box gilroy">
        <div className="d-flex gap-1 gap-sm-3 align-items-start">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 fw-bold"
          />
          <div>
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2.5em" }}
              className="heading fw-bold gilroy"
            >
              Auctions
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home
              </Link>{" "}
              /{" "}
              <Link
                underline="none"
                href={"/auctions"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Auctions
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>{auctionDetailsBids}</span>
            </p>
          </div>
        </div>
        <div>
          <DetailsAndBidsBtn
            isActive={isActive}
            onButtonClick={handleButtonClick}
          />
        </div>
        <div>{isActive ? <Details /> : <Bids bidsData={bidsData} />}</div>
      </div>
    </>
  );
}

export default AuctionDetails;
