import React, { useState, useEffect } from "react";
import "./ServiceLists.css";
import { Button, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import SubCategories from "./SubCategories/SubCategories.jsx";
import { ArrowBackRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import Automobile from "./Automobile/Automobile.jsx";
import Business from "./Business/Business.jsx";
import HealthWellness from "./HealthWellness/HealthWellness.jsx";
import PropertyRent from "./PropertyRent/PropertyRent.jsx";

function ServiceLists() {
  const isVerySmallScreen = useMediaQuery("(max-width: 576px) ");

  const [serviceData, setServiceData] = useState([]);

  // Get Service Id from Redux

  const seeMoreServiceItem = useSelector(
    (state) => state.seeMoreService.seeMoreServiceItem
  );

  useEffect(() => {
    const handleServiceCateNameId = () => {
      console.log("seeMoreServices", seeMoreServiceItem.data);
      setServiceData(seeMoreServiceItem.data);
    };

    handleServiceCateNameId();
  }, [seeMoreServiceItem]);

  return (
    <>
      <div className="home-and-maintenance__container mx-0">
        <div className="heading-box  d-flex gap-1 gap-sm-3 align-items-start ">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-2 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy m-0"
            >
              Service Lists
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                to={"/"}
                style={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              / Service Lists
            </p>
          </div>
        </div>
        {/* <ServiceCarousel /> */}
        <div className="sub--categories__container">
          <div className="search--box">
            <div className="input-group mb-3 border rounded">
              <span
                style={{ backgroundColor: "rgb(255,255,255)" }}
                className="input-group-text"
              >
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ color: "#6a7d9f", fontSize: "25px" }}
                />
              </span>
              <input
                type="text"
                className="search--input form-control border border-start-0"
                placeholder="Search Service"
              />
              <Button
                sx={{
                  backgroundColor: "rgba(19, 101, 175, 1)",
                  color: "rgba(255,255,255,1)",
                  fontSize: "20px",
                  lineHeight: "20px",
                }}
                className="btn btn-outline-secondary gilroy "
                type="button"
                id="button-addon2"
              >
                Search
              </Button>
            </div>
          </div>
          <div className="mt-5">
            <Automobile serviceData={serviceData} />
            <Business serviceData={serviceData} />
            <HealthWellness serviceData={serviceData} />
            <PropertyRent serviceData={serviceData} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceLists;
