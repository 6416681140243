import React from "react";
import "./AnalyticsSection.css";
import OrderReceived from "./OrderReceived/OrderReceived";
import Earnings from "./Earnings/Earnings";

const AnalyticsSection = () => {
  return (
    <div>
      <div className="earnings-heading-seeAllButton px-sm-3 d-flex justify-content-between">
        <h1 className="anlytics__heading gilroy mt-1">Analytics</h1>
      </div>
      <OrderReceived />
      <Earnings />
    </div>
  );
};

export default AnalyticsSection;
