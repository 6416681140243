// Creating Slice (Reducers) for PopularServiceCategoryID

import { createSlice } from "@reduxjs/toolkit";

// giving initial state to popularServiceCateId null
const initialState = {
  popularServiceCateId: "",
};

// State Managing
// action name popularCateId

const popularCateIdSlice = createSlice({
  name: "popularCateId",

  initialState,
  reducers: {
    setPopularServiceCateId: (state, action) => {
      state.popularServiceCateId = action.payload;
    },
  },
});

// exporting Slice and reducers
export const { setPopularServiceCateId } = popularCateIdSlice.actions;
export default popularCateIdSlice.reducer;
