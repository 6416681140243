import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import React, { useState } from "react";

const AddNewOffice = () => {
  const [officeType, setOfficeType] = useState("Registered");
  const [registeredAs, setRegisteredAs] = useState("");
  const [isGstRegistered, setIsGstRegistered] = useState("");
  const [gstin, setGstin] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [gstDeclaration, setGstDeclaration] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [townCityDistrict, setTownCityDistrict] = useState("");
  const [flatDoorBlockNo, setFlatDoorBlockNo] = useState("");
  const [premisesName, setPremisesName] = useState("");
  const [roadStreetPostOffice, setRoadStreetPostOffice] = useState("");
  const [areaLocality, setAreaLocality] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [validationError, setValidationError] = useState("");

  // Screen Size for responsivness ---------
  const isSmallScreen = useMediaQuery("(max-width: 575px) ");
  const isBetween768And883 = useMediaQuery(
    "(min-width: 768px) and (max-width: 883px)"
  );
  const isLargeScreen = useMediaQuery("(max-width: 992px) ");

  // Styling part ----------
  const labelStyle = {
    fontSize: isLargeScreen ? "14px" : "16px",
  };
  const labelClassStyle = "fw-bold my-auto ms-2 mt-2 gilroy text-dark";
  const sectionStyle = `d-flex flex-column flex-sm-row ${
    isBetween768And883 ? "flex-md-column" : "flex-md-row"
  } gap-3 gap-lg-5 justify-content-between align-items-center`;

  // Styling ends ------

  // handling textfields ------
  const handleOfficeType = (event) => {
    setOfficeType(event.target.value);
    console.log(event.target.value);
  };

  const handleRegisteredAs = (event) => {
    setRegisteredAs(event.target.value);
  };

  const handleGstRegistrationChange = (event) => {
    const value = event.target.value;
    setIsGstRegistered(value);
    if (value) {
      setValidationError("");
    }
  };

  const handleGstDeclarationChange = (event) => {
    setGstDeclaration(event.target.value);
  };

  const handleSave = () => {
    localStorage.removeItem("addNewOfficeData");
    if (!isGstRegistered) {
      setValidationError("Please select if you are registered with GST.");
      return;
    }

    const formData = {
      officeName: officeName,
      typeOfOffice: officeType,
      registerWithGst: isGstRegistered,
      gstIn: gstin,
      registeredAs: registeredAs,
      declaring: gstDeclaration,
      pincode: pincode,
      state: state,
      townCityDistrict: townCityDistrict,
      flatBlockNo: flatDoorBlockNo,
      buildingVillage: premisesName,
      street: roadStreetPostOffice,
      area: areaLocality,
      contactNo: contactNumber,
      email: emailAddress,
    };

    // Save formData to localStorage under the key 'addNewOfficeData'
    const existingKeywords = localStorage.getItem("addNewOfficeData");
    let keywords =
      existingKeywords !== undefined ? JSON.parse(existingKeywords) : [];

    if (!Array.isArray(keywords)) {
      keywords = [];
    }

    if (!keywords.includes(formData)) {
      keywords.push(formData);
    }

    localStorage.setItem("addNewOfficeData", JSON.stringify(keywords));
    console.log("Office Loc Data saved to localStorage:", keywords);
    // console.log("office location", keywords);
  };

  return (
    <>
      <div className="add_new_office__container ">
        <div className={`office_name--type_of_office ${sectionStyle}`}>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Office Name*
            </p>
            <TextField
              size="small"
              // style={{ backgroundColor: "#EDF2FC" }}
              fullWidth
              placeholder="Enter Office Name"
              value={officeName}
              onChange={(e) => setOfficeName(e.target.value)}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Type of Office*
            </p>
            <Select
              value={officeType}
              fullWidth
              size="small"
              IconComponent={KeyboardArrowDown}
              onChange={handleOfficeType}
              // style={{ backgroundColor: "#EDF2FC" }}
            >
              <MenuItem value={"registered"}>Registered</MenuItem>
              <MenuItem value={"twenty"}>Twenty</MenuItem>
              <MenuItem value={"thirty"}>Thirty</MenuItem>
            </Select>
          </div>
        </div>
        <div
          className={`sst__verify d-flex flex-column flex-sm-row ${
            isBetween768And883 ? "flex-md-column" : "flex-md-row"
          } gap-2 gap-lg-5 justify-content-start justify-content-sm-between align-items-start mt-3 mt-sm-4 `}
        >
          <p style={labelStyle} className={labelClassStyle}>
            Are you registered with GST?
          </p>
          <FormControl className="ms-2">
            <RadioGroup
              row
              name="gst--verify"
              value={isGstRegistered}
              onChange={handleGstRegistrationChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio size="small" />}
                label={
                  <p className="m-0" style={labelStyle}>
                    Yes
                  </p>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="no"
                control={<Radio size="small" />}
                label={
                  <p className="m-0" style={labelStyle}>
                    No
                  </p>
                }
                labelPlacement="end"
              />
            </RadioGroup>
            {validationError && (
              <p style={{ color: "red", fontSize: "12px" }}>
                {validationError}
              </p>
            )}
          </FormControl>
        </div>

        {isGstRegistered === "yes" && (
          <div className={`gstin__registered_as ${sectionStyle} mt-3 mt-sm-4`}>
            <div className="w-100">
              <p style={labelStyle} className={labelClassStyle}>
                GSTIN*
              </p>
              <TextField
                // style={{ backgroundColor: "#EDF2FC" }}
                size="small"
                fullWidth
                placeholder="Enter GSTIN"
                value={gstin}
                onChange={(e) => setGstin(e.target.value)}
              />
            </div>
            <div className="w-100">
              <p style={labelStyle} className={labelClassStyle}>
                Registered As
              </p>
              <Select
                value={registeredAs}
                fullWidth
                size="small"
                IconComponent={KeyboardArrowDown}
                onChange={handleRegisteredAs}
                // style={{ backgroundColor: "#EDF2FC" }}
              >
                <MenuItem value={"Registered under Normal Tax payer"}>
                  Registered under Normal Tax payer
                </MenuItem>
                <MenuItem value={"Registered under composition Scheme"}>
                  Registered under composition Scheme
                </MenuItem>
              </Select>
            </div>
          </div>
        )}

        {isGstRegistered === "no" && (
          <div className="not-registered_gst mt-3 mt-sm-4">
            <div className="w-100">
              <p style={labelStyle} className={labelClassStyle}>
                Declaring As*
              </p>
              <FormControl className="ms-2">
                <RadioGroup
                  row
                  name="underGst"
                  value={gstDeclaration}
                  onChange={handleGstDeclarationChange}
                >
                  <FormControlLabel
                    value="underGSTLimit"
                    control={<Radio size="small" />}
                    label={
                      <p className="m-0" style={labelStyle}>
                        Business falling under turnover threshold limit as per
                        GST law
                      </p>
                    }
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio size="small" />}
                    label={
                      <p className="m-0" style={labelStyle}>
                        Other
                      </p>
                    }
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="w-100 d-flex align-items-start ">
              <FormControlLabel
                control={<Checkbox className="py-0 ps-3" size="small" />}
              />
              <p className="m-0" style={{ fontSize: "12px" }}>
                I/We <strong>Vijay Shankar</strong> of{" "}
                <strong>Vijay Solution</strong> hereby declare that I/we is/are
                not required to obtained registration under GST act. In case any
                of the above information is to be incorrect at later a date, the
                registration with Eserve Market me be liable to be cancelled and
                any dues / deposits may be forfeited by the Buyer / Eserve
                Market.
              </p>
            </div>
          </div>
        )}

        <div className={`pincode_state ${sectionStyle}  mt-4`}>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Pincode*
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter 6 digit pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              State*
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter state"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
        </div>
        <div
          className={`town-city-district__flat-door-block_no ${sectionStyle} mt-5`}
        >
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Town/City/District*
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Town/City/District"
              value={townCityDistrict}
              onChange={(e) => setTownCityDistrict(e.target.value)}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Flat/Door/Block No.*
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Flat/Door/Block No."
              value={flatDoorBlockNo}
              onChange={(e) => setFlatDoorBlockNo(e.target.value)}
            />
          </div>
        </div>
        <div
          className={`premises_name-building-village__road-street-post_office ${sectionStyle} mt-5`}
        >
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Name of Premises/Building/Village
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Name of Premises/Building/Village"
              value={premisesName}
              onChange={(e) => setPremisesName(e.target.value)}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Road/Street/Post Office
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Road/Street/Post Office"
              value={roadStreetPostOffice}
              onChange={(e) => setRoadStreetPostOffice(e.target.value)}
            />
          </div>
        </div>
        <div
          className={`area/locality__contact_number ${sectionStyle} align-items-md-start mt-5`}
        >
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Area/Locality*
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Area/Locality"
              value={areaLocality}
              onChange={(e) => setAreaLocality(e.target.value)}
            />
          </div>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Contact Number *
            </p>
            <TextField
              // style={{ backgroundColor: "#EDF2FC" }}
              size="small"
              fullWidth
              placeholder="Enter Contact Number"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
            />
            <p
              className="gilroy m-0 py-1"
              style={{
                fontSize: "11px",
                color: "#575757",
              }}
            >
              The Number will be pulblished on Eserve Artifacts (such as
              Contract and Invoice) for helping the Buyers communicate with the
              Sellers post contract
            </p>
          </div>
        </div>
        <div className={`office-email-address ${sectionStyle} mt-4`}>
          <div className="w-100">
            <p style={labelStyle} className={labelClassStyle}>
              Email Address*
            </p>
            <TextField
              style={{
                width: isSmallScreen
                  ? "100%"
                  : isBetween768And883
                  ? "100%"
                  : "46.4%",
              }}
              size="small"
              placeholder="Enter Email Address"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#fa9b00", color: "#ffffff" }}
            onClick={handleSave}
            className="gilroy"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddNewOffice;
