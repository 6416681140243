import React from "react";
import "./QuickAction.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button } from "@mui/material";
import ServiceAvailability from "./ServiceAvailability";
import OnlineStatus from "./OnlineStatus";
import ServiceCatelogue from "./ServicesCatalogue";

function QuickAction() {
  return (
    <>
      <div className="quick-action-box">
        <div className="d-flex justify-content-between align-items-center px-3">
          <h1 className="quick_action__heading gilroy m-0">Quick Actions</h1>
          <Button>
            <SettingsIcon
              style={{ color: "rgba(106, 125, 159, 1)" }}
              fontSize="large"
            />
          </Button>
        </div>
        <div className="actions mt-4 mx-3 d-flex gap-5">
          <div>
            <ServiceAvailability />
          </div>
          <div>
            <OnlineStatus />
          </div>
          <div>
            <ServiceCatelogue />
          </div>
        </div>
      </div>
    </>
  );
}

export default QuickAction;
