import React, { useState } from "react";
import "./ResultsOfCategoryList.css";
import { Button } from "@mui/material";
import ReactPaginate from "react-paginate";
import { categoriesResultContent } from "./resultsOfCategoryList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faStar,
  faHeart as faHeartSolid,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faHeart } from "@fortawesome/free-regular-svg-icons";

const itemsPerPage = 5;

function ResultsOfCategoryList() {
  const [isFavorite, setIsFavorite] = useState(false);
  const toggleFavBtn = () => {
    setIsFavorite(!isFavorite);
  };

  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = categoriesResultContent.slice(
    offset,
    offset + itemsPerPage
  );

  return (
    <>
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div
              key={item.id}
              className="sub--categories__container__list w-100 border mb-5 p-4"
            >
              <div className=" sub--categories__lists-img result-content__box overflow-hidden">
                <div className="favourite__btn" onClick={toggleFavBtn}>
                  {isFavorite ? (
                    <FontAwesomeIcon
                      icon={faHeart}
                      style={{
                        color: "#ffffff",
                        fontSize: "26px",
                      }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeartSolid}
                      style={{
                        color: "red",
                        fontSize: "26px",
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3 ">
                <div>
                  <div className="d-flex align-items-center gap-1 ">
                    <h2 className="categories--title gilroy text-center m-0 p-0">
                      {item.title}
                    </h2>
                    <span className="checker gilroy">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{
                          color: "#172b4d",
                          fontSize: "14px",
                        }}
                      />
                      &nbsp;{item.checker}
                    </span>
                  </div>
                  <div className="company__address d-flex align-items-center gap-1 gilroy">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      style={{
                        color: "#6a7d9f",
                        fontSize: "18px",
                      }}
                    />
                    <p className="company__address m-0 p-0 gilroy">
                      {item.location}{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="categories__ratings text-center d-flex align-items-center m-0 p-0">
                    <span>
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{
                          color: "#fa9b00",
                          fontSize: "18px",
                        }}
                      />
                    </span>
                    &nbsp;
                    <span className="ratings__outof fw-bold gilroy">
                      {item.ratingOutof}/5
                    </span>
                  </p>
                  <p className="categories__reviews m-0 p-0">
                    {item.reviews} reviews
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="d-flex gap-2">
                  <p className="service-giving m-0 ">Bed Bug termination</p>
                  <p className="service-giving m-0">Pest control</p>
                </div>
                <div className="d-flex align-items-start mt-2">
                  <p className="service-para p-0 m-0">
                    Lorem ipsum, dolor sit amet consectetur adipisicing
                    elit.....................
                  </p>
                  <button className="service-pare-expand">more</button>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-4 ">
                <div className="d-flex gap-2 align-items-center">
                  <p className="categories__response text-center gilroy m-0">
                    Response: <span>{item.response}</span>
                  </p>
                  <p className="categories__open--time text-center gilroy m-0">
                    Open: <span>{item.openTime}</span>
                  </p>
                </div>
                <div className="d-flex gap-2">
                  <Button
                    sx={{
                      border: "1px solid rgba(194, 199, 207, 1)",
                      width: "36px",
                      height: "36px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      padding: "0",
                      minWidth: "36px",
                    }}
                    className="share__btn "
                  >
                    <ShareRoundedIcon
                      fontSize="small"
                      style={{ color: "#6a7d9f" }}
                    />
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: "rgba(19, 101, 175, 1)",
                      color: "rgba(255,255,255,1)",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      padding: "0px 15px",
                    }}
                    className="gilroy"
                  >
                    Request Quote
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(categoriesResultContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default ResultsOfCategoryList;
