import React from "react";
import "./BuyersAccounts.css";
import { ArrowBackRounded, CreateRounded } from "@mui/icons-material";
import { Avatar, Button, Grid } from "@mui/material";
import AccountInfo from "./AccountInfo/AccountInfo";

const BuyersAccounts = () => {
  return (
    <>
      <div className="customer_account__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Account</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / My Account
            </p>
          </div>
        </div>

        <section className="cust_details_account__section mt-5">
          <Grid container>
            <Grid sx={12} md={2} className=" ps-1">
              <Avatar
                alt="profile_img"
                src="images/myaccount/profile_img.jpg"
                style={{ width: "10rem", height: "10rem" }}
              ></Avatar>
            </Grid>
            <Grid sx={12} md={10} className=" ps-4 ">
              <div className="cust_profile_details d-flex justify-content-between py-1 h-100">
                <div className="d-flex flex-column h-100 justify-content-between">
                  <div>
                    <p className="cust_name__heading profile_heading_text gilroy m-0">
                      Your Name:
                    </p>
                    <p className="cust_name gilroy m-0">Siddhi Chaturvedi</p>
                  </div>
                  <div>
                    <p className="cust_number_heading profile_heading_text gilroy m-0">
                      Phone number
                    </p>
                    <p
                      style={{ fontSize: "20px" }}
                      className="cust_number gilroy m-0"
                    >
                      +91 85234 62462
                    </p>
                  </div>
                </div>
                <div>
                  <Button
                    sx={{
                      fontSize: "16px",
                      backgroundColor: "#EEEEF4",
                      textTransform: "none",
                      padding: "10px 20px",
                    }}
                    className="gilroy-regular"
                  >
                    <CreateRounded sx={{ width: "30px", height: "30px" }} />{" "}
                    Edit my profile
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
        <section className="pt-5">
          <AccountInfo />
        </section>
      </div>
    </>
  );
};

export default BuyersAccounts;
