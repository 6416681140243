import React, { useEffect, useState } from "react";
import "./ServiceInfo.css";
import { TextField, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { allServicesAPI } from "../../../../../Config/apiConfig";
import axios from "axios";

const ServiceInfo = ({ onSave }) => {
  const navigate = useNavigate();
  const [allService, setAllService] = useState("");
  const [serviceData, setServiceData] = useState("");
  const [serviceTitle, setServiceTitle] = useState("");
  const [serviceDetails, setServiceDetails] = useState("");
  const [servicePrice, setServicePrice] = useState("");

  useEffect(() => {
    const formData = {
      serviceTitle,
      serviceData,
      serviceDetails,
      servicePrice,
    };
    onSave(formData);
  }, [serviceTitle, serviceData, serviceDetails, servicePrice]);

  const handleChange = (event) => {
    setServiceData(event.target.value);
  };
  const handleServiceTitle = (event) => {
    setServiceTitle(event.target.value);
  };
  const handleServiceDetails = (event) => {
    setServiceDetails(event.target.value);
  };
  const handleServicePrice = (event) => {
    setServicePrice(event.target.value);
  };

  // Fetch services API
  useEffect(() => {
    const fetchAllServicesAPI = async () => {
      const loginResponse = sessionStorage.getItem("loginCredentials");
      const parsedData = JSON.parse(loginResponse);

      try {
        if (parsedData.token) {
          const response = await axios.get(allServicesAPI + "/approved-list", {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });

          console.log("services_dropdown", response);

          const allServicesData = response.data.data;
          setAllService(allServicesData);
        }
      } catch (error) {
        console.log("All Services Error", error);
      }
    };

    fetchAllServicesAPI();
  }, []);

  return (
    <div className="service_info_section">
      <div className="product_name__mrp d-flex flex-column flex-sm-row justify-content-between gap-4 mt-4">
        <div className="w-100">
          <h3 className="label-text gilroy">Title</h3>
          <TextField
            fullWidth
            size="small"
            value={serviceTitle}
            onChange={handleServiceTitle}
            sx={{ backgroundColor: "#ffffff" }}
          />
        </div>
        <div className="w-100">
          <h3 className="label-text gilroy">Services</h3>
          <Select
            value={serviceData}
            fullWidth
            size="small"
            onChange={handleChange}
          >
            <MenuItem value="">Services</MenuItem>
            {allService &&
              allService.map((item, index) =>
                item.status === "Approved" ? (
                  <MenuItem key={index} value={item._id}>
                    {item.categoryName}
                  </MenuItem>
                ) : null
              )}
          </Select>
        </div>
      </div>
      <div className="products_details d-flex flex-column flex-sm-row justify-content-between gap-4 mt-4">
        <div className="w-100">
          <h3 className="label-text gilroy">Details</h3>
          <TextField
            multiline
            rows={5}
            fullWidth
            size="small"
            value={serviceDetails}
            onChange={handleServiceDetails}
            sx={{ backgroundColor: "#ffffff" }}
          />
        </div>
        <div className="w-100">
          <h3 className="label-text gilroy">Price</h3>
          <TextField
            fullWidth
            size="small"
            value={servicePrice}
            onChange={handleServicePrice}
            sx={{ backgroundColor: "#ffffff" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
