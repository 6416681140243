import React from "react";
import "./AuctionDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWallet,
  faClock,
  faReceipt,
  faWandMagicSparkles,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const AuctionDetails = () => {
  return (
    <>
      <div className="auction_details__container mt-5">
        <div>
          <h4 className="details_title gilroy">Pest Control Services</h4>
          <p className="details_content gilroy">
            General Pest Control, 1 BHK (1000-2000 sq ft.)
          </p>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2">
            <FontAwesomeIcon
              icon={faWallet}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Budget</h4>
          </div>
          <p className="details_content gilroy">1500 Rupees</p>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Preferred Date & Time:</h4>
          </div>
          <p className="details_content gilroy">
            24th March, 2024 | 4:00-6 p.m
          </p>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2">
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Address:</h4>
          </div>
          <p className="details_content gilroy">
            B 72, Arera colony, Bhopal, 462001
          </p>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2">
            <FontAwesomeIcon
              icon={faClock}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Auction Duration:</h4>
          </div>
          <p className="details_content gilroy">48 hours</p>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2">
            <FontAwesomeIcon
              icon={faReceipt}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Package includes:</h4>
          </div>
          <ul
            style={{ textDecoration: "none" }}
            className="details_content gilroy-regular"
          >
            <li>Cobweb removal</li>
            <li>
              Common Pest Coverage (Ants, Cockroaches, Spider & Mice and Rats)
            </li>
            <li>Seasonal Pest Protection (Wasps, mosquitoes etc.)</li>
          </ul>
        </div>
        <div className="mt-4">
          <div className=" d-flex gap-2 align-items-center my-2 ">
            <FontAwesomeIcon
              icon={faWandMagicSparkles}
              style={{ color: "#1365af", fontSize: "20px" }}
            />
            <h4 className="details_title gilroy m-0">Special Instructions:</h4>
          </div>
          <p
            style={{ textTransform: "none" }}
            className="details_content gilroy-regular"
          >
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia
            ipsam at, dolore incidunt accusamus temporibus omnis eos atque minus
            cumque soluta porro officiis facere blanditiis perferendis illum ea
            ab? Voluptatem minima, provident dolore quisquam explicabo
            necessitatibus soluta quasi magnam illum.
          </p>
        </div>
      </div>
    </>
  );
};

export default AuctionDetails;
