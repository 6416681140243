export const orderData = [
  {
    id: 1,
    name: "Jane Smith",
    orderSetTime: "2 days ago",
    orderId: "92730001",
    information: "Pest Control, General Pest Control, 1 BHK",
    location: "Lorem ipsum dolor sit amet consectetur.",
    date: "24-3-2023 | 4-6 p.m.",
    price: "1700/-",
    status: "In-progress",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Jane Smith",
    orderSetTime: "2 days ago",
    orderId: "927346329",
    information: "Pest Control, General Pest Control, 1 BHK",
    location: "Lorem ipsum dolor sit amet consectetur.",
    date: "24-3-2023 | 4-6 p.m.",
    price: "1700/-",
    status: "Completed",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "Jane Smith",
    orderSetTime: "2 days ago",
    orderId: "927346003",
    information: "Pest Control, General Pest Control, 1 BHK",
    location: "Lorem ipsum dolor sit amet consectetur.",
    date: "24-3-2023 | 4-6 p.m.",
    price: "1700/-",
    status: "In-progress",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "Jane Smith",
    orderSetTime: "2 days ago",
    orderId: "927346004",
    information: "Pest Control, General Pest Control, 1 BHK",
    location: "Lorem ipsum dolor sit amet consectetur.",
    date: "24-3-2023 | 4-6 p.m.",
    price: "1700/-",
    status: "Completed",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    name: "Jane Smith",
    orderSetTime: "2 days ago",
    orderId: "9273463005",
    information: "Pest Control, General Pest Control, 1 BHK",
    location: "Lorem ipsum dolor sit amet consectetur.",
    date: "24-3-2023 | 4-6 p.m.",
    price: "1700/-",
    status: "In-progress",
    image: "https://via.placeholder.com/150",
  },
];
