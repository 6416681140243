import React, { useState, useEffect } from "react";
import "./Newslater.css";
import { Button, TextField } from "@mui/material";
import { subscribeButtonAPI } from "../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const Newslater = () => {
  const [emailId, setEmailId] = useState("");

  const handleEmailId = (event) => {
    setEmailId(event.target.value);
  };

  const fetchEmailAPI = async () => {
    try {
      const response = await axios.get(subscribeButtonAPI + emailId);
      // console.log("email subscribe", response);
      toast.success(response.data.msg);
    } catch (error) {
      if (error && error.response.status === 409) {
        toast.warn(error.response.data.msg);
      }
    }
  };

  return (
    <>
      <div className="newslater__containter d-flex flex-column justify-content-center align-items-center mt-5 py-5">
        <div className="newslater__content  mt-0 px-2 mx-auto">
          <div className="newlater__headings-container d-flex flex-column justify-content-center align-items-center">
            <h1 className="newslater__heading gilroy fw-bold">
              Subscribe to our newsletter!
            </h1>
            <div className="horizontal__line border-top-0 mt-1" />
            <p className="newslater__subheading text-wrap gilroy">
              Stay in the loop with our latest updates, exclusive offers, and
              exciting news by subscribing to our newsletter. Join our community
              and be the first to know about latest news
            </p>
          </div>
          <div className="subscribe__email d-flex flex-column flex-md-row align-items-center gap-2 justify-content-center">
            <div className="email_to_subscribe ">
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                sx={{
                  color: "#6A7D9F",
                  background: "#ffffff",
                  borderRadius: "4px",
                }}
                value={emailId}
                onChange={handleEmailId}
                placeholder="Enter Your Email ID"
              />
            </div>
            <Button
              varient="contained"
              sx={{
                backgroundColor: "#FA9B00",
                textTransform: "none",
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "23.54px",
                padding: "8px 15px",
                "&:hover": {
                  backgroundColor: "#feb02f",
                },
              }}
              onClick={fetchEmailAPI}
            >
              Subscribe
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Newslater;
