import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const SuccessMsg = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{ backgroundColor: "#DFFCF0" }}
      className="d-flex flex-column flex-md-column flex-lg-row gap-4 align-items-center justify-content-between mt-5 rounded p-4 "
    >
      <div className="d-flex gap-2 align-items-cetner">
        <FontAwesomeIcon
          icon={faCircleCheck}
          style={{ color: "#22A06B", fontSize: "18px" }}
        />
        <div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "23.86px",
              color: "#1365AF",
              textTransform: "none",
            }}
            className="gilroy m-0 pb-2"
          >
            Congratulations! Your Auction is now Live!
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "18.83px",
              color: "#1365AF",
            }}
            className="gilroy m-0"
          >
            Soon, the service providers will get notified about the auction and
            will start placing their bids!
          </p>
        </div>
      </div>
      <Button
        varient="contained"
        style={{
          width: "20rem",
          backgroundColor: "#FA9B00",
          color: "#ffffff",
          fontSize: "16px",
          fontWeight: 600,
          padding: "5px 20px",
        }}
        onClick={() => {
          navigate("/auctions");
        }}
        className="gilroy"
      >
        Go to My Auctions
      </Button>
    </div>
  );
};

export default SuccessMsg;
