import React, { useState } from "react";
import "./MyAccount.css";
import Headings from "../Headings/Headings";
import ProfileReviewsButtons from "./ProfileReviewsButtons/ProfileReviewsButtons";
import Profile from "./ProfileAndReviewPage/Profile/Profile";
import Reviews from "./ProfileAndReviewPage/Reviews/Reviews";

function MyAccount() {
  const [view, setView] = useState("profile");

  const handleViewChange = (view) => {
    setView(view);
  };

  return (
    <>
      <div className="my-account-container">
        <Headings headings="My Account" />
        <div className="profile-reviews-button">
          <ProfileReviewsButtons
            onViewChange={handleViewChange}
            currentView={view}
          />
        </div>
        <div className="mt-4">
          {view === "profile" && <Profile />}
          {view === "reviews" && <Reviews />}
        </div>
      </div>
    </>
  );
}

export default MyAccount;
