import { Button } from "@mui/material";
import React from "react";

import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

function BusinessInformation() {
  return (
    <div className="mt-5 px-4 my-2">
      <div className="d-flex justify-content-between">
        <div>
          <p style={{ color: "rgba(106, 125, 159, 1)" }} className="p-0 m-0">
            Business display name
          </p>
          <h1
            style={{ fontWeight: 700 }}
            className="p-0 m-0 fs-4 gilroy text-black"
          >
            Shri Gaurav Pest Control Services
          </h1>
        </div>
        <div>
          <Button
            style={{
              backgroundColor: "rgb(238,238,244)",
              fontSize: "16px",
              padding: "7px 12px",
            }}
            className="rounded-pill text-capitalize "
          >
            {/* <Pencil color="#00000" height="24px" width="24px" /> */}
            <CreateRoundedIcon fontSize="small" />
            Edit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BusinessInformation;
