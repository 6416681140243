import React from "react";
import "./Earnings.css";
import { Button } from "@mui/material";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import EarningTimeDuration from "./EarningTimeDuration/EarningTimeDuration";
import EarningFilter from "./EarningsFilter/EarningFilter";
import BarChartSection from "./BarChart/BarChartSection";

function Earnings() {
  return (
    <>
      <div className="earnings-box px-sm-3">
        <div className="mt-4 py-2 d-flex justify-content-between align-items-center">
          <h4 className="earnings_heading gilroy">Earnings</h4>
          <Button
            style={{
              color: "rgb(136, 139, 145)",
              fontSize: "20px",
              textTransform: "none",
            }}
            className="download-chart gilroy fw-bold"
          >
            Download
            <SystemUpdateAltRoundedIcon sx={{ fontSize: "24px" }} />
          </Button>
        </div>
        <div className="earnings--bar-chart">
          <div className="earnings-bar-chart-filters d-flex align-items-center gap-3 mb-4 ">
            <EarningTimeDuration />
            <EarningFilter />
            {/* <p
              style={{ padding: "4px 17px", backgroundColor: "#EEEDF1" }}
              className="gilroy border m-0 text-center rounded-pill"
            >
              <ClearRoundedIcon sx={{ fontSize: "14px" }} />
              &nbsp;General Pest Control
            </p> */}
          </div>
          <div className=" border rounded border-secondary px-2 py-4 w-100 ">
            <div className="pb-3 w-100 d-flex justify-content-evenly ">
              <div className="d-flex align-items-center ">
                <div className="d-flex flex-column align-items-center">
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: 700,
                      color: "#000000",
                    }}
                    className="gilroy m-0"
                  >
                    820
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      color: "#ABABAF",
                    }}
                    className="gilroy m-0"
                  >
                    Total Orders
                  </p>
                </div>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    color: "#2FC410",
                    marginLeft: "-14px",
                  }}
                  className="gilroy mb-4"
                >
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    style={{ color: "#2FC410", fontSize: "18px" }}
                  />
                  &nbsp;12%
                </p>{" "}
              </div>
              <div>
                <div className="d-flex flex-column align-items-center">
                  <p
                    style={{
                      fontSize: "28px",
                      fontWeight: 700,
                      color: "#000000",
                    }}
                    className="gilroy m-0"
                  >
                    23
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: 500,
                      color: "#ABABAF",
                    }}
                    className="gilroy m-0"
                  >
                    New Orders
                  </p>
                </div>
              </div>
            </div>
            <BarChartSection />
          </div>
        </div>
      </div>
    </>
  );
}

export default Earnings;
