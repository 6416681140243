import { Button } from "@mui/material";
import React from "react";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";

function ServiceOffered() {
  return (
    <div className="mt-5 px-4">
      <div className="d-flex justify-content-between">
        <h4
          style={{ fontWeight: 700, textTransform: "none" }}
          className="gilroy text-black "
        >
          Service offered:
        </h4>
        <Button
          style={{
            backgroundColor: "rgb(238,238,244)",
            fontSize: "16px",
            padding: "7px 12px",
            fontWeight: 500,
          }}
          className="rounded-pill text-capitalize "
        >
          <CreateRoundedIcon fontSize="small" />
          Edit
        </Button>
      </div>
      <div className="d-flex gap-2">
        <Button
          style={{
            backgroundColor: "rgba(214, 236, 255, 1)",
            fontSize: "20px",
            lineHeight: "23.54px",
            fontWeight: 500,
            color: "rgba(0, 303 47,01)",
            padding: "1px 12px",
          }}
          className="rounded gilroy text-capitalize "
        >
          Appliances & Electronics
        </Button>
        <Button
          style={{
            backgroundColor: "rgba(214, 236, 255, 1)",
            fontSize: "20px",
            lineHeight: "23.54px",
            fontWeight: 500,
            padding: "3px 10px",
            color: "rgba(0, 30, 47, 1)",
          }}
          className="rounded gilroy text-capitalize "
        >
          Packers & Movers
        </Button>
        <Button
          style={{
            backgroundColor: "rgba(214, 236, 255, 1)",
            fontSize: "20px",
            lineHeight: "23.54px",
            fontWeight: 500,
            padding: "3px 10px",
            color: "rgba(0, 30, 47, 1)",
          }}
          className="rounded gilroy text-capitalize "
        >
          Gardeners & Horticulture
        </Button>
        <Button
          style={{
            backgroundColor: "rgba(214, 236, 255, 1)",
            fontSize: "20px",
            lineHeight: "23.54px",
            fontWeight: 500,
            padding: "3px 10px",
            color: "rgba(0, 30, 47, 1)",
          }}
          className="rounded gilroy text-capitalize "
        >
          Plumbers
        </Button>
      </div>
    </div>
  );
}

export default ServiceOffered;
