import React from "react";
import "./Steps.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

function Step({ name, isChecked, isActive }) {
  return (
    <div
      style={{
        backgroundColor: isChecked || isActive ? "" : "rgba(244,243,247)",
      }}
      className={`steps form-check p-2 px-3 mb-1 gap-2 d-flex align-items-center justify-content-between rounded ${
        isChecked && isActive ? "step-checked" : ""
      }`}
    >
      <div className="steps-name text-break  me-3">{name}</div>
      <div className="stepChecked">
        {isChecked && (
          <CheckCircleRoundedIcon
            sx={{ color: "rgb(42,153,40)", fontSize: "24px" }}
            className="checkedIcon"
          />
        )}
      </div>
    </div>
  );
}

function Steps({ currentStep }) {
  const steps = [
    {
      name: "Organization Details",
      isChecked: currentStep >= 2,
      isActive: currentStep === 1,
    },
    {
      name: "Personal Verification",
      isChecked: currentStep >= 3,
      isActive: currentStep === 2,
    },
    {
      name: "Email Verification",
      isChecked: currentStep >= 4,
      isActive: currentStep === 3,
    },
    {
      name: "User Credentials",
      isChecked: currentStep >= 5,
      isActive: currentStep === 4,
    },
  ];

  return (
    <div className="steps-section ">
      <div className="steps-section-containers py-3 gilroy d-flex flex-column justify-content-center">
        {steps.map((step, index) => (
          <Step
            key={index}
            name={step.name}
            isChecked={step.isChecked}
            isActive={step.isActive}
          />
        ))}
      </div>
    </div>
  );
}

export default Steps;
