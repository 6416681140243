import React, { useState, useEffect } from "react";
import "./Bids.css";
import FilterBids from "./FilterBids";
import SortBids from "./SortBids";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Button, Rating } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  custAuctionBidSortAPI,
  custAuctionBidsFilterAPI,
} from "../../../../Config/apiConfig";
import axios from "axios";

const itemsPerPage = 4;

function Bids({ bidsData }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [averageBid, setAverageBid] = useState("");
  const [noOfBid, setNoOfBid] = useState("");
  const [bidDetails, setBidDetails] = useState([]);
  const [sortData, setSortData] = useState("");
  const [filterData, setFilterData] = useState("");

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleOnFiltering = (data) => {
    console.log("filter bid", data);
    const filter = data;
    setFilterData(filter === "Low To High" ? "low" : "high");
  };

  const handleOnSorting = (data) => {
    console.log("bid sort", data);
    const sort = data;
    setSortData(sort === "Newest" ? "desc" : "asc");
  };

  useEffect(() => {
    const bids = bidsData;
    console.log("bids data", bids);
    setBidDetails(bids.data.data);
    setAverageBid(bids.data.averageBid);
    setNoOfBid(bids.data.data.length);
  }, [bidsData]);

  useEffect(() => {
    const custBiddedOnServiceId = localStorage.getItem("custBiddedOnServiceId");
    const custparsedBiddedOnId = JSON.parse(custBiddedOnServiceId);
    if (sortData && custparsedBiddedOnId) {
      const fetchSortDataAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.post(
              custAuctionBidSortAPI,
              {
                id: custparsedBiddedOnId,
                order: sortData,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );

            if (response && response.data) {
              const filteredData = response.data;
              if (filteredData) {
                setBidDetails(filteredData.data.data);
                setAverageBid(filteredData.data.averageBid);
                setNoOfBid(filteredData.data.data.length);
              }
            }
          } catch (error) {
            console.error(
              "Error fetching sorted data:",
              error.response || error
            );
          }
        }
      };
      fetchSortDataAPI();
    }
  }, [sortData]);

  useEffect(() => {
    const custBiddedOnServiceId = localStorage.getItem("custBiddedOnServiceId");
    const custparsedBiddedOnId = JSON.parse(custBiddedOnServiceId);
    if (filterData && custparsedBiddedOnId) {
      const fetchFilterDataAPI = async () => {
        const custLoginResponse = localStorage.getItem("custLoginCredentials");
        const parsedData = JSON.parse(custLoginResponse);
        if (parsedData) {
          try {
            const response = await axios.post(
              custAuctionBidsFilterAPI,
              {
                id: custparsedBiddedOnId,
                order: filterData,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${parsedData.token}`,
                },
              }
            );

            if (response && response.data) {
              console.log(response);
              const filteredData = response.data;
              if (filteredData) {
                setBidDetails(filteredData.data.data);
                setAverageBid(filteredData.data.averageBid);
                setNoOfBid(filteredData.data.data.length);
              }
            }
          } catch (error) {
            console.error(
              "Error fetching sorted data:",
              error.response || error
            );
          }
        }
      };
      fetchFilterDataAPI();
    }
  }, [filterData]);

  const offset = currentPage * itemsPerPage;
  const currentItems = bidDetails.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="d-flex gap-5 justify-content-end w-100 mt-3">
        <div className="d-flex flex-column align-items-center">
          <span className="info-bids gilroy">&#8377;{averageBid}</span>
          <p>Average Bids</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <span className="info-bids gilroy">{noOfBid} </span>
          <p>No. of Bids</p>
        </div>
      </div>
      <div className="bids-filter-sort d-flex justify-content-between my-4">
        <FilterBids onCheck={handleOnFiltering} />
        <SortBids onCheck={handleOnSorting} />
      </div>
      <div className="bidder-container">
        <div>
          {currentItems &&
            currentItems.map((item) => (
              <div
                key={item._id}
                className="bidder-content mt-5  border p-4 rounded gilroy"
              >
                <div className="image-and-bids-info d-flex gap-4">
                  <img
                    src={item.serviceImg}
                    alt={item.serviceName}
                    className="rounded"
                    style={{ width: "9.125rem", height: "9.125rem" }}
                  />
                  <div className="bidder-info d-flex flex-column justify-content-between p-0 m-0 w-100 ">
                    <div className="title-location">
                      <div className="d-flex justify-content-between w-100 p-0">
                        <div className="d-flex  gap-2 ">
                          <h4 className="bid-title m-0 p-0">
                            {item.serviceName}
                          </h4>
                          <div className=" d-flex flex-row align-items-center certify-check fw-bold p-1 text-center rounded">
                            <CheckCircleOutlineRoundedIcon
                              className="certify-check-icon"
                              sx={{ fontSize: 16 }}
                            />
                            <span
                              style={{ fontSize: 16 }}
                              className="certify-check"
                            >
                              &nbsp;
                              {item.status ? "Certified" : "Not Certified"}
                            </span>
                          </div>
                          <div className="ratings-bids">
                            <Rating
                              sx={{ fontSize: "18px" }}
                              name="half-rating-read"
                              readOnly
                              defaultValue={item.defaultValue}
                              precision={0.5}
                            />
                          </div>
                        </div>
                        <div className="bidder-add-date ">
                          <span
                            style={{ fontSize: "14px" }}
                            className="text-lowercase gilroy-regular"
                          >
                            {item.createdTime}
                          </span>
                        </div>
                      </div>
                      <p
                        style={{
                          fontSize: "16px",
                          lineHeight: "18.83px",
                          fontWeight: 600,
                          color: "rgba(26, 27, 30, 1)",
                        }}
                        className="gilroy p-0 gilroy"
                      >
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          style={{ color: "#1a1b1e" }}
                        />
                        &nbsp;{item.address}
                      </p>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="about d-flex flex-column">
                        <div className="d-flex gap-2  align-items-end">
                          <p className="bed-bug-termination rounded m-0 gilroy">
                            Bed Bug termination
                          </p>
                          <p className="pest-control rounded gilroy m-0">
                            Pest Control
                          </p>
                        </div>

                        <p
                          style={{ color: "rgba(106, 125, 159, 1)" }}
                          className=" m-0 mt-1"
                        >
                          <span
                            style={{ color: "rgba(26, 27, 30, 1)" }}
                            className="fw-bold gilroy p-0"
                          >
                            About: &nbsp;
                          </span>
                          {item.details}
                        </p>
                      </div>
                      <Button
                        sx={{
                          backgroundColor: "rgba(230, 255, 218, 1)",
                          color: "rgba(42, 153, 40, 1)",
                          fontSize: "16px",
                          padding: "6px 12px 6px 12px",
                          textTransform: "none",
                          borderRadius: "4px",
                          lineHeight: "18.83px",
                        }}
                        className="bids-price-button gilroy"
                      >
                        Bids:&nbsp;<span>&#8377;{item.budget}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <ReactPaginate
          previousLabel={
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ color: "#001b3d" }}
            />
          }
          nextLabel={
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ color: "#001b3d" }}
            />
          }
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(bidDetails.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          pageClassName="pt-1"
          previousClassName=" pt-1"
          nextClassName=" pt-1"
        />
      </div>
    </>
  );
}

export default Bids;
