import React, { useState } from "react";
import "./AddProducts.css";
import { Button, Link, useMediaQuery } from "@mui/material";
import { ArrowBackRounded, CloudUploadOutlined } from "@mui/icons-material";
import ProductInfo from "./ProductInfo/ProductInfo";
import { productAddAPI } from "../../../../Config/apiConfig";
import axios from "axios";
import { toast } from "react-toastify";

const AddProducts = () => {
  const [file, setFile] = useState(null);
  const [titleDeta, setTitleData] = useState("");
  const [categoriesDeta, setcategoriesDeta] = useState();
  const [prizeData, setPrizeData] = useState("");
  const [finalPriceData, setFinalPriceData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [featuresData, setFeaturesData] = useState("");
  const [minQty, setMinQty] = useState("");
  const [maxQty, setMaxQty] = useState("");
  const [availableStockData, setAvailableStockData] = useState("");
  const [specificationData, setSpecificationData] = useState("");

  // Screen Size for responsiveness -------------------------
  const isMedLarScreen = useMediaQuery(" (max-width:768px) ");
  const isVerySmallScreen = useMediaQuery("(max-width:576px) ");

  // Handling States
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileDrop = (e) => {
    e.preventDefault();
    setFile(e.dataTransfer.files[0]);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleUploadClick = () => {
    // Handle the file upload logic here
    console.log("Uploading file:", file);
  };

  const handleProductDetailsData = (data) => {
    // console.log(data);
    setcategoriesDeta(data.categories);
    setTitleData(data.productTitle);
    setPrizeData(data.productMRP);
    setFinalPriceData(data.productFinalPrice);
    setDescriptionData(data.prodDescription);
    setFeaturesData(data.productFeatures);
    setAvailableStockData(data.availableStock);
    setSpecificationData(data.productSpecification);
    setMinQty(data.minQuantity);
    setMaxQty(data.maxQuantity);
  };

  const handleConfirm = async () => {
    const formData = new FormData();

    formData.append("title", titleDeta);
    formData.append("mrp", prizeData);
    formData.append("description", descriptionData);
    formData.append("finalPrice", finalPriceData);
    formData.append("stock", availableStockData);
    formData.append("specifications", specificationData);
    formData.append("features", featuresData);
    formData.append("categoryId", categoriesDeta);
    formData.append("image", file);
    formData.append("minQty", minQty);
    formData.append("maxQty", maxQty);

    try {
      const loginReponse = sessionStorage.getItem("loginCredentials");
      if (loginReponse) {
        const parsedData = JSON.parse(loginReponse);
        console.log(parsedData.token);

        const response = await axios.post(productAddAPI, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${parsedData.token}`,
          },
        });
        console.log("response", response);
        toast.success(response.data.msg);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="add_products_container">
        <div className="d-flex gap-2 gap-sm-3 align-items-start">
          <ArrowBackRounded
            sx={{ fontSize: isVerySmallScreen ? "24px" : "30px" }}
            className="back-arrow mt-1 fw-bold"
          />
          <div className="">
            <h1
              style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
              className="heading fw-bold gilroy"
            >
              Add Products
            </h1>
            <p
              style={{
                fontSize: isVerySmallScreen ? "14px" : "16px",
                fontWeight: 600,
                color: "#6A7D9F",
              }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/dashboard"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Dashboard /{" "}
              </Link>
              <Link
                underline="none"
                href={"/admin-products-services"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Products & Services
              </Link>{" "}
              / Add Products
            </p>
          </div>
        </div>
        <section className="upload_products_img mt-5">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
            className="heading fw-bold gilroy"
          >
            Upload Product Images
          </h1>
          <div className="file-upload-container mt-5">
            <div
              className="file-drop-zone d-flex flex-column flex-sm-row px-2 px-md-4 py-3 gap-2 gap-md-5 align-items-center"
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
            >
              <div className="file-drop-text d-flex gap-2 gap-md-3">
                <CloudUploadOutlined
                  sx={{
                    color: "#999999",
                    fontSize: isVerySmallScreen ? "40px" : "50px",
                  }}
                />
                <div>
                  <p
                    className={`upload_para gilroy-regular ps-1 ${
                      isVerySmallScreen && "m-0"
                    }`}
                  >
                    Select a file or drag and drop here
                  </p>
                  <p
                    style={{ color: "#999999" }}
                    className="upload_para gilroy-regular m-0 p-0"
                  >
                    JPG, PNG or PDF, file size no more than 10MB
                  </p>
                </div>
              </div>
              <input
                type="file"
                onChange={handleFileChange}
                className="file-input"
              />
              <Button
                variant="contained"
                onClick={handleUploadClick}
                sx={{
                  color: "#ffffff",
                  backgroundColor: "rgba(74, 123, 157, 1)",
                  padding: isVerySmallScreen ? "5px 10px" : "10px 20px",

                  textDecoration: "none",
                }}
                className="gilroy-regular "
              >
                Upload
              </Button>
            </div>
          </div>
        </section>

        <section className="product_info_sections mt-5">
          <h1
            style={{ fontSize: isVerySmallScreen ? "1.6em" : "2em" }}
            className="heading fw-bold gilroy"
          >
            Product Information
          </h1>
          <div>
            <ProductInfo onSave={handleProductDetailsData} />
          </div>
        </section>
        <div className="mt-5">
          <Button
            variant="contained"
            sx={{ textTransform: "none", fontSize: "18px" }}
            className="gilroy"
            onClick={handleConfirm}
          >
            Add Product
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddProducts;
