import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceSubCateIdItem: null,
};

const serviceSubCateIdSlice = createSlice({
  name: "serviceSubCateId",
  initialState,
  reducers: {
    setServiceSubCateIdItem: (state, action) => {
      state.serviceSubCateIdItem = action.payload;
    },
  },
});

export const { setServiceSubCateIdItem } = serviceSubCateIdSlice.actions;
export default serviceSubCateIdSlice.reducer;
