import React from "react";
import "./ChattingBox.css";
import { Avatar, Button, IconButton, TextField } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const ChattingBox = () => {
  return (
    <>
      <div className="chatting_box__container">
        <section className="active_chats_chatting d-flex align-items-center border px-3 py-2">
          <div className="active_chats_avtar__status">
            <Avatar
              src="images/myaccount/profile_img.jpg"
              sx={{ width: "2.6rem", height: "2.6rem" }}
            />
          </div>
          <div className="active_chats_name__typing__status d-flex justify-content-between align-items-center w-100 h-100 ps-3 pe-5">
            <div className="active_chats__name d-flex flex-column justify-content-between gap-2">
              <div>
                <p className="active_chatter__name gilroy m-0">Pooja Verma</p>
              </div>
              <div>
                <p className="active_chatter_isonline gilroy m-0">Online</p>
              </div>
            </div>
            <div>
              <IconButton>
                <InfoOutlined sx={{ width: 25, height: 25 }} />
              </IconButton>
            </div>
          </div>
        </section>
        <section className="message_typing__area__container d-flex justify-content-start gap-4 align-items-center border m-0 px-2">
          <div className="message_box">
            <input className="form-control rounded-pill" />
          </div>
          <div className="d-flex w-25 ">
            <IconButton>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ fontSize: "20px" }}
              />
            </IconButton>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#4A7B9D", textTransform: "none" }}
              className="gilroy-regular"
            >
              Create a Quote
            </Button>
          </div>
        </section>
      </div>
    </>
  );
};

export default ChattingBox;
