import React, { useState } from "react";
import "./MyAuctionPaginate.css";
import { useNavigate } from "react-router-dom";
import auctionImage from "../../Rectangle 2057.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button, useMediaQuery } from "@mui/material";
import { auctionContent } from "../myAuctionData";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const itemsPerPage = 4;

const MyAuctionPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);

  // Screen size for responsiveness--------------------
  const isExtraSmallScreen = useMediaQuery("(max-width:320px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 426px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");
  // const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const islargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMediumXLargeScreen = useMediaQuery(
    "(min-width: 568px) and (max-width:768px)"
  );

  // Styling -------------------
  const styleLabel = {
    fontSize: isVerySmallScreen ? "12px" : islargeScreen ? "14px" : "16px",
    color: "#343538",
  };
  const auctionInfo =
    " w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-start gap-2 gap-md-4 gap-lg-5 align-items-start align-items-sm-start my-2 my-lg-3 mx-0 mx-md-2 mx-lg-4 ps-0 ps-md-4 ps-lg-2 ";
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    window.scroll(0, 0);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = auctionContent.slice(offset, offset + itemsPerPage);

  //  Handling Show Bids Button -----------------------------
  const handleClick = () => {
    navigate("/my-auction-details");
  };

  return (
    <div className="mt-5">
      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div className="border my-4 py-2 pe-2 p-sm-4 rounded">
              <Container className=" d-flex justify-content-center">
                <Row className="d-flex justify-content-center">
                  <Col
                    sm={12}
                    md={2}
                    className=" d-flex ps-1 justify-content-center justify-content-md-start"
                  >
                    <img
                      src={auctionImage}
                      alt={item.title}
                      style={{
                        width: islargeScreen ? "8.5rem" : "10rem",
                        height: islargeScreen ? "8.5rem" : "10rem",
                      }}
                    />
                  </Col>
                  <Col
                    sm={12}
                    md={10}
                    className="mt-4 mt-md-1  ps-md-4 ps-lg-2 pe-0 pe-lg-3 me-0 d-flex justify-content-start justify-content-md-center "
                  >
                    <Row className="w-100 p-0 m-0 ">
                      <Col
                        sm={12}
                        className="p-0 ms-0 ps-md-1 ms-md-4 m-0 d-flex flex-column justify-content-center "
                      >
                        <div className="d-flex justify-content-start justify-content-sm-between align-items-center w-100">
                          <div className="">
                            <h4
                              style={{
                                fontSize: isVerySmallScreen
                                  ? "16px"
                                  : islargeScreen
                                  ? "18px"
                                  : "20px",
                                lineHeight: "23.54px",
                              }}
                              className="auction_title my-0 fw-bold mx-0 mx-md-2 gilroy p-0"
                            >
                              {item.title}
                              &nbsp; &nbsp;
                              <span
                                style={{
                                  backgroundColor: "#D9F4D9",
                                  color: "#2A9928",
                                  fontSize: isVerySmallScreen
                                    ? "12px"
                                    : islargeScreen
                                    ? "14px"
                                    : "16px",
                                  textTransform: "capitalize",
                                }}
                                className="gilroy-regular px-2 rounded text-uppercase"
                              >
                                open
                              </span>
                            </h4>
                          </div>
                          <div
                            style={{
                              display: isSmallScreen ? "none" : "flex",
                            }}
                          >
                            <MoreVertIcon
                              style={{
                                fontSize: isMediumXLargeScreen
                                  ? "25px"
                                  : "35px",
                                color: "#000",
                              }}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-start justify-content-sm-start mx-0 mx-md-2">
                          <p
                            style={{
                              color: "#6A7D9F",
                              textTransform: "none",
                              fontSize: islargeScreen ? "16px" : "18px",
                              lineHeight: "21.19px",
                              fontWeight: "500",
                            }}
                            size="small"
                            className="general-pest-control--btn m-0 rounded gilroy"
                          >
                            General Pest Control, 1 BHK (1000-2000 sq ft.)
                          </p>
                        </div>
                      </Col>

                      <Col
                        sm={12}
                        className="px-0 d-flex mt-2 mt-sm-0 justify-content-start justify-content-sm-start"
                      >
                        <div className={auctionInfo}>
                          <div className="d-flex gap-2 ps-0 ps-md-1 ps-lg-0 align-items-center ">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              style={{
                                color: "#275EA7",
                                fontSize: isVerySmallScreen
                                  ? "14px"
                                  : islargeScreen
                                  ? "16px"
                                  : "18px",
                              }}
                            />

                            <p
                              style={styleLabel}
                              className="details gilroy m-0 p-0 "
                            >
                              {item.location}
                            </p>
                          </div>
                          <div className="d-flex gap-2 align-items-center">
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              style={{
                                color: "#275EA7",
                                fontSize: isVerySmallScreen
                                  ? "14px"
                                  : islargeScreen
                                  ? "16px"
                                  : "18px",
                              }}
                            />
                            <p
                              style={styleLabel}
                              className="details gilroy m-0 p-0 "
                            >
                              {item.dateTime}
                            </p>
                          </div>
                        </div>
                      </Col>

                      <Col
                        sm
                        className={`mt-2 ms-0 ms-md-4 ${
                          isVerySmallScreen ? "ps-0" : "ps-2"
                        } p-0`}
                      >
                        <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-0 ms-0 ms-md-0 justify-content-between align-items-center w-100">
                          <div
                            className={`d-flex ${
                              isExtraSmallScreen ? "flex-column" : "flex-row"
                            } justify-content-between justify-content-sm-start w-100 gap-3 gap-lg-5 `}
                          >
                            <p className="my_auctions__bids px-3 py-1 rounded-pill m-0">
                              <span className="fw-bold">{item.statusBids}</span>
                              &nbsp; Bids
                            </p>
                            <p className="time-left m-0 px-3 py-1 rounded-pill">
                              {item.status}
                            </p>
                          </div>
                          <div className="w-100 text-center text-sm-end">
                            <Button
                              style={{
                                backgroundColor: "rgba(19, 101, 175, 1)",
                                color: "rgba(255,255,255,1)",
                                padding: "3px 18px",
                                fontSize: isSmallScreen
                                  ? "14px"
                                  : islargeScreen
                                  ? "16px"
                                  : "18px",
                              }}
                              size="small"
                              onClick={handleClick}
                              className="show-bids-button text-capitalize"
                            >
                              Show Bids
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(auctionContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default MyAuctionPaginate;
