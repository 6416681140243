import React from "react";
import "./DashboardCarousel.css";
import Carousel from "react-bootstrap/Carousel";
import { Button } from "@mui/material";

const DashboardCarousel = () => {
  return (
    <>
      <div className="dashboard__carousel">
        <Carousel className="mt-5 w-100 px-0 mx-0">
          <Carousel.Item>
            <img
              alt=""
              src="images/home/carousel/a.png"
              className="d-block w-100 "
              style={{ height: "54vh" }}
            />
            <Carousel.Caption>
              <h3>
                <span style={{ color: "#1365AF" }}>Looking for services? </span>
                <br />
                <span style={{ color: "#000000" }}>Well, look no further!</span>
              </h3>

              <Button
                style={{ background: "#1365AF", color: "white" }}
                className="gilroy px-3"
              >
                Start a Bidding
              </Button>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              alt=""
              src="images/home/carousel/b.jpg"
              className="d-block w-100"
              style={{ height: "54vh" }}
            />
            <Carousel.Caption>
              <h3>
                <span style={{ color: "#1365AF" }}>Looking for services? </span>
                <br />
                <span style={{ color: "#000000" }}>Well, look no further!</span>
              </h3>

              <Button
                style={{ background: "#1365AF", color: "white" }}
                className="gilroy px-3"
              >
                Start a Bidding
              </Button>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              alt=""
              src="images/home/carousel/c.jpg"
              className="d-block w-100"
              style={{ height: "54vh" }}
            />
            <Carousel.Caption>
              <h3>
                <span style={{ color: "#1365AF" }}>Looking for services? </span>{" "}
                <br />
                <span style={{ color: "#000000" }}>Well, look no further!</span>
              </h3>
              <Button
                style={{ background: "#1365AF", color: "white" }}
                className="gilroy px-3"
              >
                Start a Bidding
              </Button>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default DashboardCarousel;
