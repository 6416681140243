export const profilePic =[
    {
        id: 1,
        src: "images/myaccount/profile1.png",
        alt: "profile1"
    },
    {
        id: 2,
        src: "images/myaccount/profile1.png",
        alt: "profile2"
    },
    {
        id: 3,
        src: "images/myaccount/profile1.png",
        alt: "profile3"
    },
    {
        id: 4,
        src: "images/myaccount/profile1.png",
        alt: "profile4"
    },
    {
        id: 5,
        src: "images/myaccount/profile1.png",
        alt: "profile5"
    },

]

export const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 780 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 780, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };