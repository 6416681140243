import React from "react";
import "./MyAuctions.css";
import { Link, useMediaQuery } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import MyAuctionPaginate from "./MyAuctionPaginate/MyAuctionPaginate";
import MyAuctionFilter from "./MyAuctionFilter/MyAuctionFilter";
import MyAuctionSort from "./MyAuctionSort/MyAuctionSort";

const MyAuctions = () => {
  const isExtraSmallScreen = useMediaQuery("(max-width:320px )");

  const handleMyAuctionFilter = (data) => {
    console.log(data);
  };

  const handleMyAuctionSort = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="my_auctions__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Auctions</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              <Link
                underline="none"
                href={"/"}
                sx={{ textDecoration: "none", color: "#6A7D9F" }}
              >
                Home{" "}
              </Link>{" "}
              / <span style={{ color: "#1365AF" }}>My Auctions</span>
            </p>
          </div>
        </div>
        <div className="my-5">
          <div
            className={`d-flex ${
              isExtraSmallScreen ? "flex-column" : "flex-row"
            }  gap-2 gap-sm-inherit justify-content-between mt-4`}
          >
            <MyAuctionFilter onCheck={handleMyAuctionFilter} />
            <MyAuctionSort onCheck={handleMyAuctionSort} />
          </div>
        </div>
        <div>
          <MyAuctionPaginate />
        </div>
      </div>
    </>
  );
};

export default MyAuctions;
