import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CustomKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)(
  ({ theme }) => ({
    color: "rgba(0, 70, 139, 1)",
  })
);

export default function ServiceFilter({ onCheck }) {
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    const filterProd =
      value === "Low To High"
        ? "/customer/service/filter/low"
        : "/customer/service/filter/high";

    setSelectedOptions(value);
    console.log(filterProd);
    onCheck(filterProd);
  };

  return (
    <Box sx={{ minWidth: 100 }} className="me-2">
      <FormControl fullWidth>
        <Select
          id="demo-simple-select-placeholder-label"
          value={selectedOptions}
          displayEmpty
          size="small"
          onChange={handleChange}
          IconComponent={CustomKeyboardArrowDownIcon}
          inputProps={{ "aria-label": "Select an age" }}
          renderValue={() => (
            <p
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold d-flex align-items-center gilroy  m-0"
            >
              {/* <FilterListRoundedIcon fontSize="small" />  */}
              <img
                src="icons/filter.png"
                alt=""
                style={{ width: 21, height: 20 }}
              />
              <span>&nbsp; Filter</span>
            </p>
          )}
          sx={{
            backgroundColor: "rgba(208, 223, 255, 1)",
            border: "1.2px solid rgba(0, 70, 139, 1)",
          }}
        >
          <MenuItem value="" disabled>
            <span
              style={{ color: "rgba(0, 70, 139, 1)" }}
              className="fw-bold gilroy"
            >
              Filter
            </span>
          </MenuItem>
          {["Low To High", "High To Low"].map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox size="small" checked={selectedOptions === name} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
