import React from "react";

import DraftsRoundedIcon from "@mui/icons-material/DraftsRounded";
import { Box, TextField, useMediaQuery } from "@mui/material";

function Email({ email, setEmail, error }) {
  // Screen Size for Responsiveness
  const isSmallScreen = useMediaQuery("(max-width: 568px) ");

  return (
    <div className="email-page mt-xl-5 mt-lg-4 w-100 me-4 ">
      <div
        style={{
          padding: isSmallScreen ? "20px" : "30px 30px 20px 30px",
        }}
        className="mt-4 mt-lg-3 border w-100 rounded me-5"
      >
        <div className="d-flex align-items-center">
          <DraftsRoundedIcon
            sx={{
              color: "rgba(39, 94, 167, 1)",
              fontSize: isSmallScreen ? "24px" : "26px",
              marginTop: "0.3rem",
            }}
          />
          <label
            htmlFor="exampleFormControlInput1"
            style={{ fontSize: isSmallScreen ? "14px" : "16px" }}
            className="form-label fw-bold my-auto ms-2 mt-2 gilroy text-dark"
          >
            Enter your registered email ID
          </label>
        </div>
        <div className="mt-2">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { my: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              type="email"
              size="small"
              inputMode="email"
              fullWidth={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!!error}
              helperText={error}
              placeholder="name@example.com"
            />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Email;
