import React, { useState } from "react";
import ProfileSetupTwo from "./ProfileSetupTwo/ProfileSetupTwo";

const SetupProfile = () => {
  return (
    <>
      <ProfileSetupTwo />
    </>
  );
};

export default SetupProfile;
