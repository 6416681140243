import React from "react";
import ProfCarousel from "./ProfCarousel/ProfCarousel";
import BusinessDetails from "./BusinessDetails/BusinessDetails";
import BusinessAbout from "./BusinessAbout/BusinessAbout";
import ServiceOfferedAndCertificates from "./ServiceOfferedAndCertificates/ServiceOfferedAndCertificates";

const ServiceProviderProfile = () => {
  return (
    <div>
      <ProfCarousel />
      <div className="my-5 mx-3">
        <BusinessDetails />
        <BusinessAbout />
        <ServiceOfferedAndCertificates />
      </div>
    </div>
  );
};

export default ServiceProviderProfile;
