import React from "react";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import {
  ratings,
  progressContent,
} from "./BusinessProgressBarStatus/ratingsProgressbarData";
import BusinessProgBarStatus from "./BusinessProgressBarStatus/BusinessProgBarStatus";

function RatingsProgressBar() {
  return (
    <>
      <div className="reviews-stars container ">
        {ratings.map((rating) => (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-3">
              <Stack spacing={1}>
                <Rating
                  name="half-rating-read"
                  defaultValue={rating.stars}
                  precision={0.5}
                  size="small"
                  readOnly
                />
              </Stack>
              <p
                style={{ fontSize: "16px", textTransform: "none" }}
                className=" gilroy text-black"
              >
                {rating.stars} out of 5
              </p>
            </div>
            <p style={{ fontSize: "16px" }} className=" gilroy text-black">
              {rating.noOfRatings} Ratings
            </p>
          </div>
        ))}

        <div className="progress-bar py-2">
          {progressContent.map((item) => (
            <BusinessProgBarStatus
              key={item.id}
              stars={item.stars}
              progress={item.progress}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default RatingsProgressBar;
