import React, { useEffect, useState } from "react";
import "./CategoriesButtons.css";
import { Button, useMediaQuery } from "@mui/material";

import { custServiceCategoryAPI } from "../../../../Config/apiConfig";
import axios from "axios";

const CategoriesButtons = ({ catID, catName }) => {
  const [currentBtn, setCurrentBtn] = useState(null);
  const [categoriesTitle, setCategoriesTitle] = useState([]);

  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const isMedLarScreen = useMediaQuery("(max-width:870px)");

  // Style for the active button
  const activeButtonStyle = {
    width: isMedLarScreen ? "12.6rem" : isLargeScreen ? "15.6rem" : "16.6em",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#D6ECFF",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
    "&:hover": {
      backgroundColor: "#D6ECFF",
    },
    border: "1px solid #1365AF",
  };

  // Style for the inactive button
  const inactiveButtonStyle = {
    width: isMedLarScreen ? "12.6rem" : isLargeScreen ? "15.6rem" : "16.6em",
    fontSize: isMedLarScreen ? "16px" : "18px",
    fontWeight: 500,
    color: "#42474E",
    justifyContent: "start",
    backgroundColor: "#FAF9FD",
    border: "1px solid #C2C7CF",
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Ensure text stays within the button
    textOverflow: "ellipsis",
    display: "inline-block",
    textAlign: "start",
  };

  useEffect(() => {
    const fetchProductCateAPI = async () => {
      try {
        const response = await axios.get(custServiceCategoryAPI);
        if (response && response.status === 200) {
          console.log("product category", response.data.data);

          setCategoriesTitle(response.data.data);
        }
      } catch (error) {}
    };
    fetchProductCateAPI();
  }, []);

  // Handle button click and log _id
  const handleButtonClick = (id, index, name) => {
    console.log("Clicked prod category _id:", id);
    catID("/home/searchByCategory/" + id);
    catName(name);
    setCurrentBtn(index + 1);
  };

  return (
    <>
      <div className="categories_buttons__container">
        <h4 className="categories_heading gilroy">Categories</h4>
        <div className="d-flex flex-column gap-4 mt-4">
          {categoriesTitle.map((item, index) => (
            <Button
              key={item._id}
              sx={
                currentBtn === index + 1
                  ? activeButtonStyle
                  : inactiveButtonStyle
              } // Conditionally apply styles
              varient="outlined"
              className="gilroy text-left px-3 py-2"
              onClick={() => handleButtonClick(item._id, index, item.name)} // Pass _id to thehandler
            >
              <img src={item.path} alt="bug" style={{ width: "20px" }} />
              &nbsp;&nbsp;
              {item.name.length > 20
                ? item.substring(0, 21) + "..."
                : item.name}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoriesButtons;
