import React from "react";
import "./ContactInformation.css";

function ContactInformation() {
  return (
    <div className="contact-info-container gilroy mt-4 p-4">
      <h4
        style={{ fontWeight: 700, fontSize: "24px" }}
        className="gilroy text-black"
      >
        Contact Information
      </h4>
      <div style={{ marginTop: "1rem" }} className="d-flex gap-5">
        <div className="address">
          <p className="contact-headings gilroy p-0 my-2">Address</p>
          <p className="contact-info gilroy m-0 p-0 fw-bold">
            B88, Shyamla Hills, Bhopal, 462001
          </p>
        </div>
        <div className="mobile-number">
          <p className="contact-headings gilroy p-0 my-2">Mobile No.</p>
          <p className="contact-info gilroy m-0 p-0 fw-bold ">
            +91 86345 89324
          </p>
        </div>
        <div className="email-address">
          <p className="contact-headings gilroy p-0 my-2">Business Email ID</p>
          <p className="contact-info m-0 p-0 fw-bold text-lowercase">
            shrigauravpestcontrolservices@gmail.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactInformation;
