import React, { useEffect, useState } from "react";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  Close,
  LocationOn,
  AddCircle,
  AccountCircleRounded,
  NotificationsRounded,
  KeyboardArrowDown,
  ShoppingCart,
} from "@mui/icons-material/";
import { Avatar, Button, IconButton, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import RegistrationPage from "./BuyersRegistration/RegisrationPage";
import LoginPage from "./BuyersRegistration/LoginPage";
import { toast } from "react-toastify";

const StyledAutocomplete = styled(Autocomplete)({
  width: 150,
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "none",
    border: "none",
    backgroundColor: "#A9C7FF33",
  },
  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B2CDFF",
    border: "1px solid #B2CDFF",
    backgroundColor: "#A9C7FF33",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B2CDFF",
    border: "1px solid #B2CDFF",
    backgroundColor: "#A9C7FF33",
  },
  "& .MuiInputLabel-root": {
    color: "#ffffff",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#ffffff",
  },
  "& .MuiInputBase-input": {
    color: "#ffffff",
  },
});

const StyledAutocompleteListbox = styled("ul")({
  "& .MuiAutocomplete-option": {
    fontSize: "14px",
    textTransform: "none",
  },
});

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loginDropdownOpen, setLoginDropdownOpen] = useState(false);
  const [mainMenu, setMainMenu] = useState({});
  const [open, setOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [adminToken, setAdminToken] = useState(false);
  const [custToken, setCustToken] = useState(false);
  const [isCity, setIsCity] = useState("");

  // Screen size for responsiveness
  const isMediumScreen = useMediaQuery("(max-width: 976px) ");
  const isSmallScreen = useMediaQuery(" (max-width: 576px) ");

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    setLoginDropdownOpen(false);
  };
  const toggleLoginDropdown = () => {
    setLoginDropdownOpen((prev) => !prev);
    setDropdownOpen(false);
  };

  const navigate = useNavigate();

  const style = {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90vw" : isMediumScreen ? "70vw" : "40vw",
    bgcolor: "rgba(255, 255, 255, 1)",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 2,
    height: "85vh",
    borderRadius: "4px",
    overflow: "scroll",
  };

  const handleLogo = () => {
    navigate("/");
  };

  const handleClose = () => setOpen(false);
  const handleLoginClose = () => setLoginOpen(false);

  const handleCity = (event, newCity) => {
    setIsCity(newCity);
  };

  // updating navbar when Seller is logged in
  useEffect(() => {
    const tokenResponse = localStorage.getItem("loginCredentials");
    if (tokenResponse) {
      const parsedData = JSON.parse(tokenResponse);
      if (parsedData.token) {
        setAdminToken(true);
      } else {
        setAdminToken(false);
      }
    }
  }, []);

  // updating navbar when Buyer is logged in
  useEffect(() => {
    const tokenResponse = localStorage.getItem("custLoginCredentials");
    if (tokenResponse) {
      const parsedData = JSON.parse(tokenResponse);
      if (parsedData.token) {
        setCustToken(true);
      } else {
        setCustToken(false);
      }
    }
  }, []);

  function show() {
    return setMainMenu({
      display: "flex",
      top: "0",
    });
  }

  function close() {
    return setMainMenu({
      display: "flex",
      top: "-100%",
    });
  }

  const handleCart = () => {
    navigate("/cust-product-cart");
    window.scroll(0, 0);
  };

  const design = (
    <>
      <RegistrationPage
        open={open}
        loginOpen={loginOpen}
        handleLoginClose={handleLoginClose}
        handleClose={handleClose}
        style={style}
      />
      <LoginPage
        loginOpen={loginOpen}
        open={open}
        handleLoginClose={handleLoginClose}
        handleClose={handleClose}
        style={style}
      />

      <div className="main-box">
        <nav>
          <div className="logo">
            <h1>
              <Avatar
                aria-label="recipe"
                sx={{ width: "48px", height: "48px" }}
                onClick={handleLogo}
              >
                <img
                  src="images/logo/header-logo.png"
                  alt="logo"
                  className="website_logo"
                />
              </Avatar>
            </h1>
          </div>
          <div className="openMenu" onClick={() => show()}>
            <MenuIcon className="open-menu-icon" />
          </div>
          <ul className="mainMenu gap-1 gap-lg-2" style={mainMenu}>
            <li>
              <div>
                <StyledAutocomplete
                  disablePortal
                  clearIcon={null}
                  id="city"
                  options={top100Films}
                  popupIcon={
                    <KeyboardArrowDown
                      sx={{ fontSize: "20px", color: "#B2CDFF" }}
                    />
                  }
                  className="city_name"
                  ListboxComponent={StyledAutocompleteListbox}
                  value={isCity}
                  onChange={handleCity}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="City"
                      size="small"
                      sx={{ fontSize: "12px" }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOn
                              sx={{
                                fontSize: isCity ? "20px" : "25px",
                                color: "#B2CDFF",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </li>

            {custToken ? (
              <>
                <li className="home_link nav-link-underline">
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy"
                  >
                    Home
                  </Link>
                </li>
                <li className="auction_link nav-link-underline">
                  <Link
                    to={"/auctions"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy"
                  >
                    Auctions
                  </Link>
                </li>
                <li className="cust_product__service_link nav-link-underline">
                  <Link
                    to={"/cust-product-service"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy"
                  >
                    Products & Services
                  </Link>
                </li>

                <li className="chat_link nav-link-underline">
                  <Link
                    to={"/orders"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy nav-big-btn"
                  >
                    Orders
                  </Link>
                </li>
                <li className="cust-breaker-li"></li>
                <li className="start_a_bidding_link">
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      if (custToken) {
                        navigate("/service-categories");
                      } else {
                        toast.warn("!Please Login for Bidding");
                      }
                    }}
                    startIcon={<AddCircle sx={{ fontSize: "18px" }} />}
                    className="nav-icon-btn2 gilroy "
                  >
                    Start a Bidding
                  </Button>
                </li>
                <li className="cust_notification_link">
                  <IconButton className="nav-btn gilroy">
                    <NotificationsRounded fontSize="medium" />
                  </IconButton>
                </li>
                <li className="cust_notification_link" onClick={handleCart}>
                  <IconButton className="nav-btn gilroy">
                    <ShoppingCart fontSize="medium" />
                  </IconButton>
                </li>
                <li className="cust__account_link ">
                  <IconButton
                    className="nav-btn gilroy"
                    onClick={() => {
                      navigate("/cust-accounts");
                    }}
                  >
                    <Avatar sx={{ width: 30, height: 30 }} />
                  </IconButton>
                </li>
              </>
            ) : adminToken ? (
              <>
                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/dashboard"}
                    style={{ textDecoration: "none", fontSize: "13px" }}
                    className="nav-btn gilroy"
                  >
                    Dashboard
                  </Link>
                </li>

                <li className=" dashboarad dashboard_links nav-link-underline">
                  <Link
                    to="/my-orders"
                    style={{ textDecoration: "none", fontSize: "13px" }}
                    className="nav-btn gilroy "
                  >
                    Orders
                  </Link>
                </li>

                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/my-auctions"}
                    style={{ textDecoration: "none", fontSize: "13px" }}
                    className="nav-btn gilroy"
                  >
                    Auctions
                  </Link>
                </li>

                <li className="dashboard dashboard_links nav-link-underline">
                  <Link
                    to={"/chats"}
                    style={{ textDecoration: "none", fontSize: "13px" }}
                    className="nav-btn gilroy "
                  >
                    Chats
                  </Link>
                </li>
                <li className="dashboard dashboard_links nav-link-underline ">
                  <Link
                    to={"/admin-products-services"}
                    style={{ textDecoration: "none", fontSize: "13px" }}
                    className="nav-btn gilroy "
                  >
                    Prducts & Services
                  </Link>
                </li>

                <li className="dashboard_breaker-li"></li>

                <li className="dashboard dashboard_btn ">
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      fontSize: "14px",
                    }}
                    className="nav-btn-icon gilroy p-2 "
                  >
                    Services Catalogue
                  </Button>
                </li>

                <li className="dashboard notification">
                  <IconButton className="nav-btn gilroy">
                    <NotificationsRounded fontSize="medium" />
                  </IconButton>
                </li>

                <li className="dashboard my_account">
                  <IconButton
                    onClick={() => navigate("/myaccount")}
                    className="dashboard__my_account nav-btn gilroy"
                  >
                    <AccountCircleRounded fontSize="medium" />
                  </IconButton>
                </li>
              </>
            ) : (
              <>
                <li className="home_link nav-link-underline">
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none" }}
                    className="nav-btn gilroy"
                  >
                    Home
                  </Link>
                </li>
                <li className="auction_link nav-link-underline">
                  <Link
                    to={"/auctions"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy"
                  >
                    Auctions
                  </Link>
                </li>
                <li className="cust_product__service_link nav-link-underline">
                  <Link
                    to={"/cust-product-service"}
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy"
                  >
                    Products & Services
                  </Link>
                </li>
                <li className="about_link nav-link-underline">
                  <Link
                    style={{ textDecoration: "none" }}
                    className=" nav-btn gilroy nav-big-btn"
                  >
                    About
                  </Link>
                </li>
                <li className="breaker-li"></li>
                <li className="start_a_bidding_link">
                  <Button
                    sx={{
                      px: {
                        lg: "12px",
                        md: "1px",
                      },
                      textTransform: "none",
                    }}
                    onClick={() => {
                      if (custToken) {
                        navigate("/service-categories");
                      } else {
                        toast.warn("!Please Login for Bidding");
                      }
                    }}
                    startIcon={<AddCircle sx={{ fontSize: "18px" }} />}
                    className="nav-icon-btn2 gilroy "
                  >
                    Start a Bidding
                  </Button>
                </li>
                <li className="signup_link position-relative ">
                  <span
                    className="signup-btn nav-btn gilroy"
                    onClick={toggleDropdown}
                  >
                    SignUp
                  </span>
                  {dropdownOpen && (
                    <div className="signup-model model position-absolute border rounded py-2">
                      <div className="button-box text-center gilroy py-1">
                        <Link
                          onClick={() => {
                            setDropdownOpen(false);
                            close(true);
                          }}
                          to="/registration"
                          style={{
                            textTransform: "none",
                            textDecoration: "none",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          As A Seller
                        </Link>
                      </div>
                      <hr className="my-2" />
                      <div className="button-box text-center gilroy py-1">
                        <Link
                          onClick={() => {
                            setDropdownOpen(false);
                            setOpen(true);
                            close(true);
                          }}
                          style={{
                            textTransform: "none",
                            textDecoration: "none",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          As A Buyer
                        </Link>
                      </div>
                    </div>
                  )}
                </li>
                <li className="login_link position-relative">
                  <span
                    className="login-btn nav-btn gilroy"
                    onClick={toggleLoginDropdown}
                  >
                    Log In
                  </span>
                  {loginDropdownOpen && (
                    <div className="login-model model position-absolute border rounded py-2">
                      <div className="button-box text-center gilroy py-1">
                        <Link
                          onClick={() => {
                            setLoginDropdownOpen(false);
                            close(true);
                          }}
                          to="/login"
                          style={{
                            textTransform: "none",
                            textDecoration: "none",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          As A Seller
                        </Link>
                      </div>
                      <hr className="my-2" />
                      <div className="button-box text-center gilroy py-1">
                        <Link
                          onClick={() => {
                            setLoginDropdownOpen(false);
                            setLoginOpen(true);
                            close(true);
                          }}
                          style={{
                            textTransform: "none",
                            textDecoration: "none",
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          As A Buyer
                        </Link>
                      </div>
                    </div>
                  )}
                </li>
              </>
            )}
            <div className="closeMenu" onClick={() => close()}>
              <Close className="close-menu-icon " />
            </div>
          </ul>
        </nav>
      </div>
    </>
  );
  return design;
};

export default Navbar;
const top100Films = [
  { label: "Bhopal", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];
