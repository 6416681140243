import React from "react";
import "./AuctionsCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { carousels } from "./auctionsdata.js";
import { responsive } from "./auctionsdata.js";
import { Avatar, Button, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMapMarkerAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const AuctionsCarousel = () => {
  // Screen Size for responsiveness
  const isExtraLargeScreen = useMediaQuery(" (max-width:1200px) ");
  const isLargeScreen = useMediaQuery(" (max-width: 992px) ");
  const isMediumScreen = useMediaQuery(" (max-width: 768px) ");

  return (
    <div className="recent_auction__carousel">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        // autoPlay={true}
        // autoPlaySpeed={1000}
        // transitionDuration={1000}
        removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
      >
        {carousels.map((item) => (
          <div key={item.id} className="carousel border gilroy mb-5 ">
            <div className="carousel-aution-title-addTime d-flex mx-2 justify-content-between align-items-center">
              <div className="carousel-auction-img-title d-flex gap-2 align-items-center ">
                <Avatar
                  sx={{
                    width: isMediumScreen ? 27 : 33,
                    height: isMediumScreen ? 27 : 33,
                  }}
                  src={item.image}
                  variant="square"
                />
                <p className="carousel-auction-title gilroy m-0">
                  {item.title}
                </p>
              </div>
              <span className="auction-time text-lowercase mt-0 mb-4">
                {item.updateTime} ago
              </span>
            </div>
            <div className="carousel-aution-information d-flex mt-2 mb-3 justify-content-between align-items-center gap-2">
              <div className="d-flex align-items-center gap-2 w-100">
                <Avatar
                  className="text-color"
                  fontSize="medium"
                  sx={{ width: "19px", height: "19px" }}
                />
                <p className="carousel-text text-color m-0 ">{item.name}</p>
              </div>
              <div className="carousel-auction-info d-flex w-50 align-items-center gap-2 w-100">
                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
                <p className="carousel-text text-color m-0">
                  {item.information}
                </p>
              </div>
            </div>
            <div className="carousel-aution-location-date mx-1 d-flex gap-3">
              <div className="carousel-aution-location d-flex align-items-center gap-2 w-100">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="map-icon" />
                <p className="carousel-text text-color m-0 pe-2">
                  {item.location}
                </p>
              </div>
              <div className="carousel-aution-date d-flex align-items-center gap-2 w-100">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="calender-icon"
                />
                <p className="carousel-text text-color m-0">{item.date}</p>
              </div>
            </div>
            <div
              className={`carousel-aution-bids-time_left-budget-place_bid-button d-flex ${
                isLargeScreen ? "flex-column gap-2" : "flex-row"
              } justify-content-between mt-3 gilroy`}
            >
              <div className="d-flex gap-1 gap-sm-2">
                <p className="carousel-auction-bids rounded-pill my-auto px-3 py-1 ">
                  {item.bids} Bids
                </p>
                <p className="carousel-auction-timeLeft rounded-pill my-auto px-3 py-1 ">
                  {item.timeLeft} Left
                </p>
              </div>

              <div
                className={`carousel-aution-budget-button d-flex  ${
                  isLargeScreen
                    ? "justify-content-between"
                    : "justify-content-start"
                } gap-1 gap-sm-2 align-items-center`}
              >
                <p className="carousel-aution-budget rounded m-0 py-2">
                  Budget: &#8377;{item.budget}
                </p>
                <Button
                  variant="contained"
                  size="small"
                  style={{ fontSize: isExtraLargeScreen ? "13.5px" : "16px" }}
                  className="carousel-aution-update-button p-1 px-3 text-capitalize"
                >
                  Place Bid
                </Button>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default AuctionsCarousel;
