import React from "react";
import "./AuctionBids.css";
import AuctionBidsPaginate from "./AuctionBidsPaginate/AuctionBidsPaginate";

const AuctionBids = () => {
  return (
    <>
      <div className="auction_bids__container">
        <div className="d-flex gap-4 justify-content-end mt-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <span className="bids_info__content gilroy">&#8377;700</span>
            <span className="bids_info__label gilroy">Avarage Bid</span>
          </div>
          <div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="bids_info__content gilroy">40</span>
              <span className="bids_info__label gilroy-regular">
                No. of bids
              </span>
            </div>
          </div>
        </div>
        <div className="auction_bids__container_content">
          <AuctionBidsPaginate />
        </div>
      </div>
    </>
  );
};

export default AuctionBids;
