import React from "react";
import "./LineChartSection.css";
import { LineChart } from "@mui/x-charts/LineChart";

const LineChartSection = () => {
  return (
    <>
      <div className="dashboard__line_chart ">
        <LineChart
          xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
          series={[
            {
              data: [2, -5.5, 2, -7.5, 1.5, 6],
              area: true,
              baseline: "min",
              curve: "linear",
              color: "#00b8d4", // Line color
            },
          ]}
          fullWidth
          height={300}
        />
      </div>
    </>
  );
};

export default LineChartSection;
