import React, { useState } from "react";
import "./BidderProfile.css";
import { ArrowBackRounded } from "@mui/icons-material";
import ServiceProfAndRewBtn from "./ServiceProfAndRewBtn";
import ServiceProviderProfile from "./ServiceProviderProfile/ServiceProviderProfile";
import ServiceProviderReviews from "./ServiceProviderReviews/ServiceProviderReviews";

const BidderProfile = () => {
  // useStates--------------
  const [isBidderProfActive, setIsBidderProfActive] = useState(true);

  const handleIsActiveButton = (isActive) => {
    setIsBidderProfActive(isActive);
  };
  return (
    <>
      <div className="service_provider__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Bidder Profile</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / My Auction / Auctions Bid /&nbsp;
              <span style={{ color: "#1365AF" }}>
                Service Provider {isBidderProfActive ? "Profile" : "Reviews"}
              </span>
            </p>
          </div>
        </div>
        <div>
          <ServiceProfAndRewBtn
            isActive={isBidderProfActive}
            onButtonClick={handleIsActiveButton}
            className="w-100"
          />
        </div>
        <div>
          {" "}
          {isBidderProfActive ? (
            <ServiceProviderProfile />
          ) : (
            <ServiceProviderReviews />
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default BidderProfile;
