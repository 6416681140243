import React, { useState, useEffect } from "react";
import { Box, Button, Container } from "@mui/material";
import "./HealthWellness.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setServiceSubCateIdItem } from "../../../redux/serviceSubCateIdSlice";

const slides = [
  {
    image: "images/home/services/business/1.png",
    title: "Web Designers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/business/2.png",
    title: "Generator Dealers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/business/3.png",
    title: "Internet Service Providers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
  {
    image: "images/home/services/business/4.png",
    title: "Security system dealers",
    description: "1234 Service providers",
    clickEvent: "sliderClick",
  },
];

const HealthWellness = ({ serviceData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isData, setIsData] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceId, setServiceId] = useState("");

  useEffect(() => {
    if (serviceData && serviceData[3]) {
      console.log(serviceData[3]);
      console.log(serviceData[3].name);
      setServiceName(serviceData[3].name);
      setIsData(true);
    }
  }, [serviceData]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const handleSearchService = (id) => {
    const cateId = id;
    dispatch(setServiceSubCateIdItem(cateId));

    navigate("/cust-product-service");
    console.log("service sub Id and Name", cateId, serviceName);
  };

  const design = (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
        className="health_wellness"
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span>{serviceName} </span>
          </Box>
        </Box>
        <div className="health_wellness__carousel">
          <Carousel
            infinite={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            responsive={responsive}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {slides.map((item, index) => {
              return (
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="item_image px-2"
                  />

                  <p
                    className="pb-1 px-0 pt-0 mx-0 mb-0 gilroy-regular"
                    style={{
                      marginTop: "19px",
                      color: "#000",
                      textAlign: "center",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    {item.title}
                  </p>

                  <p
                    className="p-0 m-0 gilroy-regular"
                    style={{
                      color: " #42474E",
                      textAlign: "center",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    {item.description}
                  </p>
                </div>
              );
            })}
          </Carousel>
        </div>
      </Container>
    </>
  );
  return design;
};

export default HealthWellness;
