import React from "react";
import "./BidderProfile.css";
import { Button } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function ServiceProfAndRewBtn({ isActive, onButtonClick }) {
  return (
    <div className=" mt-5 mx-5 px-5">
      <div className="bidder_profile_button__bidder_review_button d-flex justify-content-center mx-5 px-4">
        <Button
          style={{
            backgroundColor: isActive ? "rgba(214, 236, 255, 1)" : "",
            color: "rgba(0, 30, 47, 1)",
            borderRadius: "none",
          }}
          fullWidth
          className="bidder_profile__button border border-end-0 border-secondary"
          onClick={() => onButtonClick(true)}
        >
          {isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
          Profile
        </Button>
        <Button
          style={{
            backgroundColor: !isActive ? "rgba(214, 236, 255, 1)" : "",
            color: "rgba(0, 30, 47, 1)",
          }}
          fullWidth
          className="bidder_review_button border border border-secondary"
          onClick={() => onButtonClick(false)}
        >
          {!isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
          Reviews
        </Button>
      </div>
    </div>
  );
}

export default ServiceProfAndRewBtn;
