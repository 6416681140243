import React, { useState } from "react";
import "./AuctionBidsPaginate.css";
import { useNavigate } from "react-router-dom";
import { Button, useMediaQuery, Rating, Stack } from "@mui/material";
import { auctionContent } from "./myAuctionBidsData";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import auctionImg from "../../../../bids-img-1.png";
import MyAuctionBidsSort from "../MyAuctionBidsSort/MyAuctionBidsSort";
import MyAuctionBidsFilter from "../MyAuctionBidsFilter/MyAuctionBidsFilter";

const itemsPerPage = 4;

const AuctionBidsPaginate = () => {
  const navigate = useNavigate();

  // useStates ------------------------
  const [currentPage, setCurrentPage] = useState(0);

  // Screen size for responsiveness--------------------
  const isVerySmallScreen = useMediaQuery("(max-width: 426px) ");
  const isSmallScreen = useMediaQuery("(max-width: 576px)");
  // const isMediumScreen = useMediaQuery("(max-width: 768px) ");
  const islargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMediumXLargeScreen = useMediaQuery(
    "(min-width: 568px) and (max-width:768px)"
  );

  // Styling -------------------
  const styleLabel = {
    fontSize: isVerySmallScreen ? "12px" : islargeScreen ? "14px" : "16px",
    color: "#343538",
  };
  const auctionInfo =
    " w-100 d-flex flex-column flex-md-row justify-content-center justify-content-md-start gap-2 gap-md-4 gap-lg-5 align-items-start align-items-sm-start my-2 my-lg-3 mx-0 mx-md-2 mx-lg-4 ps-0 ps-md-4 ps-lg-2 ";
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const offset = currentPage * itemsPerPage;
  const currentItems = auctionContent.slice(offset, offset + itemsPerPage);

  //  Handling Show Bids Button -----------------------------
  const handleClick = () => {
    navigate("/serviceprovider");
  };

  return (
    <div className=" mt-5">
      <div className="mb-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex gap-3 align-items-center">
            <MyAuctionBidsFilter />
            <p
              style={{ backgroundColor: "#EEEDF1" }}
              className="m-0 p-1 px-3 rounded-pill text-center"
            >
              <FontAwesomeIcon icon={faXmark} style={{ fontSize: "12px" }} />
              &nbsp;&nbsp; Recent
            </p>
          </div>

          <MyAuctionBidsSort />
        </div>
      </div>

      <div>
        {currentItems &&
          currentItems.map((item) => (
            <div className="border my-4 py-2 pe-2 p-sm-4 rounded">
              <Container className=" d-flex justify-content-center  p-0">
                <Row className="d-flex justify-content-center w-100 ">
                  <Col
                    sm={12}
                    md={2}
                    className=" d-flex ps-1 justify-content-center justify-content-md-start"
                  >
                    <img
                      src={auctionImg}
                      alt={item.title}
                      style={{
                        width: islargeScreen ? "8.5rem" : "9rem",
                        height: islargeScreen ? "8.5rem" : "9rem",
                      }}
                      className="rounded border"
                    />
                  </Col>
                  <Col sm={12} md={10} className=" mt-1 pe-0">
                    <div className="d-flex flex-column justify-content-between  h-100 ">
                      <div>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex gap-3 align-items-center">
                            <h4
                              style={{
                                fontSize: "20px",
                                fontWeight: 600,
                                lineHeight: "23.54px",
                                color: "#000000",
                              }}
                              className="gilroy m-0"
                            >
                              {item.title}
                            </h4>
                            <div className="d-flex gap-2 alig-items-center">
                              <p
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  lineHeight: "18.83px",
                                  color: "#172B4D",
                                  backgroundColor: "#F0F2F4",
                                  padding: "2px 3px",
                                  borderRadius: "2px",
                                }}
                                className="m-0"
                              >
                                <FontAwesomeIcon
                                  icon={faCircleCheck}
                                  style={{ color: "#172B4D", fontSize: "15px" }}
                                />
                                &nbsp;Certified
                              </p>
                              <div className="d-flex align-items-center">
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={2.5}
                                  precision={0.5}
                                  readOnly
                                  style={{ margin: "0" }}
                                  size="small"
                                />
                                <span className="ratings">(100)</span>
                              </div>
                            </div>
                          </div>
                          <div className="bids_time">
                            <span className="time">{item.orderTime}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-1 mt-1">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{ fontSize: "15px" }}
                          />
                          <p className="location_text gilroy m-0">
                            {item.location}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-end justify-content-between">
                        <div className="d-flex flex-column align-items-start gap-1">
                          <div>
                            <p className="filter_choosed gilroy m-0 mt-1 p-1">
                              Bed Bug termination
                            </p>
                          </div>
                          <div>
                            <p
                              className="gilroy-regular m-0"
                              style={{
                                fontSize: "16px",
                                fontWeight: 500,
                                color: "#6A7D9F",
                                textTransform: "none",
                              }}
                            >
                              <span style={{ color: "#1A1B1E" }}>About:</span>
                              &nbsp;{item.about}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex flex-column gap-2 align-items-end">
                          <div>
                            <p className="bid_price gilroy p-2 m-0">
                              Bid:&#8377;400
                            </p>
                          </div>
                          <div className="d-flex gap-3 mt-1 align-items-center">
                            <Button
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                              variant="contained"
                              size="small"
                              className="gilroy"
                              onClick={handleClick}
                            >
                              Accept Bid
                            </Button>
                            <Button
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "#1365AF",
                                border: "2px solid #1365AF",
                              }}
                              size="small"
                              variant="outlined"
                              className="gilroy"
                            >
                              Chat
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(auctionContent.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </div>
  );
};

export default AuctionBidsPaginate;
