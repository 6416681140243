import React, { useEffect, useState } from "react";
import "./BiddedOn.css";
import "../OnGoingContainer/OnGoing.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import { AvatarGroup, Box, Button, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faChevronLeft,
  faChevronRight,
  faComments,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { baseUrl } from "../../../../Config/apiConfig";
import axios from "axios";
import { SearchRounded } from "@mui/icons-material";
import BiddedOnFilter from "./BiddedOnFilter/BiddedOnFilter";
import BiddedOnSort from "./BiddedOnSort/BiddedOnSort";
import { useNavigate } from "react-router-dom";

const itemsPerPage = 4;

function BiddedOn() {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [biddedOnData, setBiddedOnData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortBiddedOn, setSortBiddedOn] = useState(
    "/customer/auction/biddedOn"
  );

  // Screen size for responsiveness--------------------
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");
  const isMedLargeScreen = useMediaQuery("(max-width: 921px) ");
  const isMediumScreen = useMediaQuery(" (max-width: 876px) ");
  const isVeryMediumScreen = useMediaQuery(" (max-width: 664px) ");
  const isSmallScreen = useMediaQuery("(max-width:564px) ");
  const isVerySmallScreen = useMediaQuery(" (max-width:420px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:350px)");

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleBiddedOnFilter = (data) => {
    console.log(data);
    if (data) {
      const sorting = data;
      console.log("biddedon sort", sorting);
      setSortBiddedOn(sorting);
    } else {
      setSortBiddedOn("/customer/auction/biddedOn");
    }
  };
  const handleBiddedOnSort = (data) => {
    console.log(data);

    if (data) {
      const sorting = data;

      setSortBiddedOn(sorting);
    } else {
      setSortBiddedOn("/customer/auction/biddedOn");
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  console.log(baseUrl + sortBiddedOn);

  useEffect(() => {
    const fetchCustAuctionBiddedOnAPI = async () => {
      const custLoginResponse = localStorage.getItem("custLoginCredentials");
      const parsedData = JSON.parse(custLoginResponse);
      if (parsedData && sortBiddedOn) {
        try {
          const response = await axios.get(baseUrl + sortBiddedOn, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${parsedData.token}`,
            },
          });
          if (response && response.status === 200) {
            console.log(response);
            console.log("bidded on data", response.data.data);
            setBiddedOnData(response.data.data);
          }
        } catch (error) {}
      }
    };
    fetchCustAuctionBiddedOnAPI();
  }, [sortBiddedOn]);

  const handleBiddedOn = (id) => {
    console.log(id);

    localStorage.setItem("custBiddedOnServiceId", JSON.stringify(id));
    navigate("/auctionDetails");
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = biddedOnData.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div>
        <div
          className={`d-flex ${
            isExtraSmallScreen ? "flex-column" : "flex-sm-row"
          } gap-2 gap-sm-inherit justify-content-between mt-5`}
        >
          <BiddedOnFilter onCheck={handleBiddedOnFilter} />
          <BiddedOnSort onCheck={handleBiddedOnSort} />
        </div>

        <div className="mt-0 mx-sm-2 mx-md-4 py-4 p-sm-2 py-sm-5 ">
          <div className=" search-bar border px-1 d-flex align-items-center justify-content-center">
            <SearchRounded
              sx={{
                color: "rgba(106, 125, 159, 1)",
                fontSize: isVerySmallScreen ? "23px" : "30px",
              }}
            />
            <Box sx={{ m: 0, width: "100%" }}>
              <input
                className="w-100 fs-6 fw-medium"
                type="text"
                id="searchForService"
                placeholder="Search For Service"
                value={searchText}
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>

        {currentItems &&
          currentItems.map((item) => (
            <div
              key={item._id}
              className="auction-content border rounded p-2 p-sm-4 py-3 gilroy my-3"
              onClick={() => handleBiddedOn(item.serviceId)}
            >
              <div
                className={`d-flex ${
                  isMediumScreen ? "flex-column" : "flex-row"
                } justify-content-center align-items-center`}
              >
                <img
                  src={item.serviceImage}
                  alt={item.serviceName}
                  style={{
                    width: isLargeScreen ? "8.8rem" : "10rem",
                    height: isLargeScreen ? "8.8rem" : "10rem",
                  }}
                />
                <div className="details p-0 mx-1 mx-sm-2 w-100 ">
                  <div className=" w-100 d-flex pt-2 pt-sm-0 justify-content-between align-items-center ">
                    <div className="title-container w-100">
                      <div className="d-flex flex-column flex-sm-row  justify-content-between align-items-start align-items-sm-center w-100">
                        <div className="d-flex gap-2 align-items-center">
                          <h4 className="content-title  mx-sm-3 m-0 gilroy">
                            {item.serviceName}
                          </h4>
                          <span className="bids-status text-uppercase">
                            Open
                          </span>
                        </div>
                        <div className="d-flex align-item-center">
                          <span
                            className="text-lowercase mt-1"
                            style={{
                              color: "rgba(106, 125, 159, 1)",
                              fontSize: isLargeScreen ? "12px" : "14px",
                            }}
                          >
                            {item.createdTime}
                          </span>
                          <MoreVertIcon
                            sx={{
                              fontSize: isMediumScreen ? "30px" : "35px",
                              color: "#000",
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className={`d-flex ${
                          isVerySmallScreen
                            ? "flex-column gap-0"
                            : "flex-row gap-3"
                        } mt-2  ps-sm-3`}
                      >
                        <p
                          size="small"
                          className="filter-point general-pest-control--btn py-1 rounded gilroy"
                        >
                          General Pest Control
                        </p>
                        <p
                          size="small"
                          className="filter-point pest-control--btn py-1 rounded gilroy"
                        >
                          Pest Control
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-flex ${
                      isVeryMediumScreen
                        ? "flex-column align-items-start gap-3"
                        : "flex-row align-items-center"
                    }    justify-content-between `}
                  >
                    <div className="bids-info d-flex gap-2 flex-column flex-md-row justify-content-between w-100 ">
                      <div className="d-flex flex-column gap-2 justify-content-start justify-content-md-between align-items-start ms-sm-2">
                        <div className="avatar--location d-flex gap-2 align-items-center">
                          <Avatar
                            sx={{ width: 18, height: 18 }}
                            src={item.userImage}
                          />
                          <span className=" details gilroy ">{item.cName}</span>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <InfoIcon
                            className="icons-color"
                            sx={{ width: 19, height: 19 }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.instruction.length > 30
                              ? `${item.instruction.substring(0, 30)}...`
                              : item.instruction}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2 justify-content-between align-items-start ms-sm-2 ps-1">
                        <div className="d-flex gap-2 justify-content-start align-items-center ">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{ color: "#6a7d9f", fontSize: "17px" }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.address.length > 30
                              ? `${item.address.substring(0, 30)}...`
                              : item.address}
                          </p>
                        </div>
                        <div className="d-flex gap-2 justify-content-start align-items-center ">
                          {/* <Calendar
                            color={"#6a7d9f"}
                            height="23px"
                            width="23px"
                          /> */}
                          <FontAwesomeIcon
                            icon={faCalendarAlt}
                            style={{ color: "#6a7d9f", fontSize: "17px" }}
                          />
                          <p className="details gilroy m-0 p-0 ">
                            {item.date} | {item.time}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: isVeryMediumScreen
                          ? "100%"
                          : isMedLargeScreen
                          ? "25%"
                          : "20%",
                      }}
                      className={`px-sm-2 d-flex ${
                        isVeryMediumScreen
                          ? "flex-row-reverse justify-content-end align-items-center gap-2"
                          : "flex-column align-items-end"
                      } `}
                    >
                      <p className="last-bid-amount m-0 p-0">
                        &#x20B9;{item.budget}/-
                      </p>
                      <p className="sub-content m-0 p-0">Your last bid</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mt-2 w-100 ">
                <div
                  className={`d-flex ${
                    isExtraSmallScreen
                      ? "flex-column align-items-start"
                      : "flex-row align-items-ceter"
                  }  gap-3 w-100`}
                >
                  <div className="avatars_container d-flex align-items-center ">
                    <AvatarGroup total={2}>
                      {item.totalBid.map((bid) => (
                        <Avatar
                          key={bid._id}
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                          sx={{
                            width: isExtraSmallScreen ? 16 : 20,
                            height: isExtraSmallScreen ? 16 : 20,
                          }}
                        />
                      ))}
                    </AvatarGroup>
                  </div>
                  <div className="d-flex gap-2">
                    <div className="bids-box border px-3 py-1 rounded-pill">
                      <p className="bids m-0 p-0">
                        <span className="fw-bold">{item.totalBid.length}</span>{" "}
                        Bids
                      </p>
                    </div>
                    <div className="timer border px-3 py-1 rounded-pill">
                      <p className="hours-left m-0 p-0">{item.remainingTime}</p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ width: isSmallScreen ? "100%" : "auto" }}
                  className="d-flex gap-3 align-items-center"
                >
                  <Box className=" w-100 mt-3 mt-sm-0">
                    <Button
                      style={{
                        backgroundColor: "rgba(19, 101, 175, 1)",
                        color: "rgba(255,255,255,1)",
                        padding: "2px 25px",
                        fontSize: "18px",
                      }}
                      size="small"
                      fullWidth={isVerySmallScreen}
                      className="place-bid-button d-flex flex-row align-items-center text-capitalize"
                    >
                      <FontAwesomeIcon
                        icon={faComments}
                        style={{
                          color: "#ffffff",
                          fontSize: isSmallScreen ? "16px" : "20px",
                        }}
                        full
                      />
                      <span
                        style={{ fontSize: isSmallScreen ? "16px" : "20px" }}
                      >
                        Chat
                      </span>
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          ))}
      </div>
      <ReactPaginate
        previousLabel={
          // <ChevronBack color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        nextLabel={
          // <ChevronForward color="#001b3d" width="18px" height="18px" />
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ color: "#001b3d", fontSize: "16px" }}
          />
        }
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(biddedOnData.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        pageClassName="pt-1"
        previousClassName=" pt-1"
        nextClassName=" pt-1"
      />
    </>
  );
}

export default BiddedOn;
