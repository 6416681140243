export const dataset = [
  { month: 1, seoul: 24 },
  { month: 2, seoul: 32 },
  { month: 3, seoul: 45 },
  { month: 4, seoul: 65 },
  { month: 5, seoul: 78 },
  { month: 6, seoul: 54 },
  { month: 7, seoul: 90 },
  { month: 8, seoul: 112 },
  { month: 9, seoul: 45 },
  { month: 10, seoul: 23 },
  { month: 11, seoul: 35 },
  { month: 12, seoul: 20 },
  ];
  