import React from "react";
import "./OrderCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Avatar, Button, useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faMapMarkerAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { orderData } from "./orderdata.js";

const OrderCarousel = () => {
  // Screen Size for responsiveness
  const isLargeScreen = useMediaQuery(" (max-width:1200px) ");
  const isMediumScreen = useMediaQuery(" (max-width:768px) ");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 876 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 876, min: 567 },
      items: 1.5,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="recent_order__carousel">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        removeArrowOnDeviceType={["desktop", "mobile", "tablet"]}
        autoPlaySpeed={2000}
        transitionDuration={2000}
        className=" m-0"
      >
        {orderData &&
          orderData.map((item) => (
            <div key={item.id} className="carousel border gilroy mb-5">
              <div className="carousel-name-order-id d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center ">
                  <Avatar
                    src={item.image}
                    sx={{
                      width: isLargeScreen ? "29px" : "32px",
                      height: isLargeScreen ? "29px" : "32px",
                    }}
                  />
                  <p className="carousel-name m-0">{item.name}</p>
                </div>
                <p className="carousel-order-id m-0">OrderID: {item.orderId}</p>
              </div>
              <div className="carousel-order-information mt-4 mb-3 d-flex align-items-center gap-2 ">
                <FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
                <p className="carousel-text m-0">{item.information}</p>
              </div>
              <div className="carousel-order-location-date d-flex align-items-start justify-content-between ">
                <div className="carousel-order-location d-flex align-items-center gap-2 w-100">
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    className=" map-icon"
                  />
                  <p className="carousel-text text-color m-0 pe-2">
                    {/* {item.location.length > 22
                      ? `${item.location.substring(0, 22)}...`
                      : item.location} */}
                    {item.location}
                  </p>
                </div>
                <div className="carousel-order-date d-flex align-items-center gap-2 w-100">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className="text-color calendar-icon"
                  />
                  <p className="carousel-text text-color m-0">{item.date}</p>
                </div>
              </div>
              <div className="carousel-order-price-status-button d-flex justify-content-between align-items-center mt-3 gilroy ">
                <p className="carousel-order-price gilroy m-0">
                  &#8377;{item.price}
                </p>
                <div className="carousel-order-status-button d-flex justify-content-center gap-1 gap-sm-2 align-items-center">
                  <p className="carousel-order-status m-0">
                    Status: {item.status}
                  </p>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      padding: "3px 15px",
                      fontSize: isMediumScreen
                        ? "12.5px"
                        : isLargeScreen
                        ? "16px"
                        : "18px",

                      margin: 0,
                    }}
                    className="carousel-order-update-button text-capitalize"
                  >
                    Update
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default OrderCarousel;
