import React from "react";
import "./RecentAuctions.css";
import { Button, useMediaQuery } from "@mui/material";
import AuctionsCarousel from "./AuctionsCarousel/AuctionsCarousel";

function RecentAuctions() {
  // Screen size for responsiveness
  const isMediumScreen = useMediaQuery(" (max-width: 768px) ");
  const isSmallScreen = useMediaQuery(" (max-width: 576px) ");

  return (
    <>
      <div className="recent-auction-box mt-5">
        <div className="recent-auctions-heading-seeAllButton px-sm-3 d-flex justify-content-between align-items-center">
          <h1 className="recent_auctions__heading gilroy m-0">
            Recent Auctions
          </h1>
          <Button
            sx={{
              backgroundColor: "rgba(208, 223, 255, 1)",
              color: "rgba(0,0,0,1)",
              fontSize: isSmallScreen
                ? "15px"
                : isMediumScreen
                ? "18px"
                : "20px",
              fontWeight: 600,
              padding: isSmallScreen
                ? "5px 10px"
                : isMediumScreen
                ? "8px 13px"
                : "10px 15px",
            }}
            className="gilroy"
          >
            See All
          </Button>
        </div>
        <div className="recent-auctions-carousel mt-1">
          <AuctionsCarousel />
        </div>
      </div>
    </>
  );
}

export default RecentAuctions;
