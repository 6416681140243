export const progressContent = [
    
    {
        id: 1,
        stars: 5,
        progress: 80
    },
    {
        id: 2,
        stars: 4,
        progress: 10
    },
    {
        id: 3,
        stars: 3,
        progress: 5,
    },
    {
        id: 4,
        stars: 2,
        progress: 5,
    },
    {
        id: 5,
        stars: 1,
        progress: 0
    }
]

export const ratings  = [
    {
    stars: 4.5,
    noOfRatings: 127
    }
]

