import React from "react";
import "./Download.css";
import { Button, useMediaQuery } from "@mui/material";

function Download() {
  const isSmallScreen = useMediaQuery(" (max-width: 768px) ");
  return (
    <>
      <div className="download__container my-5 d-flex justify-content-center justify-content-md-between ps-md-5 ps-lg-5">
        <div className="download__headings-and-buttons d-flex flex-column justify-content-center align-items-center ms-lg-3 ms-xl-5">
          <h1 className="download__heading gilroy ">Download our App</h1>
          <p className="download__subheading  gilroy text-wrap">
            Experience the best at your fingertips with our mobile app,
            available for both IOS and Android. Our app brings our exceptional
            services directly to your smartphone, providing a seamless and
            intuitive experience whether you're at home, in the office, or on
            the go.
          </p>
          <div className="d-flex justify-content-center justify-content-lg-start gap-3 w-100 ps-1 mt-4 ms-lg-4 ">
            <Button
              variant="contained"
              className="gilroy-regular ms-lg-1"
              sx={{
                fontSize: isSmallScreen ? "16px" : "20px",
                lineHeight: "23.54px",
                padding: isSmallScreen ? "5px 13px" : "10px 15px",
                color: "rgba(255,255,255,1)",
                backgroundColor: "rgba(19, 101, 175, 1)",
                textTransform: "none",
              }}
            >
              for Android
            </Button>
            <Button
              variant="contained"
              className="gilroy-regular"
              sx={{
                fontSize: isSmallScreen ? "16px" : "20px",
                lineHeight: "23.54px",
                padding: isSmallScreen ? "5px 13px" : "10px 15px",
                color: "rgba(42, 23, 0, 1)",
                backgroundColor: "rgba(255, 221, 184, 1)",
                textTransform: "none",
              }}
            >
              for IOS
            </Button>
          </div>
        </div>
        <div className="download_img mt-3"></div>
      </div>
    </>
  );
}

export default Download;
