import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./ProfileCarousels.css";
import { profilePic, responsive } from "./profileCarousels/profileItems.js";
import ProfileBanners from "./ProfileBanners.jsx";

function ProfileCarousels() {
  const profilebannersItems = profilePic.map((banners) => (
    <ProfileBanners id={banners.id} src={banners.src} alt={banners.alt} />
  ));

  // -------------------------------------------------------------------------

  return (
    <div className="profile_account__carousel w-100">
      <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        transitionDuration={2000}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        className="rounded mt-5 p-0 carousel-profile"
      >
        {profilebannersItems}
      </Carousel>
    </div>
  );
}

export default ProfileCarousels;
