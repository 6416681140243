import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CustomerReviewFilter from "./CustomerReviewFilter";
import CustomerReviewBoxes from "./CustomerReviewBoxes/CustomerReviewBoxes";
import CustomerReviewSort from "./CustomerReviewSort";

function CustomerReviews() {
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <CustomerReviewFilter />
        <div className="d-flex align-items-center gap-4">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#626f86", fontSize: "25px" }}
          />
          <CustomerReviewSort />
        </div>
      </div>
      <div className="reviews-box-container mt-4 py-2 d-flex flex-column w-100 m-0 p-0">
        <CustomerReviewBoxes />
      </div>
    </div>
  );
}

export default CustomerReviews;
