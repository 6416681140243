import React from "react";
import FilterResults from "./FilterResults/FilterResults";
import SortingResults from "./SortingResults/SortingResults";
import SearchServices from "./SearchServices/SearchServices.jsx";
import ResultsOfCategoryList from "./ResultsOfCategoryList/ResultsOfCategoryList.jsx";

function ResultsOfCategory() {
  return (
    <>
      <div className="d-flex justify-content-between mt-4 mb-4 pt-1">
        <FilterResults />
        <SortingResults />
      </div>
      <div className="px-5 pt-3 mt-4">
        <SearchServices />
      </div>
      <div className="mt-5 pt-1 ">
        <ResultsOfCategoryList />
      </div>
    </>
  );
}

export default ResultsOfCategory;
