import React from "react";
import "./CustNotifications.css";
import { ArrowBackRounded } from "@mui/icons-material";
import { Avatar } from "@mui/material";

const CustNotifications = () => {
  return (
    <>
      <div className="customer_notifications__container">
        <div className="d-flex gap-3">
          <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
          <div className="">
            <h1 className="heading fw-bold gilroy">Notifications</h1>
            <p
              style={{ fontSize: "16px", fontWeight: 600, color: "#6A7D9F" }}
              className="m-0 gilroy"
            >
              Home / Notifications
            </p>
          </div>
        </div>
        <section className="mt-5 px-3">
          <div
            style={{ backgroundColor: "#F4F3F7" }}
            className="d-flex align-items-ceter justify-content-between p-3 mx-5"
          >
            <div className="d-flex gap-2 align-items-center">
              <Avatar alt="bidder" sx={{ width: 30, height: 30 }} />
              <p className="notification_by gilroy-regular m-0">
                <span style={{ color: "#1365AF" }}>Pushpa Pest Control</span>{" "}
                <span className="">Pvt. Ltf just</span> placed a bid of 8000!
              </p>
            </div>
            <p className="notification_time gilroy-regular m-0">2 min ago</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default CustNotifications;
