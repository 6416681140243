import { Box, Stack, Button, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import ManageBankAccounts from "./ManageBankAccounts/ManageBankAccounts";
import AddNewAccount from "./AddNewAccount/AddNewAccount";

const BankAccounts = () => {
  const [currentPage, setCurrentPage] = useState("manageBankAccounts");

  // Screen Size for responsiveness ------------
  const isVerySmallScreen = useMediaQuery("(max-width: 435px) ");

  return (
    <>
      <div className="bank_accounts__container">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none",
          }}
          className="text-sm-center gilroy-regular"
        >
          You can add multiple Bank Accounts for your Business. One Accounts
          must be selected as Primary account.
        </p>
        <Box className="bank_accounts__contents">
          <hr />
          <Stack direction="row" spacing={isVerySmallScreen ? 2 : 3}>
            <Button
              style={{
                textTransform: "none",
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "manageBankAccounts" ? "#fa9b00" : "#B0B0B0",
                color:
                  currentPage === "manageBankAccounts" ? "#fa9b00" : "#000000",
              }}
              onClick={() => setCurrentPage("manageBankAccounts")}
              variant="outlined"
              className="gilroy"
            >
              Manage Bank Accounts
            </Button>
            <Button
              style={{
                textTransform: "none",
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "addNewBankAccount" ? "#fa9b00" : "#B0B0B0",
                color:
                  currentPage === "addNewBankAccount" ? "#fa9b00" : "#000000",
              }}
              onClick={() => setCurrentPage("addNewBankAccount")}
              variant="outlined"
              className="gilroy"
            >
              Add New Bank Accounts
            </Button>
          </Stack>
          <hr />
          <Box>
            {currentPage === "manageBankAccounts" && (
              <Box className="mt-4">
                <ManageBankAccounts
                  onAddNewBankAccount={() =>
                    setCurrentPage("addNewBankAccount")
                  }
                />
              </Box>
            )}
            {currentPage === "addNewBankAccount" && (
              <Box className="mt-4">
                <AddNewAccount />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default BankAccounts;
