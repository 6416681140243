import { Button } from "@mui/material";
import React from "react";
import "./MiniCategoriesButtons.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBug } from "@fortawesome/free-solid-svg-icons";

function MiniCategoriesButtons() {
  const sx = {
    backgroundColor: "rgba(250, 249, 253, 1)",
    padding: "14px",
    fontSize: 20,
    color: "rgba(66, 71, 78, 1)",
    lineHeight: "23.54px",
    fontWeight: 500,
    border: "rgba(194, 199, 207, 1)",
  };
  const style =
    "mini--categories__buttons justify-content-start border gap-3 rounded w-100 gilroy";

  return (
    <>
      <div className="mini--categories__container mt-4 d-flex flex-column gap-4">
        <Button sx={sx} className={style}>
          {/* <Bug color={"#21272a"} height="24px" width="24px" /> */}
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          General Pest control
        </Button>
        <Button sx={sx} className={style}>
          {/* <Bug color={"#21272a"} height="24px" width="24px" /> */}
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          Termite Control
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          Bed Bugs Control
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          Rodents/Mice/Rat Control
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          Cockroach Control
        </Button>
        <Button sx={sx} className={style}>
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#21272a", fontSize: "22px" }}
          />
          Ants Control
        </Button>
      </div>
    </>
  );
}

export default MiniCategoriesButtons;
