import React from "react";
import "./Headings.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Link } from "react-router-dom";

function Headings({ headings, auctionDetailsBids }) {
  const isHomeAndMaintenance = headings === "Home & Maintenance";
  const isPestControlServices = headings === "Pest Control services";
  const isStartABidding = headings === "Start A Bidding";

  const getStyledHeading = (text, color) => (
    <span style={{ color }}>{text}</span>
  );

  const renderHeading = (text) => {
    if (isHomeAndMaintenance) {
      return (
        <>
          {getStyledHeading(text.split(" ")[0], "rgba(19, 101, 175, 1)")}
          {` ${text.split(" ").slice(1).join(" ")}`}
        </>
      );
    } else if (isPestControlServices) {
      return (
        <>
          {getStyledHeading("Pest Control", "rgba(19, 101, 175, 1)")}
          {` ${text.split(" ").slice(2).join(" ")}`}
        </>
      );
    } else if (isStartABidding) {
      return (
        <>
          {text.split(" ")[0]}&nbsp;{text.split(" ")[1]}&nbsp;
          {getStyledHeading(text.split(" ")[2], "rgba(19, 101, 175, 1)")}
        </>
      );
    } else {
      return text;
    }
  };

  const renderSecondLink = () => {
    if (isHomeAndMaintenance) {
      return "/ Home & Maintenance Services";
    } else if (isPestControlServices) {
      return "/ Home & Maintenance Services ";
    } else if (isStartABidding) {
      return "/ Service Categories";
    } else {
      return `/ ${headings} `;
    }
  };

  const renderThirdLink = () => {
    if (isPestControlServices) {
      return " / Pest Control Services";
    } else {
      return auctionDetailsBids;
    }
  };

  return (
    <>
      <div className="d-flex gap-3">
        <ArrowBackRoundedIcon className="back-arrow mt-2 fs-2 fw-bold" />
        <h1 className="heading fw-bold gilroy">{renderHeading(headings)}</h1>
      </div>
      <div className="home-auctions-links ms-5 gilroy fs-6">
        <Link
          style={{ color: "rgba(106, 125, 159, 1)" }}
          className="headigs__link text-decoration-none"
        >
          Home&nbsp;
        </Link>
        <Link
          style={{ color: "rgba(106, 125, 159, 1)" }}
          className="headigs__link text-decoration-none gilroy"
        >
          {renderSecondLink()}
        </Link>
        <Link
          style={{ color: "rgba(19, 101, 175, 1)" }}
          className="headigs__link text-decoration-none"
        >
          {renderThirdLink()}
        </Link>
      </div>
    </>
  );
}

export default Headings;
