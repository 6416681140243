import React from "react";
import "./ProfCarousel.css";
import Carousel from "react-multi-carousel";
import { responsive, profilePic } from "./profCarouselItems.js";
import ProfBanners from "./ProfBanners.jsx";

const ProfCarousel = () => {
  const profilebannersItems = profilePic.map((banners) => (
    <ProfBanners id={banners.id} src={banners.src} alt={banners.alt} />
  ));

  return (
    <div className="bidder_profile__carousel">
      <Carousel
        swipeable={true}
        draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        transitionDuration={2000}
        removeArrowOnDeviceType={["mobile", "tablet"]}
        className="rounded mt-5 p-0 bidder-profile"
      >
        {profilebannersItems}
      </Carousel>
    </div>
  );
};

export default ProfCarousel;
