import React from "react";
import { Button } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share';

function ShareButton() {
  return (
    <div className="share-button-container position-absolute">
      <Button
        style={{
          color: "rgba(106, 125, 159, 1)",
          border: "rgba(106, 125, 159, 1)",
          padding: "1px 15px",
        }}
        className="shareButton border border-2 fs-5 text-capitalize"
      >
        <ShareIcon style={{ width: "24px", height: "24px" }} />
        Share
      </Button>
    </div>
  );
}

export default ShareButton;
