import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./RegistrationForm.css";
import { businessTypeAPI } from "../../../Config/apiConfig";
import axios from "axios";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  FormLabel,
  FormHelperText,
  useMediaQuery,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

function RegistrationForm({ onDataChange, onValidate }) {
  // const businessTypeApi = "http://65.1.109.1/api/companyType";

  const isMobileScreen = useMediaQuery("(max-width: 576px) ");
  const isSmallScreen = useMediaQuery("(max-width: 425px)");

  const [businessDocuments, setBusinessDocuments] = useState({
    list: [],
  });

  const [registrationData, setRegistrationData] = useState({
    companyType: "",
    businessName: "",
    documents: [],
    checkedTerms: false,
  });

  const [errors, setErrors] = useState({});
  const [companyTypes, setCompanyTypes] = useState({ company: [] });
  const [viewBusinessDetails, setViewBusinessDetails] = useState("d-none");

  // getting business type data
  useEffect(() => {
    const fetchBusinessTypes = async () => {
      try {
        const response = await axios.get(businessTypeAPI);
        setCompanyTypes(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error in BusinessType", error);
        toast.error("Some Error occured", error);
      }
    };

    fetchBusinessTypes();
  }, []);

  const handleChange = async (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setRegistrationData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));

    // Clear the error for the field being edited
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Getting business details data
    if (name === "companyType") {
      try {
        const baseUrl = `${businessTypeAPI}/documentList/${value}`;
        const response = await axios.get(baseUrl);
        if (response && response.status === 200) {
          setBusinessDocuments(response.data);
          setViewBusinessDetails("d-block");

          // Find the label corresponding to the selected value
          const selectedType = companyTypes.company.find(
            (type) => type._id === value
          );
          if (selectedType) {
            onDataChange({ [name]: selectedType.name });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.msg);
          setViewBusinessDetails("d-none");
        }
      }
    } else {
      onDataChange({ [name]: newValue });
    }

    console.log(value);
  };

  const handleDocuments = (event) => {
    const { name, checked } = event.target;
    const updatedDetails = checked
      ? [...registrationData.documents, name]
      : registrationData.documents.filter((detail) => detail !== name);

    setRegistrationData((prevData) => ({
      ...prevData,
      documents: updatedDetails,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      documents: "",
    }));
    onDataChange({ documents: updatedDetails });
  };

  // Function to check if all documents checkboxes are checked
  const areAllDocumentsChecked = () => {
    return (
      businessDocuments.list.length > 0 &&
      registrationData.documents.length === businessDocuments.list.length
    );
  };

  // validation for form
  const validateForm = () => {
    const newErrors = {};
    if (!registrationData.companyType) {
      newErrors.companyType = "Organization/Business type is required";
    }
    if (!registrationData.businessName) {
      newErrors.businessName = "Organization/Business name is required";
    }

    if (!registrationData.documents.length) {
      newErrors.documents = "Please select at least one business detail";
    }

    if (!areAllDocumentsChecked()) {
      newErrors.documents = "All business details must be selected";
    }

    if (!registrationData.checkedTerms) {
      newErrors.checkedTerms = "You must agree to the terms";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    onValidate(validateForm);
  }, [onValidate, registrationData, businessDocuments]);

  return (
    <>
      <div className="business-type-container mx-2 mt-2">
        <div className="d-flex align-items-center">
          {/* <Business color={"#275ea7"} height="25px" width="25px" /> */}
          <FontAwesomeIcon
            icon={faBuilding}
            style={{
              display: isMobileScreen ? "none" : "block",
              color: "#275ea7",
              fontSize: isMobileScreen ? "16px" : "20px",
            }}
          />
          <InputLabel>
            <p
              style={{ fontSize: isMobileScreen ? "14px" : "18px" }}
              className="fw-bold my-auto ms-sm-2  gilroy text-dark "
            >
              Your organization/business's type
            </p>
          </InputLabel>
        </div>
        <Box sx={{ my: 1 }}>
          <Select
            value={registrationData.companyType}
            onChange={handleChange}
            name="companyType"
            size="small"
            fullWidth
            displayEmpty
            error={!!errors.companyType}
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem disabled value="">
              <span
                className="menuItem-disabled"
                style={{ fontSize: isSmallScreen ? "13px" : "16px" }}
              >
                Select Your Organization/Business Type
              </span>
            </MenuItem> */}
            {companyTypes.company.map((type) => (
              <MenuItem key={type._id} value={type._id}>
                <span style={{ fontSize: isSmallScreen ? "13px" : "16px" }}>
                  {type.name}
                </span>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText className="text-danger ms-3">
            {errors.companyType}
          </FormHelperText>
        </Box>
      </div>
      <div className="business-name-container ms-2 mt-5">
        <div className=" d-flex align-items-center">
          <FontAwesomeIcon
            icon={faBuilding}
            style={{
              display: isMobileScreen ? "none" : "block",
              color: "#275ea7",
              fontSize: isMobileScreen ? "16px" : "20px",
            }}
          />
          <InputLabel htmlFor="businessName ">
            <p
              style={{ fontSize: isMobileScreen ? "14px" : "16px" }}
              className="my-auto ms-sm-2  gilroy fw-bold text-dark"
            >
              Your organization/business's name
            </p>
          </InputLabel>
        </div>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { my: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="businessName"
            fullWidth
            size="small"
            name="businessName"
            value={registrationData.businessName}
            onChange={handleChange}
            error={!!errors.businessName}
            // placeholder="Organization/Business's Name"
            className="mb-0 pe-1"
            InputProps={{
              style: {
                fontSize: isSmallScreen ? "13px" : "inherit",
              },
            }}
          />
          <FormHelperText className="text-danger ms-3">
            {errors.businessName}
          </FormHelperText>
        </Box>
      </div>

      <div
        className={`business-details-section container ms-2 mt-0 p-0 py-3 ${viewBusinessDetails}`}
      >
        <FormLabel>
          <p
            style={{ fontSize: isMobileScreen ? "14px" : "18px" }}
            className="gilroy text-dark"
          >
            <b>Business Details</b>
          </p>
        </FormLabel>
        <div
          style={{ color: "rgba(119, 119, 122, 1)" }}
          className="business-details-checkbox ps-4"
        >
          <FormGroup id="businessDetailschecked" sx={{}}>
            {businessDocuments.list.map((detail) => (
              <FormControlLabel
                key={detail._id}
                value={detail._id}
                name={detail.documentName}
                control={<Checkbox size="small" />}
                onChange={handleDocuments}
                label={detail.documentName}
                error={!!errors.documents}
              />
            ))}
          </FormGroup>
        </div>
        <FormHelperText className="text-danger ms-3">
          {errors.documents}
        </FormHelperText>
      </div>

      <div className="confirm-checkbox d-flex mt-4 ms-4">
        <FormGroup
          sx={{
            color: "rgba(19, 101, 175, 1)",
          }}
        >
          <FormControlLabel
            value={registrationData.checkedTerms}
            control={<Checkbox size="small" />}
            onChange={handleChange}
            name="checkedTerms"
            label={
              <span style={{ fontSize: isSmallScreen ? "12px" : "14px" }}>
                Please confirm yourself as the Key/Authorised Person of the
                Organisation.
              </span>
            }
            error={!!errors.checkedTerms}
          />
        </FormGroup>
      </div>

      <FormHelperText className="text-danger ms-3">
        {errors.checkedTerms}
      </FormHelperText>
    </>
  );
}

export default RegistrationForm;
