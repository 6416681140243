import React from "react";
import "./MyAuctionsDetails.css";
import { Button } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function MyAuctionAndBidBtn({ isActive, onButtonClick }) {
  return (
    <div className=" mt-5 mx-5 px-5">
      <div className="auction_details_button__bids_button d-flex justify-content-center mx-5 px-4">
        <Button
          style={{
            backgroundColor: isActive ? "rgba(214, 236, 255, 1)" : "",
            color: "rgba(0, 30, 47, 1)",
            borderRadius: "none",
          }}
          fullWidth
          className="auction_details__button border border-end-0 border-secondary"
          onClick={() => onButtonClick(true)}
        >
          {isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
          Details
        </Button>
        <Button
          style={{
            backgroundColor: !isActive ? "rgba(214, 236, 255, 1)" : "",
            color: "rgba(0, 30, 47, 1)",
          }}
          fullWidth
          className="aution_bids_button border border border-secondary"
          onClick={() => onButtonClick(false)}
        >
          {!isActive ? <DoneRoundedIcon sx={{ fontSize: 20 }} /> : ""}
          Bids
        </Button>
      </div>
    </div>
  );
}

export default MyAuctionAndBidBtn;
