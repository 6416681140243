import React from "react";

const ProfBanners = (props) => {
  return (
    <>
      <div className="profile-pic__container d-flex align-items-center justify-content-center mx-3">
        <img
          id={props.id}
          src={props.src}
          alt={props.alt}
          className=" img-fluid rounded"
        />
      </div>
    </>
  );
};

export default ProfBanners;
