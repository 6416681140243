import React from "react";
import "./ProgressBarStatus.css";
import ProgressBar from "react-bootstrap/ProgressBar";

function ProgressBarStatus({ stars, progress }) {
  return (
    <div className="d-flex gap-3 py-1 w-100 justify-content-between align-items-center">
      <span className="progress-text stars-no gilroy">{stars} Stars</span>
      <div className="w-100">
        <ProgressBar
          now={progress}
          variant="warning"
          style={{ height: "8px" }}
        />
      </div>
      <span className="progress-text progress-percent gilroy">{progress}%</span>
    </div>
  );
}

export default ProgressBarStatus;
