import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import ManageOffices from "./ManageOffices/ManageOffices";
import AddNewOffice from "./AddNewOffice/AddNewOffice";

const OfficeLocations = () => {
  const [currentPage, setCurrentPage] = useState("manageOffices");

  // Screen Size for responsiveness ------------
  const isVerySmallScreen = useMediaQuery("(max-width: 435px) ");

  return (
    <>
      <div className="office_locations_container">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none !important",
          }}
          className="text-sm-center gilroy-regular"
        >
          You can add multiple office locations as per their function/type for
          your Business
        </p>
        <p
          style={{
            backgroundColor: "#FFA5A5",
            fontSize: isVerySmallScreen ? "14px" : " 16px",
            fontWeight: 600,
            borderRadius: "3px",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 2px 3px",
          }}
          className="p-2 px-3 gilroy"
        >
          Adding Registrered Address Is Mandatory
        </p>
        <hr />
        <Box>
          <Stack direction="row" spacing={isVerySmallScreen ? 2 : 3}>
            <Button
              style={{
                textTransform: "none",
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "manageOffices" ? "#fa9b00" : "#B0B0B0",
                color: currentPage === "manageOffices" ? "#fa9b00" : "#000000",
              }}
              onClick={() => setCurrentPage("manageOffices")}
              variant="outlined"
              className="gilroy"
            >
              Manage Offices
            </Button>
            <Button
              style={{
                textTransform: "none",
                fontSize: isVerySmallScreen ? "12px" : "14px",
                padding: "5px 10px",
                borderColor:
                  currentPage === "addNewOffice" ? "#fa9b00" : "#B0B0B0",
                color: currentPage === "addNewOffice" ? "#fa9b00" : "#000000",
              }}
              onClick={() => setCurrentPage("addNewOffice")}
              variant="outlined"
              className="gilroy"
            >
              Add New Office
            </Button>
          </Stack>
          <hr />
          <Box>
            {currentPage === "manageOffices" && (
              <Box className="mt-4">
                <ManageOffices
                  onAddNewOffice={() => setCurrentPage("addNewOffice")}
                />
              </Box>
            )}
            {currentPage === "addNewOffice" && (
              <Box className="mt-4">
                <AddNewOffice />
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default OfficeLocations;
