import React from "react";
import "./SearchServices.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

function SearchServices() {
  return (
    <>
      <div
        style={{ borderRadius: "4px", border: "1px solid" }}
        className="input-group border px-1"
      >
        <span
          style={{ backgroundColor: "rgb(255,255,255)" }}
          className="input-group-text border-0"
        >
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            style={{ color: "#6a7d9f", fontSize: "22px" }}
          />
        </span>
        <input
          type="text"
          className="search-results--input form-control border-0"
          placeholder="Search for a Service"
          aria-label="Search"
          aria-describedby="button-addon2 "
        />
      </div>
    </>
  );
}

export default SearchServices;
