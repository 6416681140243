import React from "react";
import "./ServiceProviderReviews.css";
import { InfoRounded } from "@mui/icons-material";
import RatingsProgressBar from "./RatingsSection/RatingsProgressBar.jsx";
import CustomerReviews from "./CustomerReviewsSection/CustomerReviews.jsx";

const ServiceProviderReviews = () => {
  return (
    <>
      <div className="m-5">
        <div
          style={{ backgroundColor: "#E9F2FF", borderRadius: "3px" }}
          className="d-flex align-items-start gap-3 p-3 mx-5"
        >
          <InfoRounded sx={{ fontSize: "23px", color: "#275EA7" }} />
          <div>
            <h6
              style={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#1365AF",
                lineHeight: "23.86px",
                textTransform: "none",
              }}
              className="gilroy-regular "
            >
              Only Verified can Review!
            </h6>
            <p
              style={{
                color: "#1365AF",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "18.23px",
              }}
              className="mb-0"
            >
              To make sure of the authenticity of the reviews, we allow only the
              verified users i.e those who ordered and received a service, to
              leave a review on the service providers!
            </p>
          </div>
        </div>
      </div>
      <section className="reviews_container pt-3">
        <h1 className="review_heading gilroy">Review</h1>
        <div className="business_reviews__progress_bar mt-5 px-2 py-4">
          <RatingsProgressBar />
        </div>
        <div className="business_reviews_customer_says mt-5 pt-3">
          <CustomerReviews />
        </div>
      </section>
    </>
  );
};

export default ServiceProviderReviews;
