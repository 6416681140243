import React from "react";
import "./ChatsBoxContainer.css";
import { ArrowBackRounded, SearchRounded } from "@mui/icons-material";
import ContactsBox from "./ContactsBox/ContactsBox";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import ChattingBox from "./ChattingBox/ChattingBox";

function ChatsBoxContainer() {
  return (
    <>
      <div className="chat_box__container">
        <aside className="chats_contects__container ">
          <div className="chats_contacts__box d-flex flex-column gap-5">
            <div className="chats-contacts_search_engin ">
              <div className="d-flex gap-3">
                <ArrowBackRounded className="back-arrow mt-2 fs-2 fw-bold" />
                <div className="">
                  <h1 className="heading fw-bolder gilroy">Chats</h1>
                </div>
              </div>
              <section className="mt-3">
                <Autocomplete
                  freeSolo
                  options={top100Films.map((option) => option.title)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Search"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchRounded />
                          </InputAdornment>
                        ),
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderWidth: "2px",
                          },
                          "&:hover fieldset": {
                            borderWidth: "2px",
                          },
                          "&.Mui-focused fieldset": {
                            borderWidth: "2px",
                          },
                        },
                      }}
                    />
                  )}
                />
              </section>
            </div>
            <section className="contacts_box--container no-scrollbar">
              <ContactsBox />
            </section>
          </div>
        </aside>
        <section className="chatting_area_container ">
          <ChattingBox />
        </section>
      </div>
    </>
  );
}

export default ChatsBoxContainer;

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
];
