import React from "react";
import { Button } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

function ProfileReviewsButtons({ onViewChange, currentView }) {
  return (
    <>
      <div className="profile-button--reviews-sent-button d-flex justify-content-center mt-5">
        <Button
          style={{
            backgroundColor:
              currentView === "profile"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            color: "rgba(0, 30, 47, 1)",
            fontSize: "16px",
          }}
          className="profile-button border gilroy text-capitalize border-end-0 border-secondary"
          onClick={() => onViewChange("profile")}
        >
          {currentView === "profile" ? (
            <DoneRoundedIcon sx={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Profile
        </Button>
        <Button
          style={{
            backgroundColor:
              currentView === "reviews"
                ? "rgba(214, 236, 255, 1)"
                : "transparent",
            color: "rgba(0, 30, 47, 1)",
            fontSize: "16px",
          }}
          className="reviews-sent-button gilroy text-capitalize border border-secondary"
          onClick={() => onViewChange("reviews")}
        >
          {currentView === "reviews" ? (
            <DoneRoundedIcon sx={{ fontSize: "20px" }} />
          ) : (
            ""
          )}
          Reviews
        </Button>
      </div>
    </>
  );
}

export default ProfileReviewsButtons;
