import React, { useState, useEffect } from "react";
// import "./BusinessDetails.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { TextField, Button, useMediaQuery } from "@mui/material";

const BusinessDetails = ({ businessData, onSave }) => {
  // useStates
  const [dateIncorporation, setDateIncorporation] = useState(null);
  const [businessName, setBusinessName] = useState("");

  // Screen size for responsiveness ---------------
  const isBetween768And883 = useMediaQuery(
    "(min-width: 768px) and (max-width: 883px)"
  );
  const islargeScreen = useMediaQuery("(max-width: 992px) ");
  const isVerySmallScreen = useMediaQuery("(max-width: 435px)");

  const labelStyle = {
    fontSize: islargeScreen ? "14px" : "16px",
  };

  let buttonPadding = "10px 20px";
  if (isBetween768And883) {
    buttonPadding = "5px 10px";
  } else if (isVerySmallScreen) {
    buttonPadding = "5px 10px";
  }

  useEffect(() => {
    if (businessData) {
      console.log("businessType", businessData.businessName);
      setBusinessName(businessData.businessName);
    }
  }, [businessData]);

  // handling useStates
  const handleDateIncorporationChange = (date) => {
    setDateIncorporation(date);
  };

  // handle save button
  const handleSave = () => {
    const bussDetailData = {
      businessName: businessName,
      dateOfIncorporation: dateIncorporation
        ? dayjs(dateIncorporation).format("DD-MM-YYYY")
        : "",
    };
    onSave(bussDetailData);
  };

  return (
    <>
      <div className="business_details__container mx-0 my-1 w-100 h-auto">
        <p
          style={{
            fontSize: isVerySmallScreen ? "16px" : "18px",
            textTransform: "none !important",
          }}
          className="text-center gilroy mb-4"
        >
          Please ensure correct Business Name on Eserve Market
        </p>
        <div
          className={`d-flex flex-column ${
            isBetween768And883 ? "flex-column" : "flex-md-row"
          } justify-content-center align-items-center gap-5 w-100 mb-4`}
        >
          {/* AutoFilled and Not Editable only readable */}
          <div className="business_name w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark "
            >
              Business Name*
            </p>
            <div className="disabled_field mt-1 w-100">
              <TextField
                sx={{
                  "& .Mui-disabled": {
                    cursor: businessData ? "not-allowed" : "text",
                  },
                }}
                value={businessName}
                size="small"
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="date__as_in_pan w-100">
            <p
              style={labelStyle}
              className="label_name fw-bold my-auto ms-2 mt-2 gilroy text-dark "
            >
              Date of Incorporation*
            </p>
            <div className="mt-1 w-100">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-date-picker"
                  size="small"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                  value={dateIncorporation}
                  onChange={handleDateIncorporationChange}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div className="mt-4 text-center">
          <Button
            variant="contained"
            style={{ backgroundColor: "#fa9b00", color: "#ffffff" }}
            onClick={handleSave}
            className="gilroy"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default BusinessDetails;
