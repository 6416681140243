import React, { useState, useEffect } from "react";
import "./Products.css";
import { Container, Box, Button, useMediaQuery, Rating } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPopularProductCateId } from "../../../../redux/productCateIdSlice";

const Products = ({ data }) => {
  const [isData, setIsData] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.product) {
      setIsData(true);
    }
  }, [data]);

  const handlePopularProductCateId = (id) => {
    // console.log("Product ID", id);
    dispatch(setPopularProductCateId(id));
    if (id) {
      window.scrollTo(0, 0);
      navigate("buyer-product-details");
    }
  };

  const isExtraSmallScreen = useMediaQuery("(max-width: 320px)");
  const isLargeScreen = useMediaQuery("(max-width: 1024px)");

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Container
        maxWidth="lg"
        style={{
          borderBottom: "1px solid #E0E3E7",
          marginBottom: "80px",
          paddingBottom: "80px",
        }}
        className="bsiness_service"
      >
        <Box
          className="d-flex justify-content-center mb-4 mb-lg-5"
          sx={{
            marginBottom: {
              lg: "46px",
            },
          }}
        >
          <Box
            className="gilroy-regular"
            sx={{
              fontSize: {
                xs: "25px",
                lg: "40px",
              },
              alignItems: "center",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "#1365AF" }}>Products </span> for your need
          </Box>
        </Box>
        <div id="product_carousel" className="">
          <Carousel
            infinite={true}
            autoPlaySpeed={3000}
            autoPlay={true}
            responsive={responsive}
            transitionDuration={1000}
            removeArrowOnDeviceType={["mobile", "tablet"]}
          >
            {isData &&
              data.product.map((item) => {
                return (
                  <div
                    key={item._id}
                    onClick={() => handlePopularProductCateId(item._id)}
                    className="my-3 mx-sm-3 "
                  >
                    <div className="product_card__content border rounded p-3">
                      <div className="product_img_section">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="product__img"
                        />
                      </div>
                      <div className="product_info_section mt-2">
                        <p className="product__title gilroy-regular">
                          {item.title}
                        </p>
                        <p className="product__quantity m-0 gilroy">
                          Stock: {item.stock}
                        </p>
                        <div className="ratings mt-2 d-flex gap-2 align-items-ceter">
                          <Rating
                            sx={{
                              fontSize: isLargeScreen ? "18px" : "20px",
                              marginTop: 0.5,
                            }}
                            name="half-rating-read"
                            readOnly
                            defaultValue={2.5}
                            precision={0.5}
                          />
                          <p className="ratings__viewers gilroy m-0 pt-1">
                            (12.4 K)
                          </p>
                        </div>
                        <div className="product__price_content d-flex gap-2 align-items-center mt-3">
                          <span className="orignal__prod_price text-decoration-line-through">
                            &#8377;{item.mrp}
                          </span>
                          <span className="disconted__prod_price">
                            &#8377;{item.finalPrice}
                          </span>
                        </div>
                      </div>
                      <div className="delivery d-flex gap-2 align-items-center mt-2">
                        <img
                          src="icons/delivery-car.png"
                          alt="delivery_icon"
                          className="delivery_icon m-0"
                        />
                        <p className="delivery_text gilroy m-0">
                          Delivery in 4 to 5 days.
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </Container>
    </>
  );
};

export default Products;
