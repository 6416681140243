import React from "react";

function Certificates() {
  return (
    <div>
      <img src="images/home/carousel/d.jpg" alt="" className="certificates" />
    </div>
  );
}

export default Certificates;
